import Skeleton from "@mui/material/Skeleton";
import { Stack } from "@mui/system";

type Props = any;

function ContactFilesLoader(props: Props) {
  return (
    <Stack {...props}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 5,
        }}
      >
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="70%"
        />
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="10%"
        />
        <Skeleton
          animation="wave"
          height={18}
          variant="rectangular"
          width="10%"
        />
      </Stack>
    </Stack>
  );
}

export { ContactFilesLoader };
