import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function ContactActivityLoader(props: Props) {
  return (
    <Stack {...props}>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          marginTop: 5,
        }}
      >
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="20%"
        />
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="100%"
        />
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="100%"
        />
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="100%"
        />
        <Skeleton
          animation="wave"
          height={18}
          sx={{ marginX: 0.5 }}
          variant="rectangular"
          width="100%"
        />
      </Stack>
    </Stack>
  );
}

export { ContactActivityLoader };
