/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
const ringSound = new Audio(require("../assets/audio/new-ringtone.mp3"));
const outgoingRingSound = new Audio(
  require("../assets/audio/ring-tone-68676.mp3"),
);

export const playRingingSound = () => {
  ringSound.loop = true;
  ringSound.play();
};

export const playBusyTone = () => {
  const busyTone = new Audio(require("../assets/audio/busy.mp3"));

  let times = 4;
  const loop = setInterval(repeat, 500);

  function repeat() {
    times -= 1;
    if (times === 0) {
      clearInterval(loop);
    }
    busyTone.play();
  }
  repeat();
};

export const stopRingingSound = () => {
  ringSound.pause();
  ringSound.currentTime = 0;
};

export const stopOutgoingRingingSound = () => {
  outgoingRingSound.pause();
  outgoingRingSound.currentTime = 0;
};

export const startOutgoingRingingSound = () => {
  outgoingRingSound.loop = true;
  outgoingRingSound.play();
};

export const playOutgoingRingingSound = () => {
  const outgoingIphoneRingSound = new Audio(
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    require("../assets/audio/iphone_sms_original.mp3"),
  );
  outgoingIphoneRingSound.loop = false;
  outgoingIphoneRingSound.play();
};
