/* eslint-disable jsx-a11y/label-has-associated-control */
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
} from "@mui/material";
import { ATTACHMENT_URL } from "config";
import { USER_ICON } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import { UserRoleByRoleIdData } from "pages/user/contacts/query/useFetchUserRoleByRoleName";
import { IGroupMember } from "pages/user/inbox/query/useFetchMembers";
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import InputEmoji from "react-input-emoji";
import { MentionUser } from "react-input-emoji/dist/src/types/types";
import DropFile from "./component/drop-file";
import VoiceNote from "./component/voice-note/VoiceNote";

interface IProps {
  formik: any;
  setPreviewList: SetStateAction<any>;
  handleKeyPress: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleOnEnter: (text: string) => void;
  handleWhoIsTyping?: (dat: any, userData: any) => void;
  setImageListData: SetStateAction<any>;
  isDragging: boolean;
  setIsDragging: SetStateAction<any>;
  fileUploadID?: string;
  imageListData?: any;
  groupMemberData: IGroupMember[] | UserRoleByRoleIdData[];
  setIsLoadingPreview?: SetStateAction<any>;
  isRecording?: boolean;
  selectedChatRoomId?: number;
  toUserData?: any;
}

function InputBox(props: IProps) {
  const {
    formik,
    setPreviewList,
    handleWhoIsTyping,
    handleKeyPress,
    setImageListData,
    imageListData,
    isDragging,
    handleOnEnter,
    setIsDragging,
    fileUploadID = "file",
    groupMemberData,
    setIsLoadingPreview,
    isRecording,
    selectedChatRoomId,
    toUserData,
  } = props;
  const chatBoxRef = useRef<HTMLInputElement>(null);

  const decoded = useDecodedData();

  // const [mentionUsers, setMentionUsers] = useState<MentionUser[]>([]);
  // const { data: team } = useFetchUserRoleByRoleName("all", true);

  const mentionUsers: MentionUser[] = useMemo(() => {
    if (!groupMemberData) return [];
    const temp = groupMemberData?.map((item) => {
      return {
        id: String(item?.memberId),
        name: item?.memberName,
        image: `${ATTACHMENT_URL}${item?.userProfileUrl}` || USER_ICON,
      };
    });
    return temp;
  }, [groupMemberData]);

  const memberIds = useMemo(
    () =>
      groupMemberData
        ?.map((item: any) => item.memberId)
        ?.filter((x: any) => x !== Number(decoded?.id)),
    [groupMemberData],
  );
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef?.current?.focus();
    }
  }, [chatBoxRef]);

  useEffect(() => {
    if (selectedChatRoomId) {
      setFieldValue("message", "");
    }
  }, [selectedChatRoomId]);

  const handleFile = (files: File[]) => {
    const maxSize = 30 * 1024 * 1024;

    setIsLoadingPreview(true);
    setImageListData(files);
    return Promise.all(
      files.map(async (file) => {
        if (file.size > maxSize) {
          alert(
            `The file "${file.name}" is too big. Please choose a file less then 30Mb.`,
          );
          return false;
        }
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            const fileType = file.type;

            setPreviewList((prevImages: any) => [
              ...prevImages,
              { url: URL.createObjectURL(file), fileType },
            ]);
            resolve({ url: URL.createObjectURL(file), fileType });
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      }),
    )
      .then(() => {
        setIsLoadingPreview(false);
      })
      .catch((error) =>
        // eslint-disable-next-line no-console
        console.log(
          "Error while loading file from chat:305 handleFileRead ",
          error,
        ),
      )
      .finally(() => setIsLoadingPreview(false));
  };

  const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;
    if (!files) return;
    const filesArr = Object.values(files);
    handleFile(filesArr);
  };

  const { handleSubmit, isSubmitting, setFieldValue, values } = formik;

  const handleMention = useCallback(
    async (text: string) => {
      const name = text.replace(/@/g, "").toLowerCase();
      // Perform asynchronous operation, e.g., API call
      const userList: MentionUser[] = JSON.parse(JSON.stringify(mentionUsers));
      const filterList = userList.filter((item) => {
        const nameLower = item.name.toLowerCase();
        return nameLower.includes(name);
      });
      try {
        return filterList || userList; // Assuming result is of type MentionUser[]
      } catch (error) {
        console.error("Error during mention search:", error);
        return userList; // Return an empty array or handle the error appropriately
      }
    },
    [mentionUsers],
  );
  const handleChange = (value: string) => {
    const conInput: any = document.getElementById("to-user-connection-id");
    const connectionId = conInput?.value;
    const typingReq = {
      roomId: selectedChatRoomId,
      fromUserId: Number(decoded.id),
      userName: decoded.FullName,
    };
    handleWhoIsTyping?.(typingReq, connectionId);

    setFieldValue("message", value);
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLDivElement>) => {
    const items = event.clipboardData?.items;
    const hasText = event.clipboardData?.getData("text");
    const formatted = hasText.replace(/\r\n/g, "</br>");

    const mergedString = `${values.message} ${formatted}`;
    if (hasText) {
      setFieldValue("message", mergedString);
    } else if (items) {
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].type.indexOf("image") !== -1) {
          const blob = items[i].getAsFile();
          if (blob) {
            handleFile([blob]);
            // const reader = new FileReader();
            // reader.readAsDataURL(blob);
            // reader.onload = async () => {
            //   const dataUrl = reader.result as string;

            // };
          }
          break;
        }
      }
    }
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 2px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        // position: "relative",
        boxShadow:
          "3px 4px 4px rgb(100 116 139 / 14%), -3px -2px 6px rgb(100 116 139 / 10%)",
      }}
    >
      <label
        htmlFor={fileUploadID || "file"}
        style={{ cursor: "pointer", padding: "16px 5px" }}
      >
        <input
          // ref={inputField}
          multiple
          id={fileUploadID || "file"}
          style={{ display: "none" }}
          type="file"
          value=""
          onChange={(e) => handleFileRead(e)}
        />
        <AttachFileIcon sx={{ transform: "rotate(45deg)" }} />
      </label>
      <Divider orientation="vertical" sx={{ height: 28, m: 0.4 }} />
      <input
        id="to-user-connection-id"
        type="hidden"
        value={memberIds?.toString() || toUserData?.id}
      />
      <Box
        sx={{
          width: "100%",
          "& .react-input-emoji--wrapper": {
            zIndex: 0,
          },
          "& .react-input-emoji--mention--list": {
            height: "150px",
            overflow: "auto",
          },
        }}
        onPaste={handlePaste}
      >
        <InputEmoji
          key={`chat-room=${selectedChatRoomId}`}
          ref={chatBoxRef}
          shouldReturn
          borderRadius={8}
          fontSize={16}
          inputClass="chat-input"
          placeholder="Type a message..."
          searchMention={handleMention}
          value={values.message}
          onChange={(event: any) => handleChange(event)}
          onEnter={handleOnEnter}
        />
      </Box>
      {isRecording && (
        <VoiceNote
          formik={formik}
          setImageListData={setImageListData}
          setPreviewList={setPreviewList}
        />
      )}
      <Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />
      <IconButton
        aria-label="voice-note"
        color="success"
        disabled={isSubmitting || (!values.message && !imageListData)}
        sx={{ p: "2px 5px" }}
        type="button"
        onClick={() => {
          handleSubmit();
        }}
      >
        {isSubmitting ? (
          <CircularProgress color="warning" size={17} />
        ) : (
          <SendIcon />
        )}
      </IconButton>
      {isDragging && (
        <DropFile
          formik={formik}
          handleFile={handleFile}
          handleKeyPress={handleKeyPress}
          handleOnEnter={handleOnEnter}
          isDragging={isDragging}
          setImageListData={setImageListData}
          setIsDragging={setIsDragging}
          setPreviewList={setPreviewList}
        />
      )}
    </Paper>
  );
}

export default InputBox;
