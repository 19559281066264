import { StyledEngineProvider, ThemeProvider } from "@mui/system";
import { DripCampaignContextProvider } from "DripCampaignContext";
import { ContextProvider } from "SocketContex";
import { AlertProvider } from "components/alert";
import { SnackbarProvider } from "components/snackbar";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme/newTheme";

// Sentry.init({
//   dsn: "https://932efd59494b6c4a1982f96d2ad12a31@o4507338234789888.ingest.us.sentry.io/4507338238656512",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
// initGA();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DripCampaignContextProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <SnackbarProvider>
                <AlertProvider>
                  <ContextProvider>
                    <Router>
                      <App />
                    </Router>
                  </ContextProvider>
                </AlertProvider>
              </SnackbarProvider>
            </StyledEngineProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </DripCampaignContextProvider>
    </PersistGate>
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        style: {
          width: "320px",
          textAlign: "start",
          padding: "8px",
          marginTop: "8px",
          marginBottom: "8px",
          zIndex: 9999,
        },
        duration: 5000,
      }}
    />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
