import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { useAlert } from "components/alert";
import UncontrolledEditor from "components/editor/uncontrolled-editor";
import { ControlPermissionGuard } from "components/permission-guard";
import { PermissionConfig } from "components/permission-guard/permission.config";
import TextField from "components/textfield";
import VoiceRecognition from "components/voice-recognition";
import { FIELDS } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import useForm from "pages/admin/settings/screens/templates/hooks/useForm";
import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { QueryKeys } from "utils/QueryKeys";
import {
    ITemplatesRequest,
    useApiActions,
} from "../../../../query/useApiAction";
import { IResponseTemplates } from "../../../../query/useFetchTemplates";
import TemplateList from "../../../template-list";

interface IProps {
    type: string;
}

const initialValues: ITemplatesRequest = {
    type: "",
    title: "",
    subject: "",
    templateText: "",

    client: 0,
    customField: "",
    templateUsers: [0],
};

function ActivityEmailClient(props: IProps) {
    const { type } = props;
    const [activeTemplate, setActiveTemplate] = useState();
    const [selectedField, setSelectedField] = useState("");
    const { deleteTemplateSingleId } = useApiActions();
    const queryClient = useQueryClient();
    const ACTIVITY_FIELDS = [
        ...FIELDS,
        { value: "Date and time", id: "%dateTime%" },
        { value: "activityType", id: "%activityType%" },
        { value: "User Name", id: "%userName%" },
        { value: "Timezone", id: "%timezone%" },
    ];
    //   const [text, setText] = useState("");
    const chatList = queryClient.getQueryData<IResponseTemplates>([
        QueryKeys.templates,
    ]);
    console.log({ chatList });
    const alert = useAlert();
    const { trySave } = useApiActions();
    const accessPermissionsSlice = useSelector(
        (state: RootState) => state.accessPermissionsSlice,
    );
    const decoded = useDecodedData();
    const handleSelect = (data: any) => {
        setFieldValue("id", data?.id);
        setFieldValue("subject", data?.subject);
        setFieldValue("templateText", data?.templateText);
        setActiveTemplate(data?.id);
    };

    const handleDelete = async (id: number) => {
        await deleteTemplateSingleId(id);
    };

    const deleteTemplateById = (id: number) => {
        alert?.show({
            title: "Confirmation",
            message: "Do you really want to delete",
            cancelText: "No",
            confirmText: "Yes",
            onConfirm: () => handleDelete(id),
        });
    };

    const onSubmit = async (values: ITemplatesRequest) => {
        await trySave({ ...values, type: type.toLowerCase() });

        formik.resetForm();
    };

    const formik = useForm(onSubmit, initialValues);
    const {
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
        dirty,
        isSubmitting,
    } = formik;

    const fetchedEmails = useMemo(() => {
        if (!chatList?.data) return [];
        return chatList?.data?.filter(
            (x) => x.type.toLowerCase() === type.toLowerCase(),
        );
    }, [chatList?.data]);

    return (
        <Grid container>
            <Grid item lg={4} sx={{ borderRight: "1px solid #f4f4f4", p: 2 }} xs={12}>
                <Typography
                    sx={{
                        mb: 1,
                        pb: 1,
                        textAlign: "center",
                        borderBottom: "1px solid #c4c4c4",
                    }}
                    variant="h6"
                >
                    Email Templates
                </Typography>

                {/* <List sx={{ backgroundColor: "#fdfdfd", borderRadius: "6px" }}>
                    {fetchedEmails?.map((x: any) => {
                        return (
                            <ListItem
                                sx={{
                                    backgroundColor:
                                        x?.id === active ? palette.info.lightBg2 : "unset",
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        borderBottom: "1px solid #f4f4f4",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleSelect(x)}
                                >
                                    {x?.subject}
                                </ListItemButton>
                                <ControlPermissionGuard
                                    controlCode={
                                        PermissionConfig.Templates.controlCode.emailDelete
                                    }
                                    permissions={accessPermissionsSlice?.permissions}
                                    roleName={decoded?.RoleName}
                                    screenCode={PermissionConfig.Templates.screenCode}
                                    screenURL={PermissionConfig.Templates.screenURL}
                                >
                                    <Box onClick={() => deleteTemplateId(x?.id)}>
                                        <DeleteIcon
                                            style={{
                                                color: "red",
                                                marginTop: "5px",
                                                cursor: "pointer",
                                            }}
                                        />
                                    </Box>
                                </ControlPermissionGuard>
                            </ListItem>
                        );
                    })}
                </List> */}

                <TemplateList
                    activeTemplate={activeTemplate}
                    templates={fetchedEmails}
                    onDelete={deleteTemplateById}
                    onTemplateSelect={handleSelect}
                />
            </Grid>

            <Grid item lg={8} xs={12}>
                <Stack sx={{ p: 2, maxWidth: "800px" }}>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            error={!!touched.subject && !!errors.subject}
                            helperText={(touched.subject && errors && errors.subject) || ""}
                            label="Subject"
                            name="subject-5"
                            placeholder="Subject"
                            style={{ width: "500px" }}
                            value={values.subject}
                            onBlur={handleBlur("subject")}
                            onChange={handleChange("subject")}
                        />
                    </Box>
                    <Stack alignItems="center" direction="row" spacing={2}>
                        <TextField
                            isSelect
                            label="Fields"
                            menuItems={ACTIVITY_FIELDS}
                            name="field"
                            style={{ width: "350px" }}
                            value={selectedField}
                            onSelectHandler={(event) => {
                                if (event.target.value) {
                                    setSelectedField(event.target.value);
                                } else {
                                    //
                                }
                            }}
                        />

                        <Box
                            sx={{
                                width: "250px",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                            }}
                        >
                            <Button
                                fullWidth
                                variant="text"
                                onClick={() => {
                                    navigator.clipboard.writeText(selectedField);
                                    setSelectedField("");
                                }}
                            >
                                Copy Token
                            </Button>
                        </Box>
                    </Stack>
                    <Stack
                        alignItems="center"
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        marginTop={2}
                    >
                        {" "}
                        <FormControl
                            error={!!touched.templateText && !!errors.templateText}
                            sx={{ width: "100%" }}
                        >
                            <UncontrolledEditor
                                value={values.templateText}
                                onChange={(text: string) => {
                                    setFieldValue("templateText", text);
                                }}
                            />

                            <FormHelperText>
                                {(touched.templateText && errors && errors.templateText) || ""}
                            </FormHelperText>
                        </FormControl>
                        <VoiceRecognition fieldName="templateText" formik={formik} />
                    </Stack>
                    <Box sx={{ mt: 2 }}>
                        <ControlPermissionGuard
                            controlCode={PermissionConfig.Templates.controlCode.emailSave}
                            permissions={accessPermissionsSlice?.permissions}
                            roleName={decoded?.RoleName}
                            screenCode={PermissionConfig.Templates.screenCode}
                            screenURL={PermissionConfig.Templates.screenURL}
                        >
                            <Button
                                disabled={!isValid || !dirty || isSubmitting}
                                startIcon={<AddCircleIcon />}
                                sx={{ padding: "0.5rem 1rem", width: "200px" }}
                                variant="contained"
                                onClick={() => handleSubmit()}
                            >
                                {isSubmitting ? (
                                    <CircularProgress color="info" size={12} />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </ControlPermissionGuard>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}

export default React.memo(ActivityEmailClient);
