import { Box } from "@mui/material";
import { useSnackbar } from "components/snackbar";
import { FILE_URL } from "config";
import { ContentState, convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { debounce } from "lodash";
import React, {
  LegacyRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Editor, Editor as ReactEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { uploadFileAndReturnUrl } from "services/activity.service";
import palette from "theme/palette";

interface IProps {
  value: string;
  onChange: (text: string) => void;
  hasCodeGenerator?: boolean;
  style?: any;
  maxHeight?: number | string | "auto";
  placeholder?: string;
  mentionHandler?: (data: string) => void;
  customPadding?: string;
  isPadding?: boolean;
  isSingleSpace?: boolean;
  editorInstanceRef?: LegacyRef<Editor> | undefined;
}

//   text editor using useref
function UncontrolledEditor(props: IProps) {
  const {
    value,
    onChange,
    maxHeight,
    placeholder,
    customPadding,
    isPadding,
    isSingleSpace = false,
    mentionHandler,
    style,
    editorInstanceRef,
  } = props;
  const snackbar = useSnackbar();
  const editorRef = useRef<EditorState | null>(null); // `useRef` to hold `EditorState`

  // Initialize editor state once
  const initialEditorState = useMemo(() => {
    const contentBlock = htmlToDraft(value || "<p></p>");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    return EditorState.createWithContent(contentState);
  }, [value]);

  //   // Set the initial editor state in the ref
  //   useEffect(() => {
  //     editorRef.current = initialEditorState;
  //   }, [initialEditorState]);

  // Set initial editor state in the ref
  useEffect(() => {
    if (!editorRef.current) {
      // editorRef.current = initialEditorState;
      editorRef.current = EditorState.moveFocusToEnd(initialEditorState);
    } else {
      const contentBlock = htmlToDraft(value || "<p></p>");
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const newEditorState = EditorState.push(
        editorRef.current,
        contentState,
        "insert-characters",
      );
      // editorRef.current = newEditorState;
      editorRef.current = EditorState.moveFocusToEnd(newEditorState);
    }
  }, [value]);

  // Debounced function to send editor content as HTML
  const debouncedOnChange = useMemo(
    () => debounce((htmlValue: string) => onChange(htmlValue), 300),
    [onChange],
  );

  // Update editor content and call debounced onChange
  const handleEditorChange = useCallback(
    (newEditorState: EditorState) => {
      editorRef.current = newEditorState;
      const htmlValue = draftToHtml(
        convertToRaw(newEditorState.getCurrentContent()),
      );
      debouncedOnChange(htmlValue);
      const rawContentState = convertToRaw(newEditorState.getCurrentContent());
      const { blocks } = rawContentState;
      const lastBlock = blocks[blocks.length - 1];
      const text = lastBlock.text.trim();
      const words = text.split(/\s+/);
      mentionHandler?.(words[words.length - 1]); // use to get last typed word
    },
    [debouncedOnChange],
  );

  // Helper function to validate and upload images
  const uploadImageCallBack = useCallback(
    async (file: File) => {
      const allowedTypes = ["jpg", "jpeg", "png"];
      const extension = file.name.split(".").pop() || "";
      if (!allowedTypes.includes(extension.toLowerCase())) {
        snackbar?.show({
          title: "Invalid file type. Please upload a JPG,JPEG or PNG image.",
          type: "error",
        });
        return;
      }

      const formData = new FormData();
      formData.append("formFile", file);
      const response = await uploadFileAndReturnUrl(formData);

      return { data: { link: `${FILE_URL}/${response?.data}` } };
    },
    [snackbar],
  );

  // Custom handler for Enter key
  const handleReturn = useCallback(
    (event: any) => {
      if (editorRef.current) {
        const currentContent = editorRef.current.getCurrentContent();
        const selection = editorRef.current.getSelection();

        const text = isSingleSpace ? "\n" : "\n\n";
        const newContent = Modifier.insertText(currentContent, selection, text);
        const newEditorState = EditorState.push(
          editorRef.current,
          newContent,
          "insert-characters",
        );

        handleEditorChange(newEditorState);
      }
      return true;
    },
    [isSingleSpace, handleEditorChange],
  );

  const defaultPadding = "0 16px 0 16px";
  const appliedPadding =
    isPadding && customPadding ? customPadding : defaultPadding;

  return (
    <Box
      sx={{
        backgroundColor: palette.common.white,
        position: "relative",
        p: appliedPadding,
        maxHeight: maxHeight || "70vh",
        width: "100%",
        overflow: "auto",
        border: "1px solid #f5f1f1",
        ...style,
      }}
    >
      <ReactEditor
        ref={editorInstanceRef}
        spellCheck
        editorClassName="editorClassName"
        editorState={editorRef.current || initialEditorState}
        editorStyle={{ position: "relative", top: 0, margin: 0 }}
        handleReturn={handleReturn}
        placeholder={placeholder}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alt: { present: false, mandatory: false },
          },
        }}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        onEditorStateChange={handleEditorChange}
      />
    </Box>
  );
}

export default React.memo(UncontrolledEditor);
