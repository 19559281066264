/* eslint-disable no-extend-native */
/* eslint-disable func-names */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
import { ADMIN_ROLE, KILO_BYTES_PER_BYTE } from "constants/constants";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import * as Yup from "yup";

import jwtDecode from "jwt-decode";
import Payment from "payment";

// eslint-disable-next-line import/no-extraneous-dependencies
import { Chip } from "@mui/material";
import axios from "axios";
import { FILE_URL } from "config";
import { IDispositionStatus, IDropdown } from "constants/interfaces";
import { FormikErrors } from "formik";
import moment from "moment-timezone";
import { EActivityTemplateTypeFilter } from "pages/admin/settings/screens/templates/component/activity-templates";
import { ITemplate } from "pages/admin/settings/screens/templates/query/useApiAction";
import { ITemplateGenerateParams } from "pages/user/contacts/details/component/add-activity/hooks/useAddActivityUtils";
import { IFuturePayment } from "pages/user/contacts/details/component/payment-invoices/query/usePaymentApiActions";
import { IAddActivityForm } from "pages/user/contacts/hooks/useAddActivityForm";
import { ISMSSend } from "pages/user/contacts/hooks/useSendMessageForm";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import React from "react";
import { store } from "redux/store";
import API_URLS from "services/endPoints";
import palette from "theme/palette";
import client from "./ApiClient";
import { QueryKeys } from "./QueryKeys";

export interface IDatetimeFormat {
  utcDate: string | number;
  hasDateOnly?: boolean;
  hasDatetime?: boolean;
}

const date = new Date();
export const isAdmin = (roleName: string): boolean => {
  const admins = ["Admin", "SuperAdmin"];
  return admins.includes(roleName);
};

export async function downloadFileByApi(fileUrl: string, fileName: string) {
  const { user } = store.getState();
  const { token, companyId } = user;
  const trimmedFileUrl = fileUrl.replace(FILE_URL, "");
  const url = `${API_URLS.DOWNLOAD_ATTACHMENTS}?file=${trimmedFileUrl}`;

  try {
    const response: any = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        CompanyId: companyId,
      },
      responseType: "blob",
    });

    const generatedFileName = generateFileName(
      response?.data?.type || "application/octet-stream",
      fileName,
    );
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(response?.data);
    link.download = generatedFileName;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error("Error while downloading the file:", error);
  }
}

export const COLOR_AS_STATUS: any = {
  Due: palette.error.main,
  Paid: palette.success.lightGreen,
  Partially: palette.info.main,
};

export const COLOR_AS_STATUS_FOR_HTML: any = {
  Due: "#d14343",
  Paid: "#27ae60",
  Partially: "#3c7ef3",
};

export const tablePages = [5, 10, 25, 50, 75, 100];
export const colors = [
  "aqua",
  "blue",
  "fuchsia",
  "gray",
  "green",
  "lime",
  "maroon",
  "navy",
  "olive",
  "orange",
  "purple",
  "red",
  "silver",
  "teal",
  "white",
  "yellow",
];

export const timeZoneIds = [
  {
    id: "Eastern Standard Time",
    name: "Eastern Standard Time (EST)",
  },
  {
    id: "Central Standard Time",
    name: "Central Standard Time (CST)",
  },
  {
    id: "Mountain Standard Time",
    name: "Mountain Standard Time (MST)",
  },
  {
    id: "Pacific Standard Time",
    name: "Pacific Standard Time (PST)",
  },
];

export const chipStatus = (status: boolean) => {
  return status ? (
    <Chip
      color="success"
      label="YES"
      sx={{
        width: "100px",
      }}
    />
  ) : (
    <Chip
      color="error"
      label="NO"
      sx={{
        width: "100px",
      }}
    />
  );
};

export const getCampaignCustomerStatus = (status: string) => {
  return ["Nothing", "Dialable", "IsDialable"].includes(status)
    ? "IsDialable"
    : status;
};

export const requestCameraAndMicrophonePermission = async (
  isVideo: boolean,
) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: isVideo,
      audio: true,
    });
    // Permission granted, you can now use the stream for your needs (e.g., displaying video)
    return stream;
  } catch (error: any) {
    console.error("error", error);
    // Permission denied or an error occurred
    let msg = "";
    msg =
      error?.name === "NotAllowedError"
        ? "Please provide permission for camera and microphone to connect the call."
        : "";
    msg =
      error?.name === "NotFoundError"
        ? "Can't find your camera,\nCheck your system settings to make sure that a camera is available. If not, plug one in. You might then need to restart your browser."
        : " ";
    console.error("Error accessing camera and microphone:", error);
    return { ...error, message: msg };
  }
};

export const scrollToBottom = (customRef: React.RefObject<HTMLDivElement>) => {
  // eslint-disable-next-line prefer-const
  let myTimeout: any;
  if (myTimeout) {
    clearTimeout(myTimeout);
  }
  const refCurrent = customRef.current;
  myTimeout = setTimeout(() => {
    // Your code here
    if (refCurrent) {
      // refCurrent?.scrollIntoView();
      // console.log("refCurrent", refCurrent?.scrollIntoView());
      refCurrent.scrollTop = 0;
    }
  }, 300);
};

export const getStatus = (status: number) => {
  switch (status) {
    case 0:
      return "Created";
    case 1:
      return "Active";
    case 2:
      return "Inactive";
    case 3:
      return "Deleted";
    default:
      return "";
  }
};

export const getPermission = (status: number) => {
  switch (status) {
    case 0:
      return "Created";
    case 1:
      return "Active";
    case 2:
      return "Inactive";
    case 3:
      return "Deleted";
    default:
      return "";
  }
};

export const getInvitationStatus = (status: string) => {
  switch (status) {
    case "Sent":
      return "Sent";
    case "Accepted":
      return "Accepted";
    case "Rejected":
      return "Rejected";
    default:
      return "";
  }
};

export const isExistDnc = (list: string[], val: any) => {
  let check;
  if (val) {
    check = list?.join(",").includes(val);
  }

  return check;
};

export const isScreenAccessible = (screenCode: string) => {
  const state = store.getState();
  const { user, common } = state;
  if (!user.token) return false;
  const decodedToken: any = jwtDecode(user.token);
  const { permissions } = common;

  if (ADMIN_ROLE.includes(decodedToken.RoleName)) {
    return true;
  }

  const screenPermission = permissions?.find(
    (x: any) => x.screenCode === screenCode,
  );

  return !!screenPermission;
};

export const isControlAccessible = (code: string, screenCode: string) => {
  const state = store.getState();
  const { user, common } = state;
  const decodedToken: any = jwtDecode(user.token);
  const { permissions } = common;
  const screenPermission = permissions?.find(
    (x: any) => x.screenCode === screenCode,
  );

  if (ADMIN_ROLE.includes(decodedToken.RoleName)) {
    return true;
  }
  if (screenPermission) {
    // const isExist = screenPermission?.permissions?.find((x: any) => {
    //   if (x) {
    //     if (x.permissionCode === code) {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
    return true; // !!isExist;
  }

  return false;
};

export const dateFormatter = (
  d: any,
  dateOnly?: boolean,
  hasFancyFormat?: boolean,
  isSec?: boolean,
) => {
  if (dateOnly) {
    return `${moment(d).format("MM/DD/YYYY")}`;
  }

  if (hasFancyFormat) {
    return `${moment(d).format("MMM DD, YYYY")}`;
  }
  if (isSec) {
    return `${moment(d).format("MM/DD/YYYY, hh:mm:ss A")}`;
  }
  return `${moment(d).format("MM/DD/YYYY, hh:mm A")}`;
};

export const compareTime = (createdTime: any) => {
  return `${moment().diff(createdTime, "days")} days ago`;
};

export const timeStampToTime = (params: IDatetimeFormat) => {
  const date = new Date(params.utcDate); // Convert to milliseconds
  let options: Intl.DateTimeFormatOptions;
  if (params.hasDateOnly) {
    options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
      // hour12: true, // Set to true for 12-hour format
      // timeZone: "America/New_York", // Adjust timezone as needed
      // timeZoneName: "short",
    };
  } else if (params.hasDatetime) {
    options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Set to true for 12-hour format
    };
  } else {
    options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Set to true for 12-hour format
    };
  }

  const formattedDate = date.toLocaleString("en-US", options);

  return formattedDate;
};

export const convertDateUtcToLocal = (
  params: IDatetimeFormat,
): string | null => {
  if (!params?.utcDate) {
    return null;
  }
  const utcDateObject = new Date(params?.utcDate);

  // Format the local date as per your requirement

  let options: Intl.DateTimeFormatOptions;
  if (params.hasDateOnly) {
    options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      // hour: "numeric",
      // minute: "numeric",
      // second: "numeric",
      // hour12: true, // Set to true for 12-hour format
      // timeZone: "America/New_York", // Adjust timezone as needed
      // timeZoneName: "short",
    };
  } else if (params.hasDatetime) {
    options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",

      hour12: true, // Set to true for 12-hour format
    };
  } else {
    options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true, // Set to true for 12-hour format
    };
  }
  const formattedLocalDate = utcDateObject.toLocaleString("en-US", options);
  return formattedLocalDate;
};

export const timeDuration = (duration = 15) => {
  const x = duration;
  const times = [];
  let tt = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; tt <= 8 * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    // eslint-disable-next-line prefer-template
    const duration = ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2);
    if (tt) times[i] = { id: tt.toString(), value: duration };
    tt += x;
  }
  return times;
};

export const timeThrottle = (Throttle = 15) => {
  const x = Throttle;
  const times = [];
  let tt = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; tt <= 8 * 60; i++) {
    const hh = Math.floor(tt / 60);
    const mm = tt % 60;
    // eslint-disable-next-line prefer-template
    const Throttle = ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2);
    times[i] = { id: tt.toString(), value: Throttle };
    tt += x;
  }

  return times;
};

export const getDayName = (day: number) => {
  switch (day) {
    case 1:
      return "monday";
    case 2:
      return "tuesday";
    case 3:
      return "wednesday";
    case 4:
      return "thursday";
    case 6:
    case 7:
      return "sunday";
    default:
      return "";
  }
};
export const handleSequenceStatus = (status?: any) => {
  switch (status) {
    case "Draft":
      return { name: "Draft", color: "warning" };
    case "Created":
      return { name: "Active", color: "success" };
    case "Active":
      return { name: "Active", color: "success" };
    case "Inactive":
      return { name: "InActive", color: "info" };
    case "Deleted":
      return { name: "Deleted", color: "error" };
    default:
      break;
  }
  return {
    name: "",
    color: "",
  };
};

// export function formatPhoneNumber(phoneNumberString: string) {

//   const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
//   const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   if (match) {
//     const intlCode = match[1] ? "+1 " : "";
//     if(phoneNumberString.includes("+1")){
//     return [ "(", match[2], ") ", match[3], "-", match[4]].join("")
//     }
//     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
//   }
//   return phoneNumberString;
// }

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode =
      phoneNumberString.charAt(0) !== "+" && match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return phoneNumberString;
}

export function getNumberWithCode(code?: string, number?: string) {
  // show number with country code
  if (number && code) {
    return code + number;
  }
  return number;
}

export const convertToBase64 = (file: any): Promise<any> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function groupBy(list: any[], keyGetter: any) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const callGapList = [
  {
    value: 0,
    id: 0,
  },
  {
    value: 30,
    id: 30,
  },
  {
    value: 60,
    id: 60,
  },
  {
    value: 120,
    id: 120,
  },
  {
    value: 180,
    id: 180,
  },
];
export function textEllipsis(
  str: string,
  maxLength: number,
  { ellipsis = "..." } = {},
) {
  if (!str) {
    return "";
  }
  if (str.length > maxLength) {
    return str.slice(0, maxLength - ellipsis.length) + ellipsis;
  }
  return str;
}

export function getFullName(params: { firstName: string; lastName: string }) {
  const firstName = params.firstName || "";
  const lastName = params.lastName || "";
  const fullName = `${firstName} ${lastName}`;
  return fullName;
}

export function timeDiffCalc(date: any) {
  const dateNow: any = new Date();
  const dateFuture = new Date(Number(date));
  let diffInMilliSeconds = Math.abs(date - dateNow) / 1000;
  // calculate days
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "";
  if (days > 0 && days <= 7) {
    difference = days === 1 ? `${days} day ago` : `${days} days ago`;
    return difference;
  }
  if (days <= 0 && (hours > 0 || minutes >= 0)) {
    if (hours > 0) {
      difference = hours === 1 ? `${hours} hr ago` : `${hours} hrs ago`;
      return difference;
    }
    if (minutes >= 0) {
      difference =
        minutes === 0 || minutes === 1 ? "Just now" : `${minutes} minutes ago`;
      return difference;
    }
  }
  // return the post created date
  const getCurrentYear =
    dateFuture.getFullYear() !== dateNow.getFullYear()
      ? dateFuture.getFullYear()
      : "";
  difference = `${getMonthName(
    dateFuture.getMonth(),
  )}  ${dateFuture.getDate()} ${
    getCurrentYear !== "" ? `, ${getCurrentYear}` : ""
  }`;
  return difference;
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getMonthName = (monthNumber: number) => {
  return monthNames[monthNumber];
};

export type DynamicObject = Record<string, any>;

export const valueFromKey = (object: DynamicObject, text: string): string => {
  if (!object || !text) return "";
  return text.replace(/%(\w+)%/g, (_, key) => {
    switch (key) {
      case "fullname":
      case "fullName":
        return `${object?.firstName || ""} ${object?.lastName || ""}`.trim();
      case "address":
        return object?.address?.address || "";
      // --- start activity
      case "dateTime":
        return dateFormatter(object?.dateTime) || "";
      case "userName":
        return object?.userName || "";
      case "activityType":
        return object?.activityType || "";
      //  --- end activity
      case "state":
        return object?.address?.state || "";
      case "city":
        return object?.address?.city || "";
      case "zipCode":
        return object?.address?.zipCode || "";
      case "employeeType":
        return object?.contractDetails?.employeeType || "";
      case "serviceAgreementMonth":
        return object?.contractDetails?.serviceAgreementMonth || "";
      case "serviceAgreementDays":
        return object?.contractDetails?.serviceAgreementDays || "";
      case "hoursPerDayWork":
        return object?.contractDetails?.hoursPerDayWork || "";
      case "virtualEmployees":
        return object?.contractDetails?.virtualEmployees || "";
      case "totalHoursPerMonth":
        return object?.contractDetails?.totalHoursPerMonth || "";
      case "totalHoursPerCycle":
        return object?.contractDetails?.totalHoursPerCycle || "";
      case "daysPriorTocancel":
        return object?.contractDetails?.daysPriorTocancel || "";
      case "setupFee":
        return object?.contractDetails?.setupFee || "";
      case "rate":
        return object?.contractDetails?.rate || "";
      case "perAppointmentFee":
        return object?.contractDetails?.perAppointmentFee || "";
      default:
        return object?.[key] || `%${key}%`; // Fallback to original placeholder
    }
  });
};

// old -1
// export const handleTemplateFill = (params: ITemplateGenerateParams) => {
//   const {
//     templates,

//     values,
//     formik,
//   } = params;
//   const { setFieldValue, values: formikValues } = formik;
//   if (!templates) return;
//   const templateEmailClientInvite = templates.filter(
//     (x) =>
//       x.type.toLowerCase() === EActivityTemplateTypeFilter.CLIENT_INVITE_Email,
//   );
//   const templateEmailUserInvite = templates.filter(
//     (x) =>
//       x.type.toLowerCase() === EActivityTemplateTypeFilter.USER_INVITE_Email,
//   );
//   const templateSmsClientInvite = templates.filter(
//     (x) =>
//       x.type.toLowerCase() === EActivityTemplateTypeFilter.CLIENT_INVITE_SMS,
//   );

//   const templateSmsUserInvite = templates.filter(
//     (x) => x.type.toLowerCase() === EActivityTemplateTypeFilter.USER_INVITE_SMS,
//   );
//   const templateEmailClientReminder = templates.filter(
//     (x) =>
//       x.type.toLowerCase() ===
//       EActivityTemplateTypeFilter.CLIENT_REMINDER_Email,
//   );
//   const templateEmailUserReminder = templates.filter(
//     (x) =>
//       x.type.toLowerCase() === EActivityTemplateTypeFilter.USER_REMINDER_Email,
//   );
//   const templateSmsClientReminder = templates.filter(
//     (x) =>
//       x.type.toLowerCase() === EActivityTemplateTypeFilter.CLIENT_REMINDER_SMS,
//   );

//   const templateSmsUserReminder = templates.filter(
//     (x) =>
//       x.type.toLowerCase() === EActivityTemplateTypeFilter.USER_REMINDER_SMS,
//   );

//   const templateKeys = {
//     firstName: values.firstName,
//     lastName: values.lastName,
//     userName: values.userName,
//     activityType: values.activityType,
//     dateTime: values.dateTime,
//   };

//   //  - invite --start
//   const emailClientInvite = valueFromKey(
//     templateKeys,
//     templateEmailClientInvite?.[0].templateText,
//   );
//   const emailUserInvite = valueFromKey(
//     templateKeys,
//     templateEmailUserInvite?.[0].templateText,
//   );
//   const emailClientInviteSubject = valueFromKey(
//     templateKeys,
//     templateEmailClientInvite?.[0].subject,
//   );
//   const emailUserInviteSubject = valueFromKey(
//     templateKeys,
//     templateEmailUserInvite?.[0].subject,
//   );
//   const smsClientInvite = valueFromKey(
//     templateKeys,
//     templateSmsClientInvite?.[0].templateText,
//   );
//   const smsUserInvite = valueFromKey(
//     templateKeys,
//     templateSmsUserInvite?.[0].templateText,
//   );
//   //  - invite --end
//   //  - reminder--start

//   const emailClientReminder = valueFromKey(
//     templateKeys,
//     templateEmailClientReminder?.[0].templateText,
//   );
//   const emailUserReminder = valueFromKey(
//     templateKeys,
//     templateEmailUserReminder?.[0].templateText,
//   );
//   const emailClientReminderSubject = valueFromKey(
//     templateKeys,
//     templateEmailClientReminder?.[0].subject,
//   );
//   const emailUserReminderSubject = valueFromKey(
//     templateKeys,
//     templateEmailUserReminder?.[0].subject,
//   );
//   const smsClientReminder = valueFromKey(
//     templateKeys,
//     templateSmsClientReminder?.[0].templateText,
//   );
//   const smsUserReminder = valueFromKey(
//     templateKeys,
//     templateSmsUserReminder?.[0].templateText,
//   );
//   // reminder end
//   // const smsUser = valueFromKey(templateKeys, templateSmsUser?.[0].templateText);
//   setFieldValue("emailInviteTemplateClient", emailClientInvite);
//   setFieldValue("emailInviteTemplateSubjectClient", emailClientInviteSubject);
//   setFieldValue("emailInviteTemplateUser", emailUserInvite);
//   setFieldValue("emailInviteTemplateSubjectUser", emailUserInviteSubject);
//   setFieldValue("smsInviteTemplateClient", smsClientInvite);
//   setFieldValue("smsInviteTemplateUser", smsUserInvite);
//   //  - invite -end
//   setFieldValue("emailReminderTemplateClient", emailClientReminder);
//   setFieldValue(
//     "emailReminderTemplateSubjectClient",
//     emailClientReminderSubject,
//   );
//   setFieldValue("emailReminderTemplateUser", emailUserReminder);
//   setFieldValue("emailReminderTemplateSubjectUser", emailUserReminderSubject);
//   setFieldValue("smsReminderTemplateClient", smsClientReminder);
//   setFieldValue("smsReminderTemplateUser", smsUserReminder);

//   console.log({
//     emailClientInvite,
//     emailClientInviteSubject,
//     emailUserInvite,
//     emailUserInviteSubject,
//     smsClientInvite,
//     smsUserInvite,
//   });

//   console.log("updating here", formikValues);
// };

// old-2
// export const handleTemplateFill = async (params: ITemplateGenerateParams) => {
//   const { templates, values, formik } = params;
//   const { setFieldValue, validateForm, values: formikVal } = formik;

//   if (!templates) return;

//   const templateMap = templates.reduce(
//     (acc, template) => {
//       const type = template.type.toLowerCase();
//       if (!acc[type]) acc[type] = [];
//       acc[type].push(template);
//       return acc;
//     },
//     {} as Record<string, any[]>,
//   );

//   const templateKeys = {
//     firstName: values.firstName,
//     lastName: values.lastName,
//     userName: values.userName,
//     activityType: values.activityType,
//     dateTime: values.dateTime,
//   };

//   // Dynamic field configuration
//   const fieldConfig = {
//     [EActivityTemplateTypeFilter.CLIENT_INVITE_Email]: [
//       { fieldKey: "emailInviteTemplateClient", templateField: "templateText" },
//       {
//         fieldKey: "emailInviteTemplateSubjectClient",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.USER_INVITE_Email]: [
//       { fieldKey: "emailInviteTemplateUser", templateField: "templateText" },
//       {
//         fieldKey: "emailInviteTemplateSubjectUser",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.CLIENT_INVITE_SMS]: [
//       { fieldKey: "smsInviteTemplateClient", templateField: "templateText" },
//     ],
//     [EActivityTemplateTypeFilter.USER_INVITE_SMS]: [
//       { fieldKey: "smsInviteTemplateUser", templateField: "templateText" },
//     ],
//     [EActivityTemplateTypeFilter.CLIENT_REMINDER_Email]: [
//       {
//         fieldKey: "emailReminderTemplateClient",
//         templateField: "templateText",
//       },
//       {
//         fieldKey: "emailReminderTemplateSubjectClient",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.USER_REMINDER_Email]: [
//       {
//         fieldKey: "emailReminderTemplateUser",
//         templateField: "templateText",
//       },
//       {
//         fieldKey: "emailReminderTemplateSubjectUser",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.CLIENT_REMINDER_SMS]: [
//       { fieldKey: "smsReminderTemplateClient", templateField: "templateText" },
//     ],
//     [EActivityTemplateTypeFilter.USER_REMINDER_SMS]: [
//       { fieldKey: "smsReminderTemplateUser", templateField: "templateText" },
//     ],
//   };

//   // Process templates dynamically
//   Object.entries(fieldConfig).forEach(async ([templateType, fields]) => {
//     const templatesForType = templateMap[templateType.toLowerCase()];
//     if (templatesForType?.length) {
//       const [template] = templatesForType;

//       fields.forEach(async ({ fieldKey, templateField }) => {
//         const valueToSet = valueFromKey(templateKeys, template[templateField]);
//         console.log({ fieldKey, valueToSet });
//         await setFieldValue(fieldKey, valueToSet);
//         // Delay to check updated values
//         setTimeout(() => {
//           console.log(`Updated ${fieldKey}:`, formikVal[fieldKey]);
//         }, 100); // Allow async setFieldValue to complete
//       });
//     }
//   });

//   console.log("updating ..", formikVal);

//   // Validate form values to ensure all fields are populated
//   await validateForm();
// };

// Utility function for template mapping
const createTemplateMap = (templates: ITemplate[]) =>
  templates.reduce<Record<string, ITemplate[]>>((acc, template) => {
    const type = template.type.toLowerCase();
    if (!acc[type]) acc[type] = [];
    acc[type].push(template);
    return acc;
  }, {});

// Utility function to determine if a field should be processed
const shouldProcessField = (
  templateType: string,
  formikValues: Record<string, any>,
): boolean => {
  const { isReminder, isSendEmailToAttendee, isSendSmsToAttendee } =
    formikValues;

  const conditions: Record<string, boolean> = {
    "sms-user-invite": isReminder,
    "email-user-invite": isReminder,
    "sms-user-reminder": isReminder,
    "email-user-reminder": isReminder,
    "email-client-invite": isSendEmailToAttendee,
    "email-client-reminder": isSendEmailToAttendee,
    "sms-client-invite": isSendSmsToAttendee,
    "sms-client-reminder": isSendSmsToAttendee,
  };

  return conditions[templateType] ?? true;
};
// Function to dynamically set formik values
const processTemplates = async (
  fieldConfig: Record<string, any[]>,
  templateMap: Record<string, any[]>,
  templateKeys: Record<string, any>,
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>, // Accepts FormikErrors
  formikValues: IAddActivityForm,
): Promise<void> => {
  Object.entries(fieldConfig).forEach(([templateType, fields]) => {
    const templatesForType = templateMap[templateType.toLowerCase()];
    if (templatesForType?.length) {
      const [template] = templatesForType;

      fields.forEach(async ({ fieldKey, templateField }) => {
        if (!shouldProcessField(templateType, formikValues)) return;

        const valueToSet = valueFromKey(templateKeys, template[templateField]);
        try {
          await setFieldValue(fieldKey, valueToSet); // shouldValidate defaults to false
          // setTimeout(() => {
          //   console.log(`Updated ${fieldKey}:`, valueToSet);
          // }, 300);
        } catch (error) {
          console.error(`Failed to set field ${fieldKey}:`, error);
        }
      });
    }
  });
};

// Main function
export const handleTemplateFill = async (params: ITemplateGenerateParams) => {
  const { templates, values, formik } = params;
  const { setFieldValue, validateForm, values: formikValues } = formik;

  if (!templates) {
    console.warn("Templates are empty or undefined");
    return;
  }

  const templateMap = createTemplateMap(templates);

  const templateKeys = {
    firstName: values.firstName,
    lastName: values.lastName,
    userName: values.userName,
    activityType: values.activityType,
    dateTime: values.dateTime,
  };

  // Dynamic field configuration
  const fieldConfig = {
    [EActivityTemplateTypeFilter.CLIENT_INVITE_Email]: [
      { fieldKey: "emailInviteTemplateClient", templateField: "templateText" },
      {
        fieldKey: "emailInviteTemplateSubjectClient",
        templateField: "subject",
      },
    ],
    [EActivityTemplateTypeFilter.USER_INVITE_Email]: [
      { fieldKey: "emailInviteTemplateUser", templateField: "templateText" },
      { fieldKey: "emailInviteTemplateSubjectUser", templateField: "subject" },
    ],
    [EActivityTemplateTypeFilter.CLIENT_INVITE_SMS]: [
      { fieldKey: "smsInviteTemplateClient", templateField: "templateText" },
    ],
    [EActivityTemplateTypeFilter.USER_INVITE_SMS]: [
      { fieldKey: "smsInviteTemplateUser", templateField: "templateText" },
    ],
    [EActivityTemplateTypeFilter.CLIENT_REMINDER_Email]: [
      {
        fieldKey: "emailReminderTemplateClient",
        templateField: "templateText",
      },
      {
        fieldKey: "emailReminderTemplateSubjectClient",
        templateField: "subject",
      },
    ],
    [EActivityTemplateTypeFilter.USER_REMINDER_Email]: [
      { fieldKey: "emailReminderTemplateUser", templateField: "templateText" },
      {
        fieldKey: "emailReminderTemplateSubjectUser",
        templateField: "subject",
      },
    ],
    [EActivityTemplateTypeFilter.CLIENT_REMINDER_SMS]: [
      { fieldKey: "smsReminderTemplateClient", templateField: "templateText" },
    ],
    [EActivityTemplateTypeFilter.USER_REMINDER_SMS]: [
      { fieldKey: "smsReminderTemplateUser", templateField: "templateText" },
    ],
  };

  await processTemplates(
    fieldConfig,
    templateMap,
    templateKeys,
    setFieldValue,
    formikValues,
  );

  // console.log("Final Formik Values after Template Fill:", formikValues);

  // Validate the form after all fields are set
  await validateForm();
};

// export const handleTemplateFill = async (params: ITemplateGenerateParams) => {
//   const { templates, values, formik } = params;

//   const { setFieldValue, validateForm, values: formikVal } = formik;
//   if (!templates) return;
//   console.log({ templates });

//   const templateMap = templates.reduce(
//     (acc, template) => {
//       const type = template.type.toLowerCase();
//       if (!acc[type]) acc[type] = [];
//       acc[type].push(template);
//       return acc;
//     },
//     {} as Record<string, any[]>,
//   );

//   const templateKeys = {
//     firstName: values.firstName,
//     lastName: values.lastName,
//     userName: values.userName,
//     activityType: values.activityType,
//     dateTime: values.dateTime,
//   };

//   // Dynamic field configuration
//   const fieldConfig = {
//     [EActivityTemplateTypeFilter.CLIENT_INVITE_Email]: [
//       { fieldKey: "emailInviteTemplateClient", templateField: "templateText" },
//       {
//         fieldKey: "emailInviteTemplateSubjectClient",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.USER_INVITE_Email]: [
//       { fieldKey: "emailInviteTemplateUser", templateField: "templateText" },
//       {
//         fieldKey: "emailInviteTemplateSubjectUser",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.CLIENT_INVITE_SMS]: [
//       { fieldKey: "smsInviteTemplateClient", templateField: "templateText" },
//     ],
//     [EActivityTemplateTypeFilter.USER_INVITE_SMS]: [
//       { fieldKey: "smsInviteTemplateUser", templateField: "templateText" },
//     ],
//     [EActivityTemplateTypeFilter.CLIENT_REMINDER_Email]: [
//       {
//         fieldKey: "emailReminderTemplateClient",
//         templateField: "templateText",
//       },
//       {
//         fieldKey: "emailReminderTemplateSubjectClient",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.USER_REMINDER_Email]: [
//       {
//         fieldKey: "emailReminderTemplateUser",
//         templateField: "templateText",
//       },
//       {
//         fieldKey: "emailReminderTemplateSubjectUser",
//         templateField: "subject",
//       },
//     ],
//     [EActivityTemplateTypeFilter.CLIENT_REMINDER_SMS]: [
//       { fieldKey: "smsReminderTemplateClient", templateField: "templateText" },
//     ],
//     [EActivityTemplateTypeFilter.USER_REMINDER_SMS]: [
//       { fieldKey: "smsReminderTemplateUser", templateField: "templateText" },
//     ],
//   };

//   // Determine whether to process fields based on conditions
//   const shouldProcessField = (
//     templateType: string,
//     fieldKey: string,
//   ): boolean => {
//     if (
//       (templateType.includes("sms-user-invite") && !formikVal.isReminder) ||
//       (templateType.includes("email-user-invite") && !formikVal.isReminder) ||
//       (templateType.includes("sms-user-reminder") && !formikVal.isReminder) ||
//       (templateType.includes("email-user-reminder") && !formikVal.isReminder) ||
//       (templateType.includes("email-client-invite") &&
//         !formikVal.isSendEmailToAttendee) ||
//       (templateType.includes("email-client-reminder") &&
//         !formikVal.isSendEmailToAttendee) ||
//       (templateType.includes("sms-client-invite") &&
//         !formikVal.isSendSmsToAttendee) ||
//       (templateType.includes("sms-client-reminder") &&
//         !formikVal.isSendSmsToAttendee)
//     ) {
//       console.log(`Skipping ${fieldKey} for ${templateType}`);
//       return false;
//     }
//     return true;
//   };

//   // Process templates dynamically
//   Object.entries(fieldConfig).forEach(([templateType, fields]) => {
//     const templatesForType = templateMap[templateType.toLowerCase()];
//     console.log({ templatesForType });
//     if (templatesForType?.length) {
//       const [template] = templatesForType;
//       console.log({ fields });
//       fields.forEach(async ({ fieldKey, templateField }) => {
//         if (!shouldProcessField(templateType, fieldKey)) return;
//         console.log({ templateField });
//         setTimeout(async () => {
//           const valueToSet = valueFromKey(
//             templateKeys,
//             template[templateField],
//           );
//           console.log({ fieldKey, valueToSet });
//           await setFieldValue(fieldKey, valueToSet);

//           setTimeout(() => {
//             console.log(`Updated ${fieldKey}:`, formikVal[fieldKey]);
//           }, 300);
//         });
//       }, 300);
//     }
//   });

//   console.log("Final Form Values", formikVal);

//   // Validate form values to ensure all fields are populated
//   await validateForm();
// };

export async function getIpAddress() {
  try {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
}

export function convertToCurrency(currency: any) {
  return (
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(currency) || 0
  );
}

export const rowTextColor = (bgColor?: any) => {
  const getColor = ["#ff0e40", "#0000ff", "rgb(159 51 45)", "#909090"].includes(
    bgColor || "",
  )
    ? "#FFFFFF"
    : "#000000";
  return getColor;
};

// refetch API another component
let refetchSubscribers: Array<() => void> = [];

export const subscribeToRefetch = (callback: () => void) => {
  refetchSubscribers.push(callback);
};

export const unsubscribeFromRefetch = (callback: () => void) => {
  refetchSubscribers = refetchSubscribers.filter(
    (subscriber) => subscriber !== callback,
  );
};

export const triggerRefetch = () => {
  refetchSubscribers.forEach((callback) => callback());
};

export function fileType(url: string) {
  const lowercaseUrl = url?.toLowerCase(); // Convert the URL to lowercase for case-insensitivity

  if (lowercaseUrl?.includes(".pdf")) {
    return "pdf";
  }
  if (
    lowercaseUrl?.match(/\.(jpg|jpeg|png|gif|webp|jfif|avif|avif|svg|bmp|ico)$/)
  ) {
    return "image";
  }
  if (lowercaseUrl?.match(/\.(mp4|webm|wmv|mov|flv|mkv|mpeg-2|3gp|avi)$/)) {
    return "video";
  }
  if (lowercaseUrl?.match(/\.(ppt|pptx|ppsx|pps|pot|potx|pptm|ppsm|potm)$/)) {
    return "ppt";
  }
  if (
    lowercaseUrl?.match(/\.(doc|docx|dotx|docm|dotm|odt|rtf|mht|dic|thmx)$/)
  ) {
    return "word";
  }

  // if (lowercaseUrl?.includes(".doc")) {
  //   return "doc";
  // }
  if (lowercaseUrl?.includes(".txt")) {
    return "text/plain";
  }
  if (
    lowercaseUrl?.match(
      /\.(xlsx|xls|csv)$|application\/vnd\.openxmlformats-officedocument\.spreadsheetml\.sheet/,
    )
  ) {
    return "excel";
  }
  if (lowercaseUrl?.includes(".csv")) {
    return "text/csv";
  }
  if (
    lowercaseUrl?.match(
      /\.(mp3|m4a|wav|aiff|flac|aac|ogg$)|audio\//, // Add more audio MIME types as needed
    )
  ) {
    return "audio";
  }
  if (lowercaseUrl?.match(/(https?:\/\/[^\s]+)/g)) {
    return "link";
  }

  // Add more conditions for other file types if needed

  // If no match is found, return an empty string or a default type
  return "not supported";
}
// blob to base 64
export const blobToBase64 = (blob: any) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

// not in use
export async function downloadFile(fileUrl: string, fileName: string) {
  try {
    const response: Blob = await client.get(fileUrl, {
      responseType: "blob",
    });
    if (!response || !response.type) {
      console.error("Response or headers are undefined", response);
    }

    const contentType = response.type;
    const generatedFileName = generateFileName(
      contentType || "application/octet-stream",
      fileName,
    );

    const url = window.URL.createObjectURL(response);
    const a = document.createElement("a");
    a.href = url;
    a.download = generatedFileName;
    document.body.appendChild(a);
    a.click();
    // Clean up by revoking the object URL and removing the link
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 10);
  } catch (error) {
    console.error("Error while downloading the file:", error);
  }
}

// contact or customer file type
export const acceptCustomerType = {
  "application/vnd.ms-excel": [".csv", ".xls", ".xlsx"],
  "text/csv": [".csv"],
};

export const acceptCSVType = {
  "application/vnd.ms-excel": [".csv"],
  "text/csv": [".csv"],
};

export const acceptPDFType = {
  "application/pdf": [".pdf"],
};

// get file size in kb
export const convertBytesToKB = (bytes: any) =>
  Math.round(bytes / KILO_BYTES_PER_BYTE);

// generate dynamic file name and type
export function generateFileName(contentType: string, fileName: string) {
  try {
    const typeMapping: any = {
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        "pptx",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "docx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "xlsx",
      "application/pdf": "pdf",
      "image/jpeg": "jpg",
      "image/png": "png",
      "text/plain": "txt",
      "application/zip": "zip",
      "application/x-rar-compressed": "rar",
      "application/msword": "doc",
      "application/vnd.ms-excel": "xls",
      "application/vnd.ms-powerpoint": "ppt",
      "application/vnd.oasis.opendocument.text": "odt",
      "application/vnd.oasis.opendocument.spreadsheet": "ods",
      "application/vnd.oasis.opendocument.presentation": "odp",
      "text/csv": "csv",
      "image/gif": "gif",
      "image/tiff": "tiff",
      "image/bmp": "bmp",
      "audio/mpeg": "mp3",
      "video/mp4": "mp4",
      "audio/x-wav": "wav",
      "audio/aac": "aac",
      "video/x-msvideo": "avi",
      "video/mpeg": "mpeg",
      "application/x-7z-compressed": "7z",
      "application/x-tar": "tar",
      "application/vnd.rar": "rar",
      "application/gzip": "gz",
      "application/vnd.ms-excel.sheet.macroenabled.12": "xlsm",
      "application/vnd.ms-excel.sheet.binary.macroenabled.12": "xlsb",
      "application/vnd.ms-powerpoint.template.macroenabled.12": "potm",
      "application/vnd.ms-powerpoint.addin.macroenabled.12": "ppam",
      "application/vnd.ms-powerpoint.slideshow.macroenabled.12": "ppsm",
      "application/vnd.ms-powerpoint.presentation.macroenabled.12": "pptm",
      "application/vnd.ms-word.document.macroenabled.12": "docm",
      "application/vnd.ms-word.template.macroenabled.12": "dotm",
    };
    let extension = typeMapping[contentType];
    // Extract the file extension from the content type or use a default extension
    if (!extension) {
      extension = contentType ? contentType.split("/")[1] : "txt";
    }
    return `${fileName}-${date.getTime()}.${extension}`;
  } catch (error) {
    console.error("error while generating name:", error);
    throw error;
  }
}

export function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10,
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8,
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue?.trim();
}

export function formatCVC(value: string, allValues = {} as any) {
  const clearValue = clearNumber(value);

  let maxLength = 5;

  if (allValues?.cardNumber) {
    const issuer = Payment.fns.cardType(allValues?.cardNumber);
    if (issuer === "amex") {
      maxLength = 4;
    }
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export const generateInvoice = () => {
  return `<div>
<body data-gr-ext-installed="" data-new-gr-c-s-check-loaded="14.1105.0">
  <header className="clearfix">
    <div id="logo">
      <img loading="lazy" src="logo.png" />
    </div>
    <h1>INVOICE 3-2-1</h1>
    <div className="clearfix" id="company">
      <div>Company Name</div>
      <div>
        455 Foggy Heights,
        <br /> AZ 85004, US
      </div>
      <div>(602) 519-0450</div>
      <div>
        <a href="mailto:company@example.com">company@example.com</a>
      </div>
    </div>
    <div id="project">
      <div>
        <span>PROJECT</span> Website development
      </div>
      <div>
        <span>CLIENT</span> John Doe
      </div>
      <div>
        <span>ADDRESS</span> 796 Silver Harbour, TX 79273, US
      </div>
      <div>
        <span>EMAIL</span>{" "}
        <a href="mailto:john@example.com">john@example.com</a>
      </div>
      <div>
        <span>DATE</span> August 17, 2015
      </div>
      <div>
        <span>DUE DATE</span> September 17, 2015
      </div>
    </div>
  </header>
  <main>
    <table>
      <thead>
        <tr>
          <th className="service">SERVICE</th>
          <th className="desc">DESCRIPTION</th>
          <th>PRICE</th>
          <th>QTY</th>
          <th>TOTAL</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="service">Design</td>
          <td className="desc">
            Creating a recognizable design solution based on the company
            &apos s existing visual identity
          </td>
          <td className="unit">$40.00</td>
          <td className="qty">26</td>
          <td className="total">$1,040.00</td>
        </tr>
        <tr>
          <td className="service">Development</td>
          <td className="desc">
            Developing a Content Management System-based Website
          </td>
          <td className="unit">$40.00</td>
          <td className="qty">80</td>
          <td className="total">$3,200.00</td>
        </tr>
        <tr>
          <td className="service">SEO</td>
          <td className="desc">
            Optimize the site for search engines (SEO)
          </td>
          <td className="unit">$40.00</td>
          <td className="qty">20</td>
          <td className="total">$800.00</td>
        </tr>
        <tr>
          <td className="service">Training</td>
          <td className="desc">
            Initial training sessions for staff responsible for uploading
            web content
          </td>
          <td className="unit">$40.00</td>
          <td className="qty">4</td>
          <td className="total">$160.00</td>
        </tr>
        <tr>
          <td colSpan={4}>SUBTOTAL</td>
          <td className="total">$5,200.00</td>
        </tr>
        <tr>
          <td colSpan={4}>TAX 25%</td>
          <td className="total">$1,300.00</td>
        </tr>
        <tr>
          <td className="grand total" colSpan={4}>
            GRAND TOTAL
          </td>
          <td className="grand total">$6,500.00</td>
        </tr>
      </tbody>
    </table>
    <div id="notices">
      <div>NOTICE:</div>
      <div className="notice">
        A finance charge of 1.5% will be made on unpaid balances after 30
        days.
      </div>
    </div>
  </main>
  <footer>
    Invoice was created on a computer and is valid without the signature
    and seal.
  </footer>
</body>
</div>`;
};

export const templateForCustomerApproved = (data: any) => {
  return `<div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #f5f5f5; border-radius: 10px;">

  <h2 style="color: #333;">Customer Approval Notification</h2>

  <p>Hello :ContactName,</p>

  <p>We are pleased to inform you that our quality controller has approved a conversation . To view the details, please click on the link below:</p>

  <a href="${data.customerUrl}" style="display: inline-block; padding: 10px 20px; background-color: #4caf50; color: #fff; text-decoration: none; border-radius: 5px; margin-top: 15px;">Check Approval</a>

  <p>If the above link doesn't work, you can copy and paste the following URL into your browser:</p>

  <p>Thank you for your attention and congratulations on the approval!</p>

  <p>Best regards,<br>
  cutcrm.com</p>

</div>`;
};

export const templateForgotPassword = (resetPasswordUrl: string) => {
  return `<div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #f5f5f5; border-radius: 10px;">

      <h2 style="color: #333;">Reset Password Request</h2>

      <p>Hello :ClientName,</p>

      <p>We received a request to reset your password. If you initiated this request, please click on the link below to reset your password:</p>

      <a href="${resetPasswordUrl}" style="display: inline-block; padding: 10px 20px; background-color: #007bff; color: #fff; text-decoration: none; border-radius: 5px; margin-top: 15px;">Reset Password</a>

      <p>If the above link doesn't work, you can copy and paste the following URL into your browser:</p>

      <p>${resetPasswordUrl}</p>

      <p>If you did not request a password reset, please ignore this email. Your account security is important to us.</p>

      <p>Thank you!</p>

      <p>Best regards,<br>
      [Your Company Name]</p>

  </div>
`;
};

export const customerStatus: IDropdown[] = [
  { id: 0, value: "None" },
  { id: 1, value: "New" },
  { id: 2, value: "Done" },
  { id: 3, value: "Lose" },
  { id: 4, value: "Approve" },
  { id: 5, value: "Reject" },
];

export function getDateRange(
  filter: string,
): { start: string; end: string } | null {
  const today: moment.Moment = moment();
  let start: moment.Moment;
  let end: moment.Moment;

  switch (filter) {
    case "today":
      start = moment(today).startOf("day");
      end = moment(today).endOf("day");
      break;
    case "thisWeek":
      start = moment(today).startOf("week");
      end = moment(today).endOf("week");
      break;
    case "lastWeek":
      start = moment(today).subtract(1, "week").startOf("week");
      end = moment(today).subtract(1, "week").endOf("week");
      break;
    case "thisMonth":
      start = moment(today).startOf("month");
      end = moment(today).endOf("month");
      break;
    case "lastMonth":
      start = moment(today).subtract(1, "month").startOf("month");
      end = moment(today).subtract(1, "month").endOf("month");
      break;
    case "3Month":
      start = moment(today).subtract(3, "months").startOf("month");
      end = moment(today).endOf("month");
      break;
    case "6Month":
      start = moment(today).subtract(6, "months").startOf("month");
      end = moment(today).endOf("month");
      break;
    default:
      // Handle invalid filter
      return null;
  }

  return {
    start: start.format("YYYY-MM-DD[T]HH:mm"),
    end: end.format("YYYY-MM-DD[T]HH:mm"),
  };
}
export const dispositionStatus: IDispositionStatus[] = [
  { id: 0, status: true },
  { id: 1, status: false },
];

export const getCustomerStatus = (status: number) => {
  switch (status) {
    case 1:
      return "New";
    case 2:
      return "Done";
    case 3:
      return "Lose";
    case 4:
      return "Approve";
    case 5:
      return "Reject";
    default:
      return "";
  }
};

export const getChipColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "paid":
      return "success";
    case "due":
      return "error";
    case "partially":
      return "primary";
    default:
      return "default";
  }
};

export const notesType = [
  {
    id: "Simple",
    value: "None",
  },
  { id: "Client", value: "Client" },
];

export const getRandomId = (min = 0, max = 500000) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  const num = Math.floor(Math.random() * (max - min + 1)) + min;
  return num.toString().padStart(6, "0");
};

export const compareDates = (d1: any, d2: any) => {
  const date1 = new Date(d1).getTime();
  const date2 = new Date(d2).getTime();

  if (date1 < date2) {
    return false;
  }
  if (date1 > date2) {
    return false;
  }
  return true;
};

export function secondsToHms(d: any) {
  if (!d) {
    return "00:00:00";
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const sec_num = parseInt(d, 10);
  const hours = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num % 3600) / 60);
  const seconds = sec_num % 60;

  const parts = [];

  if (hours > 0) {
    parts.push(hours < 10 ? `0${hours}` : `${hours}`);
  } else {
    parts.push("00");
  }

  parts.push(minutes < 10 ? `0${minutes}` : `${minutes}`);
  parts.push(seconds < 10 ? `0${seconds}` : `${seconds}`);

  return parts.join(":");
}

export function isValidUSPhoneNumber(number: any) {
  // This regular expression matches the following formats:
  // (123) 456-7890, 123-456-7890, 123.456.7890, 1234567890, +1 123-456-7890
  const phoneNumberPattern =
    /^(\+1\s)?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;
  // const phoneNumberPattern = /^(\+1\s?(\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4})$|^(\+91[-\s]?\d{10})$|^(\d{10})$/;

  return phoneNumberPattern.test(number);
}

export const convertUTCToLocal = (date: any) => {
  const utcMoment = moment.utc(date);

  const localTime = utcMoment.local();

  return localTime.format("MM-DD-YYYY hh:mm A");
};

export function calculatedDueDate(months: number) {
  const currentDate = new Date();
  const dueDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + 7 * months,
  );

  return moment(dueDate).format("YYYY-MM-DD");
}

export function updateObjectById(
  array: IFuturePayment[],
  idToUpdate: number,
  updatedProperties: {
    id?: number;
    vid?: number;
    index?: number;
    amount?: number;
    description?: string;
    dueDate?: string;
    balance?: number;
  },
) {
  // Find the index of the object with the specified ID
  const index = array.findIndex((obj) => obj.vid === idToUpdate);

  // If the object is found, update its properties
  if (index !== -1) {
    array[index] = { ...array[index], ...updatedProperties };
    return array;
    // Alternatively, you can use Object.assign:
    // array[index] = Object.assign({}, array[index], updatedProperties);
  }
}

export function convertToJSX(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return convertElementToJSX(doc.body.firstChild);
}

export function convertElementToJSX(element: any) {
  if (!element) {
    return null;
  }

  const jsxElement: any = React.createElement(
    element.tagName.toLowerCase(),
    {
      className: element.className,
      // You can add other attributes here, e.g., id, style, etc.
    },
    ...Array.from(element.childNodes).map((child: any) =>
      child.nodeType === 1 // 1 is Node.ELEMENT_NODE
        ? convertElementToJSX(child)
        : child.nodeValue,
    ),
  );

  return jsxElement;
}

// export const decodeMIMESubject = (subject: string) => {
//   const mimePattern = /=\?([^?]+)\?B\?([^?]+)\?=/g;
//   if (!mimePattern.test(subject)) {
//     return subject;
//   }
//   return subject.replace(
//     mimePattern,
//     (match: any, charset: any, encoded: any) => {
//       const decodedString: any = atob(encoded);
//       return new TextDecoder(charset).decode(
//         new Uint8Array([...decodedString].map((c) => c.charCodeAt(0))),
//       );
//     },
//   );
// };

export const decodeMIMESubject = (subject: string) => {
  const mimePattern = /=\?([^?]+)\?([BQ])\?([^?]+)\?=/gi;

  if (!mimePattern.test(subject)) {
    return subject;
  }

  return subject.replace(mimePattern, (match, charset, encoding, encoded) => {
    let decodedString;
    if (encoding.toUpperCase() === "B") {
      decodedString = atob(encoded);
    } else if (encoding.toUpperCase() === "Q") {
      decodedString = encoded
        .replace(/_/g, " ")
        .replace(/=([A-Fa-f0-9]{2})/g, (match: any, hex: any) => {
          return String.fromCharCode(parseInt(hex, 16));
        });
    } else {
      return match;
    }
    return new TextDecoder(charset).decode(
      new Uint8Array([...decodedString].map((c) => c.charCodeAt(0))),
    );
  });
};

export function generateInvoiceNumber(count: any) {
  // Get current date in UTC
  const currentDate = new Date();
  const year = currentDate.getUTCFullYear().toString().slice(-2);
  const month = (currentDate.getUTCMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const formattedDate = year + month;

  // Increment count and format as "0000"
  const formattedCount = count.toString().padStart(4, "0");

  // Combine the components
  const invoiceNumber = `INV${formattedDate}-${formattedCount}`;

  return invoiceNumber;
}

export function dynamicSort(property: any) {
  let sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    const result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
}

export function timeDifference(date1: any, date2: any) {
  if (!date1 || !date2) return 0;

  const seconds = (date1.getTime() - date2.getTime()) / 1000;

  return secondsToHms(seconds > 0 ? seconds : 0);
}

export const convertBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const validationFunc = (key: string, types: string[], error: string) => {
  return Yup.string().when(key, (value: any, field: any) =>
    types?.includes(value) ? field.required(error) : field,
  );
};

export const formatTime = (time: any) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const remainingSeconds = time % 60;
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    remainingSeconds < 10 ? "0" : ""
  }${remainingSeconds}`;
};

function flattenArray(arr: any[]): any[] {
  return arr.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val),
    [],
  );
}

export const handleChatCache = async (
  message: any,
  queryClient: any,
  selectedChatRoomId: number | undefined,
  setChatRooms: (chatRooms: any[]) => void,
  chatRooms: any[],
  chatRoomState: string,
) => {
  const chatList = await queryClient.getQueryData([
    QueryKeys.allChatRoomPagination,
  ]);

  if (!chatList?.pages) return;

  // Update chat list with the new message
  const updatedPages = chatList.pages.map((page: any) => {
    const updatedData = page.data?.map((chat: any) => {
      // Match the specific chat room and update its properties
      if (chat.id === message.roomId) {
        return {
          ...chat,
          message: message.fileUrls?.[0] || message.message,
          unReadMessagesCount:
            selectedChatRoomId === message.roomId &&
            ["main", "max"].includes(chatRoomState)
              ? 0
              : (chat.unReadMessagesCount || 0) + 1,
          lastMessageOn: message.timeStamp,
          isMentioned: message.isMentioned || false,
          lastMessageUserId: message.lastMessageUserId,
          lastMessageUserName: message.lastMessageUserName,
          type: message.type,
          exist: true,
        };
      }

      return chat; // Leave other chats unchanged
    });

    return { ...page, data: updatedData };
  });

  // Flatten the updated chat data for processing
  const flatChatList = updatedPages.flatMap((page: any) => page.data || []);

  // Check if the room exists in the chat list, add it if not
  if (!flatChatList.some((room: any) => room.id === message.roomId)) {
    flatChatList.push({
      id: message.roomId,
      uuId: message.uuId,
      isGroup: message.isGroup,
      groupName: message.groupName,
      userId: message.userId,
      toUser: message.toUser,
      fromUser: message.fromUser,
      toUserName: message.toUserName,
      fromUserName: message.fromUserName,
      toUserConnectionId: message.toUserConnectionId,
      fromUserConnectionId: message.fromUserConnectionId,
      lastMessageOn: message.timeStamp,
      status: message.status,
      message: message.fileUrls?.[0] || message.message,
      createdOn: message.createdOn,
      updatedOn: message.updatedOn,
      unReadMessagesCount: 1, // Start with 1 unread message for new rooms
      isPinned: message.isPinned || false,
      readLater: message.readLater || false,
      toProfileUrl: message.toProfileUrl,
      fromProfileUrl: message.fromProfileUrl,
      toStatus: message.toStatus,
      fromStatus: message.fromStatus,
      isBroadCast: message.isBroadCast || false,
      readLaterCount: message.readLaterCount || 0,
      lastMessageUserId: message.lastMessageUserId,
      lastMessageUserName: message.lastMessageUserName,
      isMentioned: message.isMentioned || false,
      exist: false,
    });
  }

  // Sort the final chat list by pin status and last message time
  const sortedChatList = flatChatList.sort((a: any, b: any) => {
    if (a.isPinned !== b.isPinned) return b.isPinned - a.isPinned;
    return Number(b.lastMessageOn) - Number(a.lastMessageOn);
  });

  // Update the chat rooms state
  setChatRooms?.(sortedChatList);

  // Update the QueryClient cache for allChatRoomPagination
  queryClient.setQueryData([QueryKeys.allChatRoomPagination], {
    ...chatList,
    pages: updatedPages,
  });

  // Update the QueryClient cache for the specific room
  queryClient.setQueryData(
    [QueryKeys.allChats, message.roomId],
    (oldQueryData: any) => {
      const updatedPages = (oldQueryData?.pages || []).map((page: any) => ({
        ...page,
        data: [message, ...(page.data || [])],
      }));

      return { ...oldQueryData, pages: updatedPages };
    },
  );
};

// export const handleChatCache = async (
//   message: any,
//   queryClient: any,
//   selectedChatRoomId: number | undefined,
//   setChatRooms: any,
//   chatRooms: any[],
//   chatRoomState: string,
// ) => {
//   const chatList = await queryClient.getQueryData([
//     QueryKeys.allChatRoomPagination,
//   ]);

//   console.log("chatRooms", chatRooms);
//   if (chatList?.pages) {
//     let newData: any[] = [];
//     const latestList = chatList?.pages?.map((page: any) => {
//       console.log("chat.unReadMessagesCount", chatRoomState);
//       const newChatList = page?.data?.map((chat: any) => {
//         let newChat: any = chat;
//         if (chat.id === message.roomId) {
//           newChat = {
//             ...chat,
//             message: message.fileUrls?.[0]
//               ? message.fileUrls?.[0]
//               : message.message,
//             unReadMessagesCount:
//               selectedChatRoomId &&
//                 selectedChatRoomId === message?.roomId &&
//                 ["main", "max"].includes(chatRoomState)
//                 ? 0
//                 : chat.unReadMessagesCount + 1,
//             lastMessageOn: message.timeStamp,
//             isMentioned: message.isMentioned,
//             lastMessageUserId: message.lastMessageUserId,
//             lastMessageUserName: message.lastMessageUserName,
//             type: message.type,
//             exist: true,
//           };
//         }
//         return newChat;
//       });
//       if (newChatList?.length > 0) newData = [...newData, newChatList];
//       return { ...page, data: newChatList };
//     });

//     queryClient.setQueryData([QueryKeys.allChatRoomPagination], {
//       ...chatList,
//       pages: latestList,
//     });
//     newData = newData.flat();
//     const isExistRoom = newData?.find(
//       (room: any) => room.id === message.roomId,
//     );
//     let newChatList: any[] = [];
//     if (!isExistRoom) {
//       const newChat = {
//         ...({} as IChatRoom),
//         id: message.roomId,
//         uuId: message.uuId,
//         isGroup: message.isGroup,
//         groupName: message.groupName,
//         userId: message.userId,
//         toUser: message.toUser,
//         fromUser: message.fromUser,
//         toUserName: message.toUserName,
//         fromUserName: message.fromUserName,
//         toUserConnectionId: message.toUserConnectionId,
//         fromUserConnectionId: message.fromUserConnectionId,
//         lastMessageOn: message.lastMessageOn,
//         status: message.status,
//         message: message.message,
//         createdOn: message.createdOn,
//         updatedOn: message.updatedOn,
//         unReadMessagesCount: message.unReadMessagesCount,
//         isPinned: message.isPinned,
//         readLater: message.readLater,
//         toProfileUrl: message.toProfileUrl,
//         fromProfileUrl: message.fromProfileUrl,
//         toStatus: message.toStatus,
//         fromStatus: message.fromStatus,
//         isBroadCast: message.isBroadCast,
//         readLaterCount: message.readLaterCount,
//         lastMessageUserId: message.lastMessageUserId,
//         lastMessageUserName: message.lastMessageUserName,
//         isMentioned: message.isMentioned,
//         exist: false,
//       };
//       newData = [...newData, newChat];
//     }
//     newChatList = newData?.sort((a: any, b: any) => {
//       if (a.isPinned !== b.isPinned) {
//         return b.isPinned - a.isPinned;
//       }
//       return Number(b.lastMessageOn) - Number(a.lastMessageOn);
//     });

//     setChatRooms?.(newChatList);
//   }

//   queryClient.setQueryData(
//     [QueryKeys.allChats, message.roomId],
//     (oldQueryData: any) => {
//       const newPage = (oldQueryData?.pages || []).map((page: any) => {
//         const newData = [message, ...(page.data || [])];
//         const updateChatCache = { ...page, data: newData };
//         return updateChatCache;
//       });
//       return { ...oldQueryData, pages: newPage };
//     },
//   );
//   // queryClient.setQueryData([QueryKeys.unreadCount], updateUnReadData);
// };

export const getUuid = () => {
  return uuidv4();
};

export const handleAssignContactGroup = async (
  room: number,
  contactId: number,
  contactName: string,
  queryClient: any,
) => {
  if (!room) return;

  const chatList = await queryClient.getQueryData([
    QueryKeys.allChatRoomPagination,
  ]);
  if (chatList?.pages) {
    let newData: any[] = [];
    const latestList = chatList?.pages?.map((page: any) => {
      const newChatList = page?.data?.map((chat: any) => {
        let newChat: any = chat;
        if (chat.id === room) {
          newChat = {
            ...chat,
            contactId,
            contactName,
          };
        }
        return newChat;
      });
      if (newChatList?.length > 0) newData = [...newData, newChatList];
      return { ...page, data: newChatList };
    });
    queryClient.setQueryData([QueryKeys.allChatRoomPagination], {
      ...chatList,
      pages: latestList,
    });
  }
};

export const convertCamelToNormal = (text: string): string => {
  if (text === "" || text === " ") return "";
  // Add space before uppercase letters and capitalize the first letter
  const hasUpperCase = /[A-Z]/.test(text);

  if (!hasUpperCase) {
    return text; // If no uppercase letters are found, return the original string
  }
  return text
    .replace(/([A-Z])/g, " $1") // Add space before each uppercase letter
    .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
    .trim(); // Trim any leading spaces
};

export const handleChatCount = async (room: IChatRoom, queryClient: any) => {
  if (!room) return;

  const chatList = await queryClient.getQueryData([
    QueryKeys.allChatRoomPagination,
  ]);
  if (chatList?.pages) {
    let newData: any[] = [];
    const latestList = chatList?.pages?.map((page: any) => {
      const newChatList = page?.data?.map((chat: any) => {
        let newChat: any = chat;
        if (chat.id === room.id) {
          newChat = {
            ...chat,
            unReadMessagesCount: 0,
            isMentioned: false,
          };
        }
        return newChat;
      });
      if (newChatList?.length > 0) newData = [...newData, newChatList];
      return { ...page, data: newChatList };
    });
    queryClient.setQueryData([QueryKeys.allChatRoomPagination], {
      ...chatList,
      pages: latestList,
    });
  }

  const updateUnReadData = (oldQueryData: any) => {
    const count = room.unReadMessagesCount;
    const oldCount = oldQueryData?.unreadCount;
    const updatedCount = oldCount - count;
    if (oldCount && room.unReadMessagesCount) {
      return {
        ...oldQueryData,
        unreadCount: updatedCount < 0 ? 0 : updatedCount,
      };
    }
    return oldQueryData;
  };
  queryClient.setQueryData([QueryKeys.unreadCount], updateUnReadData);
};

export const UpdateChatRoomListCache = async (list: any, queryClient: any) => {
  await queryClient.setQueryData([QueryKeys.allChatRoomPagination], list);
};
export const UpdateChatCache = async (message: any, queryClient: any) => {
  const oldChatData: any = queryClient.getQueryData([
    QueryKeys.allChats,
    message.roomId,
  ]);
  const limitedData = oldChatData?.pages?.[0]?.data || []; // ?.slice(0, CHAT_PAGE_LIMIT);
  let newChatList = [message, ...(limitedData || [])];
  const oldPage = oldChatData?.pages?.[0];
  if (newChatList.length > 100) {
    newChatList = newChatList.slice(0, 100);
  }
  const newPageList = { ...oldPage, data: newChatList };

  const newChatListCache = {
    ...oldChatData,
    pageParams: [undefined],
    pages: [newPageList],
  };
  queryClient.setQueryData(
    [QueryKeys.allChats, message.roomId],
    newChatListCache,
  );
};

export const UpdateChatUnreadCache = async (
  chatId: number,
  roomId: number,
  queryClient: any,
) => {
  const oldChatData: any = queryClient.getQueryData([
    QueryKeys.allChats,
    roomId,
  ]);
  const limitedData = oldChatData?.pages?.[0]?.data || [];
  const oldPage = oldChatData?.pages?.[0];
  const newChats = limitedData?.map((chat: any) => {
    if (chat.id === chatId) {
      const newChat = { ...chat, readLater: !chat.readLater };
      return newChat;
    }
    return chat;
  });

  const newPageList = { ...oldPage, data: newChats };

  const newChatListCache = {
    ...oldChatData,
    pageParams: [undefined],
    pages: [newPageList],
  };
  queryClient.setQueryData([QueryKeys.allChats, roomId], newChatListCache);
};

export const UpdateChatInCache = async (message: any, queryClient: any) => {
  await queryClient.setQueryData(
    [QueryKeys.allChats, message.roomId],
    (oldQueryData: any) => {
      const limitedData = oldQueryData?.pages?.[0]?.data || [];

      const oldPage = oldQueryData?.pages?.[0];
      const latestUpdate = limitedData?.map((msg: any) => {
        if (msg.uuId === message.uuId) {
          return message;
        }
        return msg;
      });

      const newPageList = { ...oldPage, data: latestUpdate };

      return {
        pageParams: [undefined],
        pages: [newPageList],
      };
    },
  );
};

export const updateChatRoomOnReadLater = (
  chatRooms: any[],
  setChatRooms: any,
  roomId: number,
  value: any,
  setSelectedChatRoom: any,
) => {
  if (chatRooms?.length) {
    let selectedChat;
    const newData = chatRooms?.map((chat: any) => {
      let newChat = chat;

      if (chat.id === roomId) {
        newChat = {
          ...chat,
          readLater: value.readLater,
          readLaterCount: value.readLaterCount,
        };
        selectedChat = newChat;
      }

      return newChat;
    });

    setSelectedChatRoom?.({ selectedChatRoom: selectedChat });

    setChatRooms?.(newData);
  }
};

export const updateChatRoomPinUnPined = (
  chatRooms: any[],
  setChatRooms: any,
  roomId: number,
  value: any,
) => {
  if (chatRooms?.length) {
    const newData = chatRooms?.map((chat: any) => {
      let newChat = chat;

      if (chat.id === roomId) {
        newChat = {
          ...chat,
          isPinned: value,
        };
      }

      return newChat;
    });
    const sortedData = newData?.sort((a: any, b: any) => {
      if (a.isPinned !== b.isPinned) {
        return b.isPinned - a.isPinned;
      }
      return b.lastMessageOn - a.lastMessageOn;
    });
    setChatRooms?.(sortedData);
  }
};
export const updateChatRoomDeleteChatRoom = (
  chatRooms: any[],
  setChatRooms: any,
  roomId: number,
) => {
  if (chatRooms?.length) {
    const newData = chatRooms?.filter((x) => x.id !== roomId);
    const sortedData = newData?.sort((a: any, b: any) => {
      if (a.isPinned !== b.isPinned) {
        return b.isPinned - a.isPinned;
      }
      return b.lastMessageOn - a.lastMessageOn;
    });
    setChatRooms?.(sortedData);
  }
};
export const getFileExtension = (fileType: string) => {
  return fileType?.split(".")?.pop();
};

export function messageFormatDateTime(datetime: any) {
  const inputDate = new Date(Number(datetime));
  const currentDate = new Date();
  const yesterdayDate = new Date(currentDate);
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);

  if (inputDate.toDateString() === currentDate.toDateString()) {
    // Today
    return `${moment(inputDate).format("hh:mm A")}`;
  }
  if (inputDate.toDateString() === yesterdayDate.toDateString()) {
    // Yesterday
    return "Yesterday";
  }
  // Other dates
  return moment(inputDate).format("MM/DD/YYYY");
}
export const handleSMSColors = (isReplyText: boolean, smsType: ISMSSend) => {
  if (isReplyText) {
    return palette.info.lightBg;
  }
  if (smsType === "Draft") {
    return "#c3c0c0";
  }
  if (smsType === "Schedule") {
    return "#df607c";
  }
  return palette.info.light;
};

export function convertToBlob(file: File): Blob {
  const workbook = XLSX.read(file, { type: "array" });
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  const binaryWorkbook = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "binary",
  });
  const binaryArray = new Uint8Array(binaryWorkbook.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryWorkbook.length; i++) {
    binaryArray[i] = binaryWorkbook.charCodeAt(i);
  }

  const blob = new Blob([binaryArray], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  return blob;
}

export function combinSMSNumbers(
  AdditionalContact: any,
  primaryContact: any,
  setSmsList: any,
) {
  const storeAdditionalContact = [];

  if (primaryContact?.mobile) {
    storeAdditionalContact.push({
      id: primaryContact?.mobile,
      value: primaryContact?.mobile,
    });
  }
  if (primaryContact?.phone) {
    storeAdditionalContact.push({
      id: primaryContact?.phone,
      value: primaryContact?.phone,
    });
  }

  AdditionalContact?.map((item: any) => {
    if (item?.officeNumber) {
      storeAdditionalContact.push({
        id: item?.officeNumber,
        value: item?.officeNumber,
      });
    }
    if (item?.phoneNumber) {
      storeAdditionalContact.push({
        id: item?.phoneNumber,
        value: item?.phoneNumber,
      });
    }
    return null;
  }) || [];
  setSmsList(storeAdditionalContact);
  return storeAdditionalContact;
}

export function maskApiLoginID(apiLoginID: any) {
  return apiLoginID ? `${apiLoginID.slice(0, 4)}xxxxxx` : "";
}

export function maskTransactionID(transactionID: any) {
  return transactionID
    ? `${transactionID.slice(0, 4)}xxxxxxx${transactionID.slice(-4)}`
    : "";
}

export function combineEmails(
  AdditionalEmail: any,
  primaryEmail: any,
  setEmailList: any,
) {
  const storeAdditionalContact = [];

  if (primaryEmail?.secondEmail) {
    storeAdditionalContact.push({
      id: primaryEmail?.secondEmail,
      value: primaryEmail?.secondEmail,
    });
  }
  if (primaryEmail?.email) {
    storeAdditionalContact.push({
      id: primaryEmail?.email,
      value: primaryEmail?.email,
    });
  }

  AdditionalEmail?.map((item: any) => {
    if (item?.email) {
      storeAdditionalContact.push({
        id: item?.email,
        value: item?.email,
      });
    }
    return null;
  }) || [];
  setEmailList(storeAdditionalContact);
  return storeAdditionalContact;
}

export function generate4DigitUniqueID() {
  return Math.floor(1000 + Math.random() * 9000).toString();
}

// export function addChatRoomIdToUrl(id: any) {
//   window.history.pushState(
//     {},
//     "",
//     `${window.location.origin}${window.location.pathname}?chatroomid=${id}`,
//   );
// }

// for add params in the url
export function addChatRoomIdToUrl(params: any) {
  const baseUrl = `${window.location.origin}${window.location.pathname}`;

  const url = new URL(baseUrl);
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key]),
  );
  window.history.pushState({}, "", url.toString());
  return url.toString();
}

export function getIdFromUrl(chatroomid: string) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(chatroomid); // This returns the id value
}

export function stripComments(html: any) {
  return html.replace(/<!--[\s\S]*?-->/g, "");
}

export const extractEmail: any = (str: string[]) => {
  return str.map((i: string) => {
    const match = i.match(/<(.+?)>/);
    return match ? match[1] : i.trim();
  });
};

export function convertArrayToObject(jsonArray: any[]) {
  return jsonArray.reduce((obj, item) => {
    obj[item.roomId] = item.count;
    return obj;
  }, {});
}

export function sumChatCount(dataArray: any[]) {
  return dataArray.reduce((total, item) => total + item.count, 0);
}

export function sumObjectValues(obj: Record<string, number>): number {
  let sum = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      sum += obj[key];
    }
  }
  return sum;
}
