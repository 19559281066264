import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import client from "utils/ApiClient";

import { IVirtualNumberResponse } from "pages/call-manager/query/useFetchVirtualNumbers";
import API_URLS from "./endPoints";

export async function saveCallerId(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_CALLER_ID, body);
}

export async function removeCallerId(numberId: any): Promise<any> {
  return client.delete(`${API_URLS.DELETE_CALLER_ID}/${numberId}`);
}

export async function disableEnable(numberId: any): Promise<any> {
  return client.put(`${API_URLS.DISABLE_ENABLE_CALLER_ID}/${numberId}`);
}

export async function fetchCallerIds(
  pageNo: number,
  type: string,
  pageLimit = 10,
  search = "",
): Promise<any> {
  let url = `${API_URLS.CALLER_IDS}?page=${pageNo + 1}&pageSize=${
    pageLimit || PER_PAGE
  }&type=${type}`;

  if (search) {
    url = `${url}&search=${search}`;
  }
  return client.get(url);
}

export async function fetchVirtualNumbers(
  clientId: number,
  userId: number,
): Promise<IVirtualNumberResponse> {
  const url = `${API_URLS.GETALL_VIRTUAL_NUMBER_AS_CALLER_ID}?userId=${userId}&clientId=${clientId || 0}`;

  return client.get(url);
}
