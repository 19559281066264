/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ICamRequest } from "pages/admin/call-campaign/hook/useCamForm";
import { useAppDispatch } from "redux/store";

const initialState: ICamRequest = {
  contact: "",
  key: 1,
  name: "",
  appointmentSetterIdList: [],
  description: "",
  saleStatuses: [],
  dialableStatuses: [],
  saleStatusesList: [],
  dialableStatusesList: [],
  callerID: null,
  file: null,
  status: 1,
  rank: 0,
  stateTimeRestriction: 0,
  campaignType: 1,
  agentSelectionMethod: 1,
  pauseAfterWrapup: false,
  manualAutoNextTimeout: 0,
  systemDNC: false,
  externalDNCProfile: 123,
  dialableLeadsAlertThreshold: 1,
  dropRateAlertThresholdPercent: 1,
  listOrder: 1,
  forceCallNewLeads: false,
  minimumDialQueueSize: 1,
  defaultTransferCallerId: 1,
  appointmentSetterId: [],
  MaxCallPerDay: 0,
  IsSms: false,
  IsEmail: false,
};
interface IPayload {
  type: any;
  value: any;
}
interface IAction {
  payload: IPayload;
  type: any;
}

const teamSlice = createSlice({
  name: "callCampaign",
  initialState,
  reducers: {
    setValue: (state, action: IAction) => {
      switch (action.payload.type) {
        case "key":
          state.key = action.payload.value;
          break;
        case "name":
          state.name = action.payload.value;
          break;
        case "description":
          state.description = action.payload.value;
          break;
        case "saleStatuses":
          state.saleStatuses = action.payload.value;
          break;
        case "callerID":
          state.callerID = action.payload.value;
          break;
        case "file":
          state.file = action.payload.value;
          break;
        case "status":
          state.status = action.payload.value;
          break;
        case "rank":
          state.rank = action.payload.value;
          break;
        case "stateTimeRestriction":
          state.stateTimeRestriction = action.payload.value;
          break;
        case "campaignType":
          state.campaignType = action.payload.value;
          break;
        case "agentSelectionMethod":
          state.status = action.payload.value;
          break;
        case "pauseAfterWrapup":
          state.pauseAfterWrapup = action.payload.value;
          break;
        case "manualAutoNextTimeout":
          state.manualAutoNextTimeout = action.payload.value;
          break;
        case "systemDNC":
          state.systemDNC = action.payload.value;
          break;
        case "externalDNCProfile":
          state.externalDNCProfile = action.payload.value;
          break;

        case "dialableStatuses":
          state.dialableStatuses = action.payload.value;
          break;
        case "dialableLeadsAlertThreshold":
          state.dialableLeadsAlertThreshold = action.payload.value;
          break;
        case "dropRateAlertThresholdPercent":
          state.dropRateAlertThresholdPercent = action.payload.value;
          break;
        case "listOrder":
          state.listOrder = action.payload.value;
          break;
        case "forceCallNewLeads":
          state.forceCallNewLeads = action.payload.value;
          break;
        case "minimumDialQueueSize":
          state.minimumDialQueueSize = action.payload.value;
          break;

        case "defaultTransferCallerId":
          state.defaultTransferCallerId = action.payload.value;
          break;
        case "appointmentSetterId":
          state.appointmentSetterId = action.payload.value;
          break;

        case "MaxCallPerDay":
          state.MaxCallPerDay = action.payload.value;
          break;
        case "IsSms":
          state.IsSms = action.payload.value;
          break;
        case "IsEmail":
          state.IsEmail = action.payload.value;
          break;
        case "contact":
          state.contact = action.payload.value;
          break;

        default:
          null;
      }
    },
    removeValue: (state) => {
      state.key = 1;
      state.name = "";
      state.description = "";
      state.saleStatuses = [];
      state.callerID = null;
      state.file = null;
      state.status = 1;
      state.rank = 0;
      state.stateTimeRestriction = 0;
      state.campaignType = 1;
      state.agentSelectionMethod = 1;
      state.pauseAfterWrapup = false;
      state.manualAutoNextTimeout = 0;
      state.systemDNC = false;
      state.externalDNCProfile = 123;
      state.dialableStatuses = [1, 2, 34];
      state.dialableLeadsAlertThreshold = 1;
      state.dropRateAlertThresholdPercent = 1;
      state.listOrder = 1;
      state.forceCallNewLeads = false;
      state.minimumDialQueueSize = 1;
      state.defaultTransferCallerId = 1;
      state.appointmentSetterId = [];
      state.MaxCallPerDay = 0;
      state.IsSms = false;
      state.IsEmail = false;
    },
  },
});

export const { setValue, removeValue } = teamSlice.actions;

export const useCallCampaign = () => {
  const dispatch = useAppDispatch();
  return {
    setValue: (type: string, value: any) => dispatch(setValue({ type, value })),
    removeValue: () => dispatch(removeValue()),
  };
};
const { reducer } = teamSlice;

export default reducer;
