import { makeStyles } from "@material-ui/core/styles";
import palette from "theme/palette";

export const useMessageItemStyles = makeStyles({
  receivedContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    justifyContent: "start",
    gap: 12,
    marginBottom: "0.5rem",
  },
  receivedChatHead: {
    // backgroundColor: palette.info.lightBg,
    padding: "8px",
    paddingRight: "42px",
    borderRadius: "8px 8px 8px 0px  ",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "-7px",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottomWidth: "10px",
      borderBottomStyle: "solid",
    },
  },
  sendContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    gap: 12,
    marginBottom: "0.5rem",
  },
  sendChatHead: {
    paddingRight: "42px",
    // backgroundColor: palette.info.light,
    color: palette.common.white,
    padding: "8px ",
    borderRadius: "8px 8px 0px 8px ",
    position: "relative",
    // left: "40px",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: 0,
      right: "-7px",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottomWidth: "10px",
      borderBottomStyle: "solid",
    },
  },
  bottomText: { paddingTop: "0.3rem", color: palette.text.muted },
  scrollBox: {
    backgroundColor: "transparent",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },

  quoteReceivedContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "row-reverse",
    justifyContent: "start",
    gap: 12,
    marginBottom: "0.5rem",
  },

  quoteMsg: {
    borderLeft: "2px solid #c2c4c6",
    padding: "8px",
    borderRadius: "0",
    marginBottom: "8px",
    width: "100%",
  },
  sendQuoteMsg: {
    backgroundColor: "#f1f1f130",

    // marginRight: "4rem",
    color: "#fff",
  },
  receivedQuoteMsg: {
    backgroundColor: "#c2c4c629",

    // marginLeft: "3rem",
    color: "#333",
  },
  typeIcons: {
    fontSize: "14px",
    color: "#546376",
  },
});
