import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function ChatInboxLoader(props: Props) {
  return (
    <Box {...props}>
      <Box
        sx={{
          paddingX: 5,
          marginTop: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={{ width: "70%" }}>
          <Skeleton
            animation="wave"
            height={70}
            sx={{ marginBottom: 1, borderRadius: 0.3 }}
            variant="rectangular"
            width="100%"
          />
          <Stack
            sx={{
              alignItems: "end",
            }}
          >
            <Skeleton
              animation="wave"
              sx={{ marginBottom: 1, borderRadius: 0.3 }}
              variant="rectangular"
              width="50%"
            />
            <Skeleton animation="wave" variant="rectangular" width="50%" />
          </Stack>
        </Box>
        <Stack sx={{ marginLeft: 1, justifyContent: "flex-end" }}>
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40}
          />
        </Stack>
      </Box>

      <Box sx={{ width: "70%", marginTop: 4, paddingX: 5, display: "flex" }}>
        <Stack sx={{ marginRight: 1, justifyContent: "flex-end" }}>
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40}
          />
        </Stack>
        <Box sx={{ width: "90%" }}>
          <Skeleton
            animation="wave"
            height={70}
            sx={{ marginBottom: 1, borderRadius: 0.3 }}
            variant="rectangular"
            width="100%"
          />
          <Stack>
            <Skeleton
              animation="wave"
              sx={{ marginBottom: 1, borderRadius: 0.3 }}
              variant="rectangular"
              width="50%"
            />
            <Skeleton animation="wave" variant="rectangular" width="50%" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export { ChatInboxLoader };
