/* eslint-disable react/no-array-index-key */
/* eslint-disable no-console */
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

interface IAudioTranscription {
  chatWindowRef: any;
  handleScroll: any;
  message: any;
}
function TranscriptionSection(props: IAudioTranscription) {
  const { chatWindowRef, handleScroll, message } = props;
  return (
    <Box
      sx={{
        boxShadow: 10,
        borderRadius: 2,
        margin: 3,
        height: "50vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        ref={chatWindowRef}
        sx={{
          overflowY: "scroll",
          padding: "16px",
          scrollBehavior: "smooth",
          backgroundColor: "transparent",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
        onScroll={handleScroll}
      >
        {message?.map((i: any, index: number) => (
          <Box key={index}>
            <ListItem disableGutters>
              <ListItemButton onClick={() => console.log(i)}>
                <ListItemAvatar>
                  <Avatar
                    sizes="10"
                    sx={{ bgcolor: blue[100], color: blue[600] }}
                  >
                    <PersonIcon fontSize="small" />
                  </Avatar>
                </ListItemAvatar>
                <Typography sx={{ marginLeft: -1 }} variant="h6">
                  {i.type}
                </Typography>
              </ListItemButton>
            </ListItem>
            <Typography sx={{ marginTop: -1, marginLeft: 2 }}>
              {i.data}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default TranscriptionSection;
