/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface Permission {
  id: number;
  permissions: string;
  permissionDescription: string;
  permissionCode: string;
  status: number;
  screenId: number;
  screenName?: any;
}

export interface IPermissions {
  id: number;
  screenName: string;
  screenCode: string;
  status: number;
  screenUrl: string;

  permissions: Permission[];
}

export interface ICommonReducer {
  permissions: IPermissions[] | null;
  companyDetails: any;
}

const initialState: ICommonReducer = {
  permissions: [] as IPermissions[],
  companyDetails: null,
};

interface IPayload {
  permissions?: IPermissions[];
  companyDetails?: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const commonSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action: IAction) => {
      if (state.permissions) {
        const latestState: any = state;
        latestState.permissions = action.payload.permissions;
        state = latestState;
      }
    },
    setCompanyDetails: (state, action: IAction) => {
      if (state.permissions) {
        const latestState: any = state;
        latestState.companyDetails = action.payload.companyDetails;
        state = latestState;
      }
    },
  },
});

export const { setPermissions, setCompanyDetails } = commonSlice.actions;

export const useCommonActions = () => {
  const dispatch = useAppDispatch();
  return {
    setPermissions: (payload: IPayload) => dispatch(setPermissions(payload)),
    setCompanyDetails: (payload: IPayload) =>
      dispatch(setCompanyDetails(payload)),
  };
};
const { reducer } = commonSlice;

export default reducer;
