import CloseIcon from "@mui/icons-material/Close";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { Box, IconButton, Stack, Typography } from "@mui/material";

interface IAssignColor {
  handleSelectColour: () => void;
  formik: any;
}

function AssignColor(props: IAssignColor) {
  const { handleSelectColour, formik } = props;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="flex-start"
      style={{}}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          handleSelectColour();
        }}
      >
        <IconButton>
          <ColorizeIcon
            sx={{
              fontSize: "1rem",
              color: "black",
            }}
          />
        </IconButton>
        <Typography
          sx={{
            fontSize: "1rem",
            color: "black",
          }}
        >
          {formik?.values?.colour ? "Change" : "Choose"} Color
        </Typography>
      </Box>
      {formik?.values?.colour && (
        <Stack alignItems="center" direction="row" justifyContent="center">
          <Stack
            style={{
              width: "24px",
              height: "20px",
              borderRadius: "2px",
              marginLeft: "8px",
              backgroundColor: formik?.values?.colour || "",
            }}
          />
          <IconButton
            onClick={() => {
              formik?.setFieldValue("colour", "");
            }}
          >
            <CloseIcon
              sx={{
                fontSize: "1rem",
                color: "black",
                marginLeft: "8px",
              }}
            />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
}

export default AssignColor;
