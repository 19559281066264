import { createSlice } from "@reduxjs/toolkit";

export interface IProps {
  selectedItem: any;
}

const initialState: IProps = {
  selectedItem: null,
};

const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      const newState = state;
      newState.selectedItem = action.payload;
    },
  },
});

const { reducer, actions } = emailSlice;

export const { setEmail } = actions;
export default reducer;
