import { IResponse } from "constants/interfaces";
import { IEditUserForm } from "pages/user/contacts/details/query/useApiActions";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function fetchUserFormByContactId(contactId: number): Promise<IResponse> {
  const url = `${API_URLS.GET_ALL_USER_FORM_BY_CONTACT_ID}/${contactId}`;
  return client.get(url);
}

async function addUserForm(body: any): Promise<IResponse> {
  return client.post(API_URLS.ADD_USER_FORM, body);
}
async function editUserForm(body: IEditUserForm): Promise<IResponse> {
  return client.post(API_URLS.EDIT_USER_FORM, body);
}

async function fetchUserFormByUrl(formUrl: number): Promise<IResponse> {
  const url = `${API_URLS.GET_USER_FORM_BY_URL}?url=${formUrl}`;
  return client.get(url);
}

async function SubmitUserForm(body: any): Promise<IResponse> {
  return client.post(API_URLS.SUBMIT_USER_FORM, body);
}

async function deleteUserFormById(id: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_USER_FORM}/${id}`;
  return client.delete(url);
}

export {
  addUserForm,
  deleteUserFormById,
  editUserForm,
  fetchUserFormByContactId,
  fetchUserFormByUrl,
  SubmitUserForm
};

