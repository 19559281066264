/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IColumn } from "pages/user/contacts/components/types/tableToolbar";
import { RootState, useAppDispatch } from "redux/store";

type IDispositionIds = number[];

interface IDispositionDeleteIds {
  id: number;
}
interface IPaginationList {
  activityId?: number;
  dispositionId: number;
  index: number;
}

interface IMassEmailSMS {
  name?: number;
  email: number;
  id: number;
}

export interface IDispositionsReducer {
  columnIds?: IColumn[];
  dispositionIds?: IDispositionIds;

  deleteIds?: IDispositionDeleteIds[];
  paginationList?: IPaginationList[];
  selectedDisposition?: IPaginationList;

  selectedDispositions?: IMassEmailSMS[];
  type?: "normal" | "campaign";
}

const initialState: IDispositionsReducer = {
  columnIds: [],
  dispositionIds: [],
  deleteIds: [],
  paginationList: [],
  selectedDisposition: {} as IPaginationList,
  selectedDispositions: [],
  type: "normal",
};

interface IPayload {
  columnIds?: IColumn[];
  deleteIds?: IDispositionDeleteIds[];
  paginationList?: IPaginationList[];
  selectedDisposition?: IPaginationList;
  selectedDispositions?: IMassEmailSMS | null;
}

interface IAction {
  payload: IPayload;
  type: string;
}

const dispositionSlice = createSlice({
  name: "disposition",
  initialState,
  reducers: {
    setColumnIds: (state, action: IAction) => {
      state.columnIds = action.payload.columnIds;
    },

    setDispositionIds: (
      state,
      action: PayloadAction<{ dispositionId: number }>,
    ) => {
      const { dispositionId } = action.payload;
      const findDisposition = state.dispositionIds?.find(
        (i) => i === dispositionId,
      );
      if (findDisposition) {
        state.dispositionIds =
          state.dispositionIds?.filter((i) => i !== dispositionId) || [];
      } else {
        state.dispositionIds?.push(dispositionId);
      }
    },
    setAllDispositionIds: (
      state,
      action: PayloadAction<{ checked: boolean; dispositionIds: number[] }>,
    ) => {
      const { checked, dispositionIds } = action.payload;
      if (checked) {
        state.dispositionIds = dispositionIds;
      } else {
        state.dispositionIds = [];
      }
      // const { dispositionId } = action.payload;
      // const findDisposition = state.dispositionIds?.find((i) => i === dispositionId);
      // if (findDisposition) {
      //   state.dispositionIds =
      //     state.dispositionIds?.filter((i) => i !== dispositionId) || [];
      // } else {
      //   state.dispositionIds?.push(dispositionId);
      // }
    },

    setDeleteIds: (state, action: IAction) => {
      const { deleteIds } = action.payload;
      state.deleteIds = deleteIds;
    },
    setPaginationList: (state, action: IAction) => {
      const { paginationList } = action.payload;
      state.paginationList = paginationList;
    },
    setSelectedDisposition: (state, action: IAction) => {
      const { selectedDisposition } = action.payload;
      state.selectedDisposition = selectedDisposition;
    },

    resetDispositionIds: (state) => {
      state.dispositionIds = [];
    },

    // selectedIds
  },
});

const dispositionState = (state: RootState) => state.disposition;

export const selectDispositionIds = createSelector(
  [dispositionState],
  (state) => state.dispositionIds || [],
);

export const {
  setColumnIds,
  setDispositionIds,
  setDeleteIds,
  setPaginationList,
  setSelectedDisposition,
  resetDispositionIds,
  setAllDispositionIds,
} = dispositionSlice.actions;

export const useDispositionActions = () => {
  const dispatch = useAppDispatch();
  return {
    setColumnIds: (payload: IPayload) => dispatch(setColumnIds(payload)),

    setDeleteIds: (payload: IPayload) => dispatch(setDeleteIds(payload)),
    setPaginationList: (payload: IPayload) =>
      dispatch(setPaginationList(payload)),
    setSelectedDisposition: (payload: IPayload) =>
      dispatch(setSelectedDisposition(payload)),
    resetDispositionIds: () => dispatch(resetDispositionIds()),
  };
};
const { reducer } = dispositionSlice;

export default reducer;
