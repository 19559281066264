/**
 * @format
 */
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

function useCallManager(): any {
  const userInfo: any = useSelector((state: RootState) => state.callManager);
  // merged
  return userInfo;
}

export default useCallManager;
