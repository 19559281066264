import { IResponse } from "constants/interfaces";
import { ITemplatesRequest } from "pages/admin/settings/screens/templates/query/useApiAction";
import { IResponseTemplates } from "pages/admin/settings/screens/templates/query/useFetchTemplates";
import { IResponseTemplatesByUsers } from "pages/admin/settings/screens/templates/query/useFetchTemplatesByUser";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export function getAllTemplates(): Promise<IResponseTemplates> {
  return client.get(API_URLS.GET_TEMPLATES_WITHOUT_PAGINATION);
}

export function getVideoTemplates(): Promise<IResponseTemplates> {
  return client.get(`${API_URLS.GET_TEMPLATES_VIDEO}/video`);
}
export function getVoiceTemplates(): Promise<IResponseTemplates> {
  return client.get(`${API_URLS.GET_TEMPLATES_VIDEO}/voice`);
}

export function fetchTemplate(id: string): Promise<IResponse> {
  return client.get(`${API_URLS.GET_TEMPLATES}?listid=${id}`);
}

export function fetchTemplatesById(id: number): Promise<IResponse> {
  return client.get(`${API_URLS.DELETE_TEMPLATE_BY_ID}/${id}`);
}

export function saveTemplate(
  body: ITemplatesRequest,
  type?: string,
): Promise<IResponse> {
  if (type === "Email") {
    return client.post(API_URLS.ADD_TEMPLATES, body);
  }
  return body?.id
    ? client.put(API_URLS.EDIT_TEMPLATES, body)
    : client.post(API_URLS.ADD_TEMPLATES, body);
}

export function saveVideoTemplate(body: ITemplatesRequest): Promise<IResponse> {
  return body?.id
    ? client.put(API_URLS.UPDATE_VIDEO_VOICE_TEMPLATE, body)
    : client.post(API_URLS.ADD_VIDEO_VOICE_TEMPLATE, body);
}

export function deleteTemplate(id: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_TEMPLATES}/${id}`);
}
export function deleteTemplateId(id: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_TEMPLATE_SINGLE_ID}/${id}`);
}

export function deleteContactFormId(id: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_CONTACT_FORM_SINGLE_ID}/${id}`);
}

export function getAllUserSpecificTemplates(): Promise<IResponseTemplatesByUsers> {
  return client.get(API_URLS.GET_USER_SPECIFIC_TEMPLATES);
}
