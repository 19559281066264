import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import AudioPlayer from "components/audio-player";
import VideoPlayer from "components/video-player";
import { Swiper, SwiperSlide } from "swiper/react";
import { downloadFileByApi, fileType, timeStampToTime } from "utils";

import { ATTACHMENT_URL } from "config";
import { IChatFile } from "pages/user/inbox/query/useFetchGetFile";
import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

interface IProps {
  openFileUrl: any;
  handleClose: () => void;
  open: boolean;
  imageUrls: IChatFile[];
  chat: any | null;
  isLoading?: boolean;
}
function MediaViewPopup(props: IProps) {
  const { openFileUrl, open, handleClose, imageUrls, chat, isLoading } = props;

  const initialSlideIndex = imageUrls.findIndex(
    (image: any) => image.chatId === chat?.id,
  );

  return (
    <Dialog open={open && openFileUrl} onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Typography sx={{ fontSize: "16px" }} variant="h5">
          Media Preview
        </Typography>
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box>
            <CircularProgress size={26} />
          </Box>
        ) : chat?.quoteMessage ? (
          <img
            alt="chat"
            src={openFileUrl}
            style={{
              width: "600px",
              height: "600px",
              objectFit: "contain",
            }}
          />
        ) : (
          <Box>
            {fileType(openFileUrl) === "image" ? (
              <Swiper
                navigation
                className="mySwiper"
                initialSlide={initialSlideIndex}
                modules={[Navigation]}
                style={{
                  textAlign: "center",
                }}
              >
                {imageUrls.map((image, index) => {
                  const num = index;
                  return (
                    <SwiperSlide key={num}>
                      <Stack alignItems="flex-start">
                        <Typography
                          sx={{
                            justifyContent: "flex-start",
                            fontSize: "14px",
                          }}
                        >
                          {timeStampToTime({
                            utcDate: Number(image?.timeStamp),
                          })}
                        </Typography>
                      </Stack>
                      {image.fileUrl ? (
                        <img
                          alt={`chat ${initialSlideIndex}`}
                          src={`${ATTACHMENT_URL}${image.fileUrl}`}
                          style={{
                            width: "600px",
                            maxHeight: "600px",
                            objectFit: "contain",
                          }}
                        />
                      ) : null}
                      <Typography sx={{ fontSize: "16px" }}>
                        {image.message}
                      </Typography>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : fileType(openFileUrl) === "video" ? (
              <Box sx={{ textAlign: "center" }}>
                <Stack alignItems="flex-start">
                  <Typography sx={{ fontSize: "14px" }}>
                    {timeStampToTime({
                      utcDate: Number(chat?.timeStamp),
                    })}
                  </Typography>
                </Stack>
                <VideoPlayer url={openFileUrl} />
                <Typography>{chat?.message}</Typography>
              </Box>
            ) : fileType(openFileUrl) === "audio" ? (
              <Box sx={{ textAlign: "center" }}>
                <Stack alignItems="flex-start">
                  <Typography sx={{ fontSize: "14px" }}>
                    {timeStampToTime({
                      utcDate: Number(chat?.timeStamp),
                    })}
                  </Typography>
                </Stack>
                <AudioPlayer url={openFileUrl} />
                <Typography>{chat?.message}</Typography>
              </Box>
            ) : fileType(openFileUrl) === "pdf" ? (
              <Box sx={{ textAlign: "center" }}>
                <Stack alignItems="flex-start">
                  <Typography sx={{ fontSize: "14px" }}>
                    {timeStampToTime({
                      utcDate: Number(chat?.timeStamp),
                    })}
                  </Typography>
                </Stack>
                <iframe
                  allowFullScreen
                  height="800px"
                  src={openFileUrl}
                  title="PDF Viewer"
                  width="700px"
                />
                <Typography>{chat?.message}</Typography>
              </Box>
            ) : (
              <Typography color="error" variant="h5">
                File type not supported
              </Typography>
            )}
          </Box>
        )}
        <Stack alignItems="flex-end">
          <Tooltip title="Download file">
            <IconButton
              onClick={(e) => downloadFileByApi(openFileUrl, "chat-file")}
            >
              <DownloadForOfflineIcon sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(MediaViewPopup);
