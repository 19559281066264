import { createSlice } from "@reduxjs/toolkit";

export interface IProps {
  selectPlatformName: any;
}

const initialState: IProps = {
  selectPlatformName: null,
};

const platformNameSlice = createSlice({
  name: "platformName",
  initialState,
  reducers: {
    setPlatformName: (state, action) => {
      const newState = state;
      newState.selectPlatformName = action.payload;
    },
  },
});

const { reducer, actions } = platformNameSlice;

export const { setPlatformName } = actions;
export default reducer;
