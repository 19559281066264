import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function AllConversationLoader(props: Props) {
  return (
    <Box {...props}>
      <Box sx={{ paddingX: 5, display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <Skeleton
            animation="wave"
            height={70}
            sx={{ marginBottom: 1 }}
            variant="rectangular"
            width="100%"
          />
          <Stack
            sx={{
              alignItems: "end",
            }}
          >
            <Skeleton
              animation="wave"
              sx={{ marginBottom: 1 }}
              variant="rectangular"
              width="50%"
            />
            <Skeleton animation="wave" variant="rectangular" width="50%" />
          </Stack>
        </Box>
        <Box sx={{ marginLeft: 1 }}>
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40}
          />
        </Box>
      </Box>

      <Box sx={{ marginTop: 6, paddingX: 5, display: "flex" }}>
        <Box sx={{ marginRight: 1 }}>
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Skeleton
            animation="wave"
            height={70}
            sx={{ marginBottom: 1 }}
            variant="rectangular"
            width="100%"
          />
          <Stack>
            <Skeleton
              animation="wave"
              sx={{ marginBottom: 1 }}
              variant="rectangular"
              width="50%"
            />
            <Skeleton animation="wave" variant="rectangular" width="50%" />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export { AllConversationLoader };
