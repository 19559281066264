import AttachmentIcon from "@mui/icons-material/Attachment";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ContactSearch from "components/contact-search";
import Slider from "components/layouts/popup-modals/Slider";
import TextField from "components/textfield";
import { ATTACHMENT_URL } from "config";
import useDecodedData from "hooks/useDecodedData";
import AppRoutes from "navigation/appRoutes";
import { ISearchContacts } from "pages/user/contacts/query/useFetchContactBySearch";
import { UserRoleByRoleIdData } from "pages/user/contacts/query/useFetchUserRoleByRoleName";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useChatsActions } from "redux/chats/chats";
import { RootState } from "redux/store";
import { SocketContext } from "SocketContex";
import { getUuid, handleAssignContactGroup } from "utils";
import useCreateGroupForm from "../../hook/useCreateGroupForm";
import { ICreateGroupRequest, useApiActions } from "../../query/useApiAction";
import { useFetchMembers } from "../../query/useFetchMembers";
import FriendsList from "./FriendsList";

interface IProps {
  openGroup: boolean;
  setShowChatDetail?: any;
  handleGroupClose: () => void;
  selectedGroup: any;
  userData: UserRoleByRoleIdData[] | undefined;
  // setSelectedRoom: any;
  // selectedRoom: any;
}
const initialValues: ICreateGroupRequest = {
  id: 0,
  userId: 0,
  name: "",
  members: [],
  file: "",
};

function CreateGroupPop(props: IProps) {
  const {
    openGroup,
    userData,
    selectedGroup,
    handleGroupClose,
    // selectedRoom,
    // setSelectedRoom,
    setShowChatDetail,
  } = props;

  const getChats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoom } = getChats;
  const { chatRooms, setChatRooms } = useContext(SocketContext);

  const { setSelectedChatRoom } = useChatsActions();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [friends, setFriends] = useState<number[]>([]);
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(null);

  const [selectedContact, setSelectedContact] =
    useState<ISearchContacts | null>(null);
  const queryClient = useQueryClient();

  const { tryCreateGroup } = useApiActions();
  const {
    data: members,
    isLoading,
    refetch,
  } = useFetchMembers(selectedGroup?.id || 0);
  const decoded = useDecodedData();
  const navigate = useNavigate();
  const isSmall = useMediaQuery("(max-width:767px)");
  function handleAddFriend(friend: any) {
    const tempIds = JSON.parse(JSON.stringify(friends));
    const isExist = tempIds.find((x: any) => x === friend.id);
    if (isExist) {
      const filtered = tempIds.filter((x: any) => x !== friend.id);
      setFieldValue("members", filtered);
      setFriends(filtered);
    } else {
      setFriends([...friends, friend.id]);
      setFieldValue("members", [...friends, friend.id]);
    }
  }

  const onSubmit = async (values: ICreateGroupRequest) => {
    const userId = Number(decoded?.id) || 0;
    const newArray = selectedGroup?.id ? friends : [...friends, userId];

    const formData: any = new FormData();

    formData.append("id", values.id ? values.id.toString() : "0");

    formData.append("RoleId", decoded?.id);
    formData.append("IsBroadCast", false);
    formData.append("UserId", userId.toString());
    formData.append("name", values.name || "");
    // formData.append("members", JSON.stringify(newArray));
    newArray.forEach((member) => {
      formData.append("Members", member);
    });
    formData.append("timeStamp", new Date().getTime().toString());

    if (values.file) {
      const imageFile = inputFileRef.current?.files?.[0];
      if (imageFile) {
        formData.append("file", imageFile);
      }
    }

    const uuId = getUuid();
    const ChatRoomUuId = getUuid();

    formData.append("uuId", uuId);
    formData.append("ChatRoomUuId", ChatRoomUuId);

    if (selectedContact?.id) {
      formData.append("contactId", selectedContact?.id.toString());
    }

    const response: any = await tryCreateGroup(
      selectedGroup?.id || 0,
      formData,
    );
    if (selectedGroup?.id && response.statusCode === 200 && selectedChatRoom) {
      // setSelectedRoom: any;
      // selectedRoom: any;
      const updatedRoom = {
        id: selectedChatRoom.id,
        companyId: selectedChatRoom.companyId,
        isGroup: selectedChatRoom.isGroup,
        groupName: values.name,
        userId: selectedChatRoom.userId,
        toUser: selectedChatRoom.toUser,
        fromUser: selectedChatRoom.fromUser,
        toUserName: selectedChatRoom.toUserName,
        fromUserName: selectedChatRoom.fromUserName,
        toUserConnectionId: selectedChatRoom.toUserConnectionId,
        fromUserConnectionId: selectedChatRoom.fromUserConnectionId,
        lastMessageOn: selectedChatRoom.lastMessageOn,
        status: selectedChatRoom.status,
        message: selectedChatRoom.message,
        createdOn: selectedChatRoom.createdOn,
        updatedOn: selectedChatRoom.updatedOn,
        unReadMessagesCount: 0,
        isPinned: selectedChatRoom.isPinned,
        uuId,
        ChatRoomUuId,
        contactId: selectedContact?.id || 0,
        contactName: `${selectedContact?.firstName || ""} ${selectedContact?.lastName || ""}`,
      };
      // const updatedRoom = {
      //   ...selectedChatRoom,
      //   groupName: values.name,
      // };
      setSelectedChatRoom({ selectedChatRoom: updatedRoom });
      const newChatRooms = chatRooms?.map((chat: any) => {
        if (chat.id === Number(updatedRoom?.id)) {
          return {
            ...response.data,
            contactId: selectedContact?.id || 0,
            contactName: `${selectedContact?.firstName || ""} ${selectedContact?.lastName || ""}`,
            unReadMessagesCount: 0,
          };
        }
        return chat;
      });
      setChatRooms(newChatRooms);

      handleAssignContactGroup(
        updatedRoom?.id,
        selectedContact?.id || 0,
        `${selectedContact?.firstName || ""} ${selectedContact?.lastName || ""}`,
        queryClient,
      );
    }
    resetForm();
    handleClose();
    setFriends([]);
    if (isSmall) {
      navigate(`${AppRoutes.INBOX}/${selectedChatRoom?.id}`);
      setShowChatDetail(true);
    }
  };

  const formik = useCreateGroupForm(onSubmit, initialValues);
  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
    isSubmitting,
  } = formik;

  const handleGroupPictureChange = async () => {
    const file = inputFileRef.current?.files?.[0];
    if (file) {
      // const base64 = await convertToBase64(file);
      setFieldValue("file", file);
      const imageUrl = URL.createObjectURL(file);
      setImagePreviewUrl(imageUrl);
    }
  };

  useEffect(() => {
    if (selectedGroup) {
      setFieldValue("name", selectedGroup.groupName);
      setFieldValue("id", selectedGroup.id);
      refetch();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (members?.data) {
      const tempIds = members?.data?.map((item: any) => {
        return item.memberId;
      });
      setFriends(tempIds);
    }
  }, [members?.data]);

  function handleClose() {
    handleGroupClose();
    setFriends([]);
    formik.resetForm();
    if (isSmall && selectedGroup?.id) {
      navigate(`${AppRoutes.INBOX}/${selectedChatRoom?.id}`);
      setShowChatDetail(true);
    }
  }

  const handleSelectedContact = (data: ISearchContacts | null) => {
    setSelectedContact(data);
  };

  return (
    <Slider open={openGroup} size="sm" onClose={() => handleClose()}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          {selectedGroup?.id ? "Update Group " : "Create Group"}
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={() => handleClose()}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Paper>
            <Box
              p={3}
              style={{ overflow: "hidden" }}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box>
                {selectedGroup?.data?.file ? (
                  <Avatar
                    alt="Profile Picture"
                    src={
                      imagePreviewUrl ||
                      values?.file ||
                      // (values.file && URL.createObjectURL(values.file)) ||
                      `${ATTACHMENT_URL}${selectedGroup?.data?.file}`
                    }
                    sx={{ width: 80, height: 80, cursor: "pointer", mb: 2 }}
                  >
                    Group DP
                  </Avatar>
                ) : (
                  <Avatar
                    alt="Profile Picture"
                    src={
                      imagePreviewUrl ||
                      values?.file ||
                      `${ATTACHMENT_URL}${selectedGroup?.data?.file}`
                    }
                    sx={{ width: 80, height: 80, cursor: "pointer", mb: 2 }}
                  >
                    Group DP
                  </Avatar>
                )}

                <Box position="relative">
                  <IconButton
                    sx={{
                      position: "absolute",
                      bottom: "1px",
                      right: -11,
                      backgroundColor: "white",
                      marginLeft: "8px",
                    }}
                    onClick={() => inputFileRef.current?.click()}
                  >
                    <CreateIcon />
                  </IconButton>
                </Box>
              </Box>

              <input
                ref={inputFileRef}
                accept="image/*"
                style={{ display: "none" }}
                type="file"
                onChange={handleGroupPictureChange}
              />
              <Box mb={2}>Group name</Box>
              <TextField
                error={!!touched.name && !!errors.name}
                helperText={(touched.name && errors && errors.name) || ""}
                name="Create Group"
                placeholder="Group Name"
                style={{ width: "550px" }}
                type="text"
                value={values.name}
                onBlur={handleBlur("name")}
                onChange={handleChange("name")}
              />
              <ContactSearch selectedContact={handleSelectedContact} />
              <FriendsList
                formik={formik}
                friends={friends}
                isLoading={isLoading}
                isUpdate={!!selectedGroup?.id}
                members={members?.data || []}
                userData={userData}
                onAddFriend={(data) => handleAddFriend(data)}
              />
            </Box>
          </Paper>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            paddingTop="10px"
            spacing={2}
          >
            <Button
              startIcon={<AttachmentIcon />}
              style={{ padding: "0.5rem 1rem" }}
              variant="contained"
              type="submit"
              // onClick={() =>
              //   (values.members?.length !== 0 || selectedGroup?.id) &&
              //   handleSubmit()
              // }
            >
              {isSubmitting ? (
                <CircularProgress color="warning" size={12} />
              ) : selectedGroup?.id ? (
                "Update "
              ) : (
                "Create"
              )}
            </Button>
            <Button
              autoFocus
              color="error"
              startIcon={<CloseIcon />}
              style={{ padding: "0.5rem 1rem" }}
              variant="contained"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </Stack>
        </DialogContent>
      </form>
    </Slider>
  );
}

export default React.memo(CreateGroupPop);
