import { Menu, MenuItem, MenuList } from "@mui/material";
import useDecodedData from "hooks/useDecodedData";
import { IChat } from "pages/user/inbox/query/useFetchChats";

interface IProps {
  anchorEl: null | Element;
  index: number;
  msgOpen: boolean;
  handleMsgClose: (id: number) => void;
  handleQuoteMessageOpen: (chat: any) => void;
  handleGroupInfoOpen: (chat: IChat) => void;
  chat: IChat;
  isGroup: boolean;
  handleTaskOpen: (chat: IChat) => void;
  // deleteChatId: () => void;
  deleteChatId: (id: number) => void;
  handleDiscussInPrivate: (data: any) => void;
  handleEditMsgOpen: (id: number, message: string) => void;
  handleMarkUnread: (msgId: number, roomId: number) => void;
  roomId: number;
}

function MessageDropDown(props: IProps) {
  const {
    anchorEl,
    index,
    msgOpen,
    handleMsgClose,
    handleQuoteMessageOpen,
    chat,
    // info,
    isGroup,
    handleGroupInfoOpen,
    handleTaskOpen,
    deleteChatId,
    handleDiscussInPrivate,
    handleEditMsgOpen,
    handleMarkUnread,
    roomId,
  } = props;

  const decoded = useDecodedData();

  const handleItemClick = (event: React.MouseEvent<HTMLUListElement>) => {
    event.stopPropagation();
    // event.nativeEvent.stopImmediatePropagation();

    handleMsgClose(chat?.id);
    const target = event.target as HTMLLIElement;
    if (target && target.classList.contains("chat-menu-item")) {
      const targetedItem = target.ariaLabel;
      switch (targetedItem) {
        case "group-chat-info":
          return handleGroupInfoOpen(chat);
        case "chat-quote-message":
          return handleQuoteMessageOpen(chat);
        case "chat-unread-message":
          return handleMarkUnread(chat?.id, roomId);
        case "chat-task-message":
          return handleTaskOpen(chat);
        case "chat-private-message":
          return handleDiscussInPrivate(chat);
        case "chat-edit-message":
          return handleEditMsgOpen(chat?.id, chat?.message);
        case "chat-delete-message":
          return deleteChatId(chat?.id);
        default:
          break;
      }
    } else {
      throw new Error("No event detected message dropdown:handleItemClick:46");
    }

    return target;
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id={`chat-message-menu-${index}`}
      MenuListProps={{
        "aria-labelledby": `chat-message-button-${index}`,
      }}
      open={msgOpen}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleMsgClose}
    >
      {/* {isGroup ? ( */}
      <MenuList className="chat-menu-list" onClick={handleItemClick}>
        {isGroup && (
          <MenuItem
            aria-label="group-chat-info"
            className="chat-menu-item"
            id={`group-chat-info-${chat?.id}`}
          >
            Info
          </MenuItem>
        )}
        <MenuItem
          aria-label="chat-quote-message"
          className="chat-menu-item"
          id={`chat-quote-message-${chat?.id}`}
        >
          Quote message
        </MenuItem>
        {chat?.fromUser !== Number(decoded?.id) && (
          <MenuItem
            aria-label="chat-unread-message"
            className="chat-menu-item"
            id={`chat-unread-message-${chat?.id}`}
          >
            {chat?.readLater
              ? "Mark as read message"
              : "Mark as unread message"}
          </MenuItem>
        )}
        <MenuItem
          aria-label="chat-task-message"
          className="chat-menu-item"
          id={`chat-task-message-${chat?.id}`}
        >
          Create Task
        </MenuItem>
        {isGroup && (
          <MenuItem
            aria-label="chat-private-message"
            className="chat-menu-item"
            id={`chat-private-message-${chat?.id}`}
          >
            Discuss in a new chat
          </MenuItem>
        )}
        {chat?.fromUser === Number(decoded?.id) && (
          <MenuItem
            aria-label="chat-edit-message"
            className="chat-menu-item"
            id={`chat-edit-message-${chat?.id}`}
          >
            Edit message
          </MenuItem>
        )}
        {chat?.fromUser === Number(decoded?.id) && (
          <MenuItem
            aria-label="chat-delete-message"
            className="chat-menu-item"
            id={`chat-delete-message-${chat?.id}`}
          >
            Delete message
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  );
}

export default MessageDropDown;
