import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import {
  AUDIO_ICON,
  DOCX_ICON,
  MICROSOFT_WORD_ICON,
  NOT_SUPPORTED_ICON,
  PDF_ICONS,
  TXT_ICON,
  VIDEO_ICON,
  XLXS_ICON,
  ZIP_ICON,
} from "constants/constants";
import palette from "theme/palette";

type IFile = {
  url: string;
  fileType: string;
};

interface IProps {
  handleCancelFile: (remimage: any) => void;
  previewItem: IFile;
  fromMinimize?: boolean;
}

export const fileTypeIcons: { [key: string]: string } = {
  "application/pdf": PDF_ICONS,
  "application/msword": MICROSOFT_WORD_ICON,
  "text/plain": TXT_ICON,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    DOCX_ICON,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    XLXS_ICON,
  "application/vnd.ms-excel": XLXS_ICON,
  "application/wps-office.xlsx": XLXS_ICON,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    NOT_SUPPORTED_ICON, // Replace with PPTX_ICON if available
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
    NOT_SUPPORTED_ICON, // Replace with PPTX_ICON if available
  "application/vnd.ms-powerpoint": NOT_SUPPORTED_ICON, // Replace with PPTX_ICON if available
  "application/vnd.openxmlformats-officedocument.presentationml.slide":
    NOT_SUPPORTED_ICON, // Replace with PPTX_ICON if available
  "text/csv": XLXS_ICON, // Or CSV_ICON if available
  "application/zip": ZIP_ICON,
  "application/vnd.rar": ZIP_ICON,
  "application/x-rar-compressed": ZIP_ICON,
  "application/x-tar": ZIP_ICON, // Replace with TAR_ICON if available
  "application/x-gzip": ZIP_ICON, // Replace with GZIP_ICON if available
  "application/x-7z-compressed": ZIP_ICON, // Replace with 7Z_ICON if available
  "video/mp4": VIDEO_ICON,
  "video/mpeg": VIDEO_ICON, // Replace with MPEG_ICON if available
  "audio/mpeg": AUDIO_ICON,
  "audio/x-wav": AUDIO_ICON, // Replace with WAV_ICON if available
  "audio/ogg": AUDIO_ICON, // Replace with OGG_ICON if available
  "image/jpeg": "", // Empty string for JPEG image type
  "image/png": "", // Empty string for PNG image type
  "image/gif": "", // Empty string for GIF image type
  "image/bmp": "", // Empty string for BMP image type
  "image/tiff": "", // Empty string for TIFF image type
  "image/webp": "", // Empty string for WEBP image type
  "image/svg+xml": "", // Empty string for SVG image type
};

const getIconForFileType = (fileType: string): string => {
  // Check if the fileType exists in the mappings, if not return the default icon
  return fileTypeIcons[fileType] || NOT_SUPPORTED_ICON;
};
function MediaPreviewItem(props: IProps) {
  const { handleCancelFile, previewItem, fromMinimize } = props;

  const isImageType = previewItem.fileType.startsWith("image/");

  const styles: any = {
    width: fromMinimize ? "85px" : "100px",
    height: fromMinimize ? "85px" : "100px",
    objectFit: "contain",
    borderRadius: "5px",
  };
  return (
    <Box
      // key={index}
      sx={{
        position: "relative",
        width: fromMinimize ? "100px" : "120px",
        marginX: "5px",
        boxShadow: 10,
      }}
    >
      <CancelIcon
        sx={{
          width: "17px",
          height: "17px",
          position: "absolute",
          top: "-5px",
          right: 0,
          cursor: "pointer",
          color: `${palette.error.lightRed}`,
        }}
        onClick={() => handleCancelFile(previewItem.url)}
      />
      {isImageType ? (
        <img
          alt="original "
          loading="lazy"
          src={previewItem.url}
          style={styles}
        />
      ) : (
        <img
          alt={previewItem.fileType}
          loading="lazy"
          src={getIconForFileType(previewItem.fileType)}
          style={styles}
        />
      )}
    </Box>
  );
}

export default MediaPreviewItem;
