import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import palette from "theme/palette";
import { ICreateGroupRequest } from "../../query/useApiAction";

interface IFriendsListProps {
  userData: any;
  friends: number[];
  formik: FormikProps<any>;
  members?: any;
  isUpdate?: boolean;
  isLoading?: boolean;
  onAddFriend: (friend: ICreateGroupRequest) => void;
}
function FriendsList(props: IFriendsListProps) {
  const {
    userData,
    friends,
    members,
    isLoading,
    onAddFriend,
    formik,
    isUpdate,
  } = props;
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any[]>([]);
  useEffect(() => {
    if (userData) {
      const tempData = JSON.parse(JSON.stringify(userData));
      setData(tempData);
    }
  }, [userData]);

  const isSmall = useMediaQuery("(max-width:767px)");

  const handleSearch = (data: string) => {
    setSearch(data);
    if (!data) {
      return false;
    }
    const value = data?.toLowerCase();
    const tempUsers = JSON.parse(JSON.stringify(userData));
    const filterContact = tempUsers
      ? tempUsers?.filter(
        (i: any) =>
          i?.email?.toLowerCase()?.includes(value) ||
          i?.fullName?.toLowerCase()?.includes(value),
      )
      : [];
    setData(filterContact);
    return true;
  };

  const handleClearSearch = () => {
    setSearch("");
    setData(JSON.parse(JSON.stringify(userData)));
  };

  return (
    <Box>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {search && (
                <IconButton onClick={handleClearSearch}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        label="Search Friends"
        name="members"
        placeholder="Search friend"
        style={{ width: isSmall ? "100%" : "550px" }}
        type="text"
        value={search}
        onChange={(e: any) => handleSearch(e.target.value)}
      />

      <PerfectScrollbar>
        <Box p={isSmall ? 1 : 3} sx={{ maxHeight: 400 }}>
          {isLoading ? (
            <CircularProgress size={17} />
          ) : (
            data?.map((friend) => {
              const isPresent = friends?.includes(friend.id);
              return (
                <Box
                  key={friend.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    checked={isPresent}
                    name="members"
                    onChange={() => {
                      onAddFriend(friend);
                    }}
                  />
                  <Typography>
                    {friend.fullName} ({friend?.roleName})
                  </Typography>
                </Box>
              );
            })
          )}
          {formik.values.members.length === 0 && !isUpdate && (
            <FormHelperText
              id="my-helper-text"
              sx={{ color: palette.error.dark }}
            >
              Please select at least one friend
            </FormHelperText>
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );
}

export default FriendsList;
