/**
 * @format
 */
const QueryKeys = {
  users: "teamUsers",
  paymentList: "paymentList",
  usersByRoleId: "usersByRoleId",
  getClientAccess: "getClientAccess",
  getCompanyEmail: "getCompanyEmail",
  screens: "screens",
  pipelines: "pipelines",
  callerIds: "callerIds",
  virtualNumbersAsCallerIds: "virtualNumbersAsCallerIds",
  screensAccess: "screens-access",
  roles: "roles",
  permissions: "permissions",
  leadSources: "leadSources",
  leadStatuses: "leadStatuses",
  readLaterGetAll: "readLaterGetAll",
  chatReadInfo: "chatReadInfo",
  dndList: "dndList",
  dndListByUserId: "dndListByUserId",
  onlineUsers: "onlineUsers",
  leadStatusesWithoutPagination: "leadStatusesWithoutPagination",
  rolePermissions: "rolePermissions",
  rolePermissionsInit: "rolePermissionsInit",
  contacts: "contact",
  contactsByUserId: "contactsByUserId",
  contactAtOnce: "contactAtOnce",
  contract: "contract",
  contactsBySearch: "contactsBySearch",
  userRoles: "user-roles",
  preferences: "preferences",
  customFields: "custom-fields",
  customFieldsByUserId: "customFieldsByUserId",
  emailExist: "email-exist",
  notes: "notes",
  activitiesType: "activitiesType",
  activities: "activities",
  activitiesToday: "activitiesToday",
  getClientAccessList: "getClientAccessList",
  getUnAssignedAS: "getUnAssignedAS",
  getAssignAppoinmentAS: "getAssignAppoinmentAS",
  clientList: "clientList",
  getTimeLine: "getTimeLine",
  getDealCard: "getDealCard",
  getDealCount: "getDealCount",
  getDashboardMain: "getDashboardMain",
  getComunication: "getComunication",
  getAllAssignedAS: "getAllAssignedAS",
  getSignedContacts: "getSignedContacts",
  getAllCustomersList: "getAllCustomersList",
  getAllCampaign: "getAllCampaign",
  getAllDuplicate: "getAllDuplicate",
  getAllLetterTemplates: "getAllLetterTemplates",
  myList: "my-list",
  myContactList: "my-contact-list-by-listId",
  getAllActivities: "get-all-activities",
  allCounts: "all-counts",
  allCardOnFile: "all-card-on-file",
  tags: "tags",
  tagsWithoutPagination: "tagsWithoutPagination",
  tagsByContactId: "tagsByContactId",
  templates: "templates",
  userSpecificTemplates: "userSpecificTemplates",
  videoTemplates: "video-templates",
  voiceTemplates: "voice-templates",
  sendMessage: "sendMessage",
  sendEmail: "sendEmail",
  getConversation: "getConversation",
  getLoggConversation: "getLogsConversation",
  getEmails: "getEmails",
  getSms: "getSms",
  getAllPostCardTemplates: "getAllPostCardTemplates",
  getAllDripCampaign: "getAllDripCampaign",
  getAllDripCampaignFolder: "getAllDripCampaignFolder",
  allActivity: "allActivity",
  allActivityByUserId: "allActivityByUserId",
  allActivityAtOnce: "allActivityAtOnce",
  allActivityByUserIdByMonth: "allActivityByUserIdByMonth",
  getDeals: "getDeals",
  searchContact: "searchContact",
  getAllLeadStatusAscending: "getAllLeadStatusAscending",
  dueToday: "dueToday",
  activityReminder: "activityReminder",
  files: "files",
  allChats: "allchats",
  allChatRoomPagination: "allChatRoomPagination",
  getContactFileNames: "getContactFileNames",
  getContactCampaignNames: "getContactCampaignNames",
  attachments: "attachments",
  allChatFiles: "allChatFiles",
  chatBySearch: "chatBySearch",
  sendChatMessage: "sendChatMessage",
  unreadCount: "unreadCount",
  chatUnreadCount: "chatUnreadCount",
  getAttachmentsByFolderId: "getAttachmentsByFolderId",
  getAllFolderData: "getAllFolderData",
  dnc: "dnc",
  agreementByurl: "agreementByurl",
  checkToken: "checkToken",
  agreementById: "agreementById",
  agreementRemove: "agreementRemove",
  agreementUpdate: "agreementUpdate",
  ContactAnalytics: "ContactAnalytics",
  dncRoleId: "dncRoleId",
  getAlReports: "getAlReports",
  addFolders: "addFolders",
  getallCustomers: "getallCustomers",
  groupMembers: "group-members",
  decrypted: "decrypted",
  cardFeeDetails: "cardFeeDetails",
  paymentHistory: "paymentHistory",
  paymentInvoiceList: "paymentInvoiceList",
  assignHistory: "paymentHistory",
  suggestions: "suggestions",
  onlineASAgentList: "onlineASAgentList",
  onlineSalesAgentList: "onlineSalesAgentList",
  callInfo: "callInfo",
  getAllQuality: "getAllQuality",
  invoiceSummary: "invoiceSummary",
  getUserAccess: "getUserAccess",
  fetchVideoLibrary: "fetchVideoLibrary",
  getSequenceList: "getSequenceList",
  getSequenceDayById: "getSequenceDayById",
  getSequenceById: "getSequenceById",
  getContactsSequenceById: "getContactsSequenceById",
  getHolidayList: "getHolidayList",
  leadGroupList: "leadGroupList",
  callingScript: "callingScript",
  emailSignature: "emailSignature",
  emailConfiguration: "emailConfiguration",
  leadGroupListByCampaignId: "leadGroupListByCampaignId",
  getAllCompanies: "getAllCompanies",
  getDripCampaignByID: "getDripCampaignByID",
  getContactByDripCampaignId: "getContactByDripCampaignId",
  getCampaignStepSentContact: "getCampaignStepSentContact",
  getAllVirtualNumbers: "getAllVirtualNumbers",
  getAllCallResults: "getAllCallResults",
  activeSalesAgentList: "activeSalesAgentList",
  getAllDisposition: "getAllDisposition",
  getAllCampaignTimeSlot: "getAllCampaignTimeSlot",
  getAllCallBackList: "getAllCallBackList",
  getCallCampaign: "getCallCampaign",
  getCallCampaignSetting: "getCallCampaignSetting",
  getCallCampaignByContactId: "getCallCampaignByContactId",
  getTimeSlots: "getTimeSlots",
  getSaleStatuses: "getSaleStatuses",
  getDialableStatuses: "getDialableStatuses",
  getCampaignDetails: "getCampaignDetails",
  getApprovedCustomer: "getApprovedCustomer",
  customerDetails: "customerDetails",
  getMaxCall: "getMaxCall",
  customerPosition: "customerPosition",
  getCallSchedule: "getCallSchedule",
  customerCallsSchedule: "customerCallsSchedule",
  customerCallsScheduleCount: "customerCallsScheduleCount",
  campaignCallerIds: "campaignCallerIds",
  callTimeSlots: "callTimeSlots",
  getAllCallLogs: "getAllCallLogs",
  getAllCallCampaign: "getAllCallCampaign",
  getGlobalSearch: "getGlobalSearch",
  getGlobalSearchByType: "getGlobalSearchByType",
  getGlobalSearchByPagination: "getGlobalSearchByPagination",
  getDashboardSearch: "getDashboardSearch",
  getDashboardCount: "getDashboardCount",
  getForwardNumber: "getForwardNumber",
  getAllSalesPerformance: "getAllSalesPerformance",
  getAllTimeStatusReports: "getAllTimeStatusReports",
  asAgentReport: "asAgentReport",
  dispositionCodes: "dispositionCodes",
  getAllTrigger: "getAllTrigger",
  transcription: "transcription",
  contactTableSchema: "contactTableSchema",
  contactDownloadSkip: "contactDownloadSkip",
  tableSchema: "tableSchema",
  downloadSkip: "donwloadSkipUpdate",
  csvColumns: "csvColumns",
  contactCsvColumns: "contactCsvColumns",
  getAllContactCampaign: "getAllContactCampaign",
  getAllImportHistory: "getAllImportHistory",
  getAllContactImportHistory: "getAllContactImportHistory",
  fetchPermissionsList: "fetchPermissionsList",
  fetchPermissionsListByID: "fetchPermissionsListByID",
  getAllConversation: "getAllConversation",
  getAllPaymentMethod: "getAllPaymentMethod",
  getPermissionByScreenCode: "getPermissionByScreenCode",
  getAllCallLog: "getAllCallLog",
  deleteCallCampaign: "deleteCallCampaign",
  getAllPaymentItems: "getAllPaymentItems",
  getAllPaymentGateway: "getAllPaymentGateway",
  getAllCheckoutLink: "getAllCheckoutLink",
  moveToOtherCompany: " moveToOtherCompany",
  // aaditionalContact
  getAdditionalContact: "getAdditionalContact",
  getContactViewInfo: "getContactViewInfo",
  paymentInvoiceDetails: "paymentInvoiceDetails",
  paymentInvoiceHistory: "paymentInvoiceHistory",
  getAllPaymentLog: "getAllPaymentLog",
  getAllPaymentSetting: "getAllPaymentSetting",
  getFetchPaymentSetting: "getFetchPaymentSetting",
  getAllBlackListData: "getAllBlackListData",
  getPaymentReminders: "getPaymentReminders",
  addCallCampaignScript: "addCallCampaignScript",
  onlineAdminAgentList: "onlineAdminAgentList",
  getPowerDialSetting: "getPowerDialSetting",
  getMyListContacts: "getMyListContacts",
  getAgentPerformanceCount: "getAgentPerformanceCount",
  userPlatFormEmail: "userPlatFormEmail",
  getAllMailConversation: "getAllMailConversation",
  getEmailsV2: "getEmailsV2",
  missedCalls: "missedCalls",
  fetchCompanyDetails: "fetchCompanyDetails",
  fetchAccounts: "fetchAccounts",
  notifications: "notifications",
  clientNotifications: "clientNotifications",
  emailNotification: "emailNotification",
  smsNotification: "smsNotification",
  getAllLogs: "getAllLogs",
  syncEmails: "syncEmails",
  getAllCallCampaignClient: "getAllCallCampaignClient",
  getAllAsByClientId: "getAllAsByClientId",
  getUserEmail: "getUserEmail",
  getAssignUserEmail: "getAssignUserEmail",
  getAttachmentByUniqueId: "getAttachmentByUniqueId",
  getAllContactsForm: "getAllContactsForm",
  contactFormByUrl: "contactFormByUrl",

  getContactDetailsForCustomer: "getContactDetailsForCustomer",
  clientNotes: "clientNotes",
  attachmentsDownload: "attachmentsDownload",
  useFetchEmailNotification: "useFetchEmailNotification",
  useFetchSmsNotification: "useFetchSmsNotification",
};

export { QueryKeys };

