/* eslint-disable prettier/prettier */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
// import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Slider from "components/layouts/popup-modals/Slider";
import TextField from "components/textfield";
import { preferencesConstants } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import { debounce } from "lodash";
import moment from "moment";
import ColorPickerSlider from "pages/user/contacts/components/ColorPickerSlider";
import ActivityToolTip from "pages/user/contacts/details/component/add-activity/components/ActivityTooltip";
import AssignColor from "pages/user/contacts/details/component/add-activity/components/AssignColor";
import { useApiActions } from "pages/user/contacts/details/query/useApiActions";
import { useFetchActivitiesTypes } from "pages/user/contacts/details/query/useFetchActivitiesTypes";
import { IToggleModel } from "pages/user/contacts/details/type";
import { IAddActivityForm } from "pages/user/contacts/hooks/useAddActivityForm";
import {
  ISearchContacts,
  useFetchContactsBySearch,
} from "pages/user/contacts/query/useFetchContactBySearch";
import { useEffect, useMemo, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useContactActions } from "redux/contacts/contacts";
import { preferenceList } from "redux/dashboard/dashboard";
import { timeDuration } from "utils";
import useTaskForm, { IAddTaskForm } from "../../hook/useTaskForm";
import TaskFields from "../TaskFields";

interface ICreateTask {
  open: boolean;
  handleClose: (_: keyof IToggleModel) => void;
  name?: string;
  title?: string;
  from?: string;
  chatInfo?: any;
}
const initialValues: IAddActivityForm = {
  id: 0,
  title: "",
  descrition: "",
  contactId: "",
  assignToId: [],
  duration: "",
  durationCount: 0,
  date: "",
  time: "",
  activityTypeId: 0,
  isSendEmailToAttendee: false,
  isSendSmsToAttendee: false,
  isReminder: false,
  setReminderBefore: 15,
  contactName: "",
  contactEmail: "",
  callerId: "",
  //  ----invite template -start
  smsInviteTemplateClient: "",
  emailInviteTemplateClient: "<p></p>",
  emailInviteTemplateSubjectClient: "",
  smsInviteTemplateUser: "",
  emailInviteTemplateUser: "<p></p>",
  emailInviteTemplateSubjectUser: "",
  // ----invite-template end
  // ----- reminder-template - start
  smsReminderTemplateClient: "",
  emailReminderTemplateClient: "<p></p>",
  emailReminderTemplateSubjectClient: "",
  smsReminderTemplateUser: "",
  emailReminderTemplateUser: "<p></p>",
  emailReminderTemplateSubjectUser: "",
  //  ----reminder-template - end
  // smsTemplateTemp: "",
  // emailTemplateTemp: "<p></p>",
  // emailTemplateSubjectTemp: "",
  emailSign: "",
  fromEmail: "",
  userEmails: [],
  userMobiles: [],
};

// const setInitialValuesFromData = (data: any, setFieldValue: any) => {
//   if (!data) return;
//   const fieldValues: { [key: string]: any } = {
//     assignToId: data?.callCampaignId || null,
//     contactId: data?.callScriptId,

//   };

//   Object.keys(fieldValues).forEach((key) => {
//     setFieldValue(key, fieldValues[key]);
//   });
// };

function CreateTask(props: ICreateTask) {
  const { open, handleClose, name, title, from, chatInfo } = props;
  const [toggleSearch, setToggleSearch] = useState(true);
  const [chatterList, setChatterList] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");
  const decoded = useDecodedData()
  const [tableColour, setTableColourData] = useState({
    contactId: 0,
    activityId: 0,
    color: "",
  });
  const [openColourPicker, setOpenColourPicker] = useState(false);
  const getPreferenceList = useSelector(preferenceList);

  const selectedPrefCallerId = useMemo(
    () =>
      getPreferenceList?.find(
        (item) => item.preferenceName === preferencesConstants.selectCallerId,
      ),
    [getPreferenceList],
  );

  const [dataContacts, setDataContacts] = useState<ISearchContacts[]>([]);

  const contactsStore = useSelector((state: any) => state.contacts);
  const { contact, activity } = contactsStore;
  const { trySaveActivity } = useApiActions();
  const { removeActivity } = useContactActions();


  const { data: contactList, refetch: refetchContacts } =
    useFetchContactsBySearch(`RoleName=${decoded?.RoleName}&userid=${decoded.id}&search=${searchText}`, false, false);

  const { data: activities, refetch: refetchActivitiesTypes } =
    useFetchActivitiesTypes(false);

  const onSubmit = async (values: IAddTaskForm) => {
    const parsedCallerId = JSON.parse(selectedPrefCallerId?.preferences);
    await trySaveActivity({
      ...values,
      callerId: parsedCallerId || "",
      setReminderBefore: Number(values.setReminderBefore),
      timeStamp: moment(values.date).unix().toString(),
      //  ----invite template -start
      smsInviteTemplateClient: "",
      emailInviteTemplateClient: "<p></p>",
      emailInviteTemplateSubjectClient: "",
      smsInviteTemplateUser: "",
      emailInviteTemplateUser: "<p></p>",
      emailInviteTemplateSubjectUser: "",
      // ----invite-template end
      // ----- reminder-template - start
      smsReminderTemplateClient: "",
      emailReminderTemplateClient: "<p></p>",
      emailReminderTemplateSubjectClient: "",
      smsReminderTemplateUser: "",
      emailReminderTemplateUser: "<p></p>",
      emailReminderTemplateSubjectUser: "",
      //  ----reminder-template - end
      // smsTemplateTemp: "",
      // emailTemplateTemp: "<p></p>",
      // emailTemplateSubjectTemp: "",
      emailSign: "",
      fromEmail: "",
      userEmails: [],
      userMobiles: [],

    });
    handleClose?.(name?.toLowerCase() as keyof IToggleModel);
    removeActivity();
    formik?.resetForm();
    setToggleSearch(false);
    return true;
  };
  const formik = useTaskForm(onSubmit, initialValues);

  useEffect(() => {
    if (!chatInfo?.isGroup) {
      setChatterList([
        {
          id: chatInfo?.fromUser,
          value: chatInfo?.fromUserName,
        },
        {
          id: chatInfo?.toUser,
          value: chatInfo?.toUserName,
        },
      ]);
    }
  }, [chatInfo]);

  useEffect(() => {
    if (contactList?.data) {
      setDataContacts(contactList.data);
    }
  }, [contactList?.data]);

  const {
    handleSubmit,
    setFieldValue,
    setFieldError,
    setTouched,
    handleBlur,
    handleChange,
    isSubmitting,
    values,
    touched,
    errors,
  } = formik;

  useEffect(() => {
    if (open) {
      // refetchTeam();
      // refetchContacts();
      refetchActivitiesTypes();
    }
  }, [open])

  useEffect(() => {
    if (open) {

      if (!from) {
        setFieldValue("assignToId", contact?.salesRepId);
        setFieldValue("contactId", contact?.id);
      }
      setFieldValue("date", moment().format("YYYY-MM-DD[T]HH:mm"));
      setFieldValue("time", moment().format("YYYY-MM-DD[T]HH:mm"));
      if (activity) {
        setFieldValue("id", activity?.id);
        setFieldValue("title", activity?.title);
        setFieldValue("descrition", activity?.descrition);
        setFieldValue("assignToId", activity?.assignToId);
        setFieldValue("duration", activity?.duration);
        setFieldValue("durationCount", activity?.durationCount);
        setFieldValue("date", activity?.date);
        setFieldValue("time", activity?.time);
        setFieldValue("activityTypeId", activity?.activityTypeId);
        setFieldValue("contactName", activity?.contactName);
        setFieldValue("contactEmail", activity?.contactEmail);
        setFieldValue("isSendEmailToAttendee", activity?.isSendEmailToAttendee);
        setFieldValue("isSendEmailToAttendee", activity?.isSendEmailToAttendee);

        setFieldValue("isSendSmsToAttendee", activity?.isSendSmsToAttendee);

        setFieldValue("isReminder", activity?.isReminder);
        setFieldValue("setReminderBefore", activity?.setReminderBefore);
      }
      if (name) {
        const filteredType = activities?.data?.find(
          (x: any) => x.activityTypeName === name,
        );
        if (filteredType) {
          setFieldValue("activityTypeId", filteredType.id);
        }
      }
    }
  }, [open, from, activities, contact, activity]);

  useEffect(() => {
    if (name && activities?.data) {
      const filteredType = activities?.data?.find(
        (x: any) => x.activityTypeName === name,
      );
      if (filteredType) {
        setFieldValue("activityTypeId", filteredType.id);
        setTouched({ ...formik.touched, activityTypeId: true });
        setFieldError("activityTypeId", "");
      }
    }
  }, [name]);

  const debouncedSearch = debounce(() => {
    refetchContacts();
  }, 1000);

  const handleSearchContact = (value: string) => {
    setSearchText(value);
    if (value) debouncedSearch();
  };

  const handleActivitySelect = (id: number) => {
    setFieldValue("activityTypeId", id);
  };

  const onClose = () => {
    handleClose(name?.toLowerCase() as keyof IToggleModel);
    removeActivity();
    formik?.resetForm();
  };

  const handleContactSearch = (data: any) => {
    setFieldValue("contactId", data?.id);
    setFieldValue("assignToId", data?.salesRepId);
    setSearchText(`${data?.firstName} ${data?.lastName}`);
    setDataContacts([]);
  };
  const handleContactSearchClose = () => {
    setSearchText("");
    setDataContacts([]);
  };

  useEffect(() => {
    if (activities?.data) {
      setFieldValue("activityTypeId", activities?.data[2]?.id);
    }
  }, [activities?.data]);

  return (
    <Slider open={open} size="xs">
      <DialogTitle>
        {title || "Add chat Task"}
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ justifyContent: "center", alignSelf: "center" }}>
        <PerfectScrollbar>
          <DialogContent dividers>
            <form>
              <>
                <ActivityToolTip
                  activities={activities?.data || []}
                  error={!!touched.activityTypeId && !!errors.activityTypeId}
                  handleActivitySelect={handleActivitySelect}
                  helperText={
                    (touched.activityTypeId &&
                      errors &&
                      errors.activityTypeId) ||
                    ""
                  }
                  values={values}
                />
                <TaskFields
                  chatInfo={chatInfo}
                  chatterList={chatterList}
                  contacts={dataContacts || []}
                  formik={formik}
                  from={from}
                  handleContactSearch={handleContactSearch}
                  handleContactSearchClose={handleContactSearchClose}
                  handleSearch={handleSearchContact}
                  searchText={searchText}
                />
                <AssignColor
                  formik={formik}
                  handleSelectColour={() => {
                    setOpenColourPicker(true);
                  }}
                />
                <FormGroup>
                  <Stack alignItems="center" direction="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isReminder}
                          onChange={() => {
                            setFieldValue("isReminder", !values.isReminder);
                          }}
                        />
                      }
                      label="Remind me before the event"
                      onChange={handleChange("isReminder")}
                    />

                    {values.isReminder ? (
                      <>
                        <TextField
                          isSelect
                          error={
                            !!touched.setReminderBefore &&
                            !!errors.setReminderBefore
                          }
                          helperText={
                            (touched.setReminderBefore &&
                              errors &&
                              errors.setReminderBefore) ||
                            ""
                          }
                          label="Before Meeting"
                          menuItems={timeDuration(5)}
                          name="activityBefore"
                          placeholder=""
                          style={{ width: "250px" }}
                          value={values.setReminderBefore}
                          onBlur={handleBlur("setReminderBefore")}
                          onSelectHandler={(event) => {
                            if (event.target.value) {
                              setFieldValue(
                                "setReminderBefore",
                                event.target.value,
                              );
                            } else {
                              setFieldValue("setReminderBefore", 0);
                            }
                          }}
                        />
                      </>
                    ) : null}
                  </Stack>
                  <FormControlLabel
                    control={<Checkbox checked={values.isSendSmsToAttendee} />}
                    label=" Send sms reminder to attendee"
                    onChange={() => {
                      setFieldValue(
                        "isSendSmsToAttendee",
                        !values.isSendSmsToAttendee,
                      );
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={values.isSendEmailToAttendee} />
                    }
                    label="Send an email notification to the attendee"
                    onChange={() => {
                      setFieldValue(
                        "isSendEmailToAttendee",
                        !values.isSendEmailToAttendee,
                      );
                    }}
                  />
                </FormGroup>
              </>
            </form>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              paddingTop="10px"
              spacing={2}
            >
              <Button
                startIcon={<AddCircleIcon />}
                style={{ padding: "0.5rem 1rem" }}
                variant="contained"
                onClick={() => handleSubmit()}
              >
                {isSubmitting ? (
                  <CircularProgress color="warning" size={17} />
                ) : (
                  "Save"
                )}
              </Button>
              <Button
                autoFocus
                color="error"
                startIcon={<CloseIcon />}
                style={{ padding: "0.5rem 1rem" }}
                variant="contained"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
            <ColorPickerSlider
              isCloseFunction
              colorData={tableColour}
              formik={formik}
              handleClose={() => setOpenColourPicker(false)}
              open={openColourPicker}
              setColor={setTableColourData}
            />
          </DialogContent>
        </PerfectScrollbar>
      </Box>
    </Slider>
  );
}

export default CreateTask;
