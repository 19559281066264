import { Box, CircularProgress } from "@mui/material";

function ChatLoader() {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50px",
        left: "50%",
        transform: "translate(-50%)",
      }}
    >
      <CircularProgress size={54} />
    </Box>
  );
}

export default ChatLoader;
