import { makeStyles } from "@material-ui/core/styles";
import { Person } from "@mui/icons-material";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import DescriptionIcon from "@mui/icons-material/Description";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalSeeIcon from "@mui/icons-material/LocalSee";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideocamIcon from "@mui/icons-material/Videocam";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import { Box, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import { CALL_MESSAGES } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import ChatListAvatar from "pages/user/inbox/component/chat-header/components/chat-list-avatar";
import FileTypes from "pages/user/inbox/component/chat-listing/component/FileTypes";
import PinChat from "pages/user/inbox/component/pin-chat";
import React, { MouseEvent, useContext } from "react";
import palette from "theme/palette";
import { fileType, messageFormatDateTime, textEllipsis } from "utils";

const useStyles = makeStyles({
  typeIcons: {
    fontSize: "14px",
    color: "#546376",
  },
});

function ChatRoomListItem(props: any) {
  const { item, isOnline, handleChat, selectedRoomId, handleContactClick } =
    props;
  const decoded = useDecodedData();
  const classes = useStyles();
  const { whoIsTyping } = useContext(SocketContext);
  return (
    <Box
      key={item.id}
      sx={{
        backgroundColor:
          selectedRoomId === item.id ? palette.info.lightBg : "transparent",
        borderBottom: "1px solid #ebebeb",
        padding: "14px 10px",
        cursor: "pointer",
        display: "flex",
        position: "relative",
        "&:hover span.down-arrow": {
          display: "block",
        },
      }}
      onClick={(e: MouseEvent<HTMLElement>) => handleChat(e, item?.id, item)}
    >
      <PinChat data={item} />

      <Box
        sx={{
          marginRight: "8px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box>
          {!item?.isGroup && (
            <Typography
              sx={{
                fontWeight: "600",
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color: isOnline ? "green" : "red",
                  fontSize: "12px",
                  position: "absolute",
                  top: "3.1em",
                  zIndex: 1,
                  left: "2.4rem",
                }}
              />
            </Typography>
          )}
        </Box>
        <ChatListAvatar
          decodedId={decoded?.id}
          fromProfileUrl={item?.fromProfileUrl}
          isGroup={item?.isGroup}
          profileUrl={item?.profileUrl}
          toProfileUrl={item?.toProfileUrl}
          toUser={item.toUser}
          toUserName={item.toUserName}
        />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#133159",
                fontWeight: "600",
                fontSize: {
                  midlg: "12px",
                  lg: "14px",
                },
              }}
            >
              {textEllipsis(
                item?.isGroup
                  ? item.groupName
                  : Number(decoded?.id) === item.toUser
                    ? item.fromUserName
                    : item.toUserName,
                15,
              )}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "10px",
              textAlign: "right",
            }}
          >
            {/* {timeDiffCalc(item.lastMessageOn)} */}
            {messageFormatDateTime(item.lastMessageOn)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {item.id === whoIsTyping?.roomId ? (
            <Typography
              sx={{
                textAlign: "left",
                fontSize: "12px",
                color: palette.success.main,
              }}
            >
              {whoIsTyping?.userName} is typing...
            </Typography>
          ) : (
            <Box>
              {fileType(item.message) === "image" ? (
                <FileTypes
                  fileIcon={<LocalSeeIcon className={classes.typeIcons} />}
                  subTitle="Photo"
                />
              ) : fileType(item.message) === "video" ? (
                <FileTypes
                  fileIcon={<VideocamIcon className={classes.typeIcons} />}
                  subTitle="video"
                />
              ) : fileType(item.message) === "audio" ? (
                <FileTypes
                  fileIcon={<AudioFileIcon className={classes.typeIcons} />}
                  subTitle="Audio"
                />
              ) : fileType(item.message) === "pdf" ? (
                <FileTypes
                  fileIcon={<PictureAsPdfIcon className={classes.typeIcons} />}
                  subTitle="pdf"
                />
              ) : fileType(item.message) === "word" ? (
                <FileTypes
                  fileIcon={<LibraryBooksIcon className={classes.typeIcons} />}
                  subTitle="word"
                />
              ) : // ) : fileType(item.message) === "doc" ? (
                //   <FileTypes
                //     fileIcon={<DescriptionIcon className={classes.typeIcons} />}
                //     subTitle="doc"
                //   />
                fileType(item.message) === "text/plain" ? (
                  <FileTypes
                    fileIcon={<WysiwygIcon className={classes.typeIcons} />}
                    subTitle="txt"
                  />
                ) : fileType(item.message) === "excel" ? (
                  <FileTypes
                    fileIcon={<WysiwygIcon className={classes.typeIcons} />}
                    subTitle="excel"
                  />
                ) : fileType(item.message) === "ppt" ? (
                  <FileTypes
                    fileIcon={<DescriptionIcon className={classes.typeIcons} />}
                    subTitle="ppt"
                  />
                ) : fileType(item.message) === "text/csv" ? (
                  <FileTypes
                    fileIcon={<WysiwygIcon className={classes.typeIcons} />}
                    subTitle="csv"
                  />
                ) : CALL_MESSAGES.includes(item.type) ? (
                  <FileTypes
                    fileIcon={<VideocamIcon className={classes.typeIcons} />}
                    subTitle={item.type}
                  />
                ) : (
                  <>
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html:
                          textEllipsis(
                            Number(decoded?.id) === item.lastMessageUserId
                              ? "you: "
                              : `${item.lastMessageUserName}: `,
                            40,
                          ) +
                          textEllipsis(
                            item.isMentioned
                              ? `Mentioned you ${item.message}`
                              : item.message,
                            20,
                          ) || "",
                      }}
                      sx={{
                        fontSize: "11px",
                        color: "#546376",
                      }}
                    />
                  </>
                )}
            </Box>
          )}
        </Box>
        {item?.contactId ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Person sx={{ color: palette.info.main, fontSize: 18 }} />
            <Typography
              sx={{
                color: palette.info.main,
                fontWeight: "600",
                mt: 1,
                fontSize: {
                  midlg: "0.9rem",
                  lg: "0.8rem",
                },
              }}
              onClick={(event: any) => {
                event.stopPropagation();
                handleContactClick?.(item.contactId);
              }}
            >
              {item?.contactName}
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default React.memo(ChatRoomListItem);
