/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface ISelectedClintId {
  draftMail: any;
}

const initialState: ISelectedClintId = {
  draftMail: null,
};

interface IPayload {
  draftMail: any;
}

interface IAction {
  payload: IPayload;
  type: any;
}
const draftMailSlice = createSlice({
  name: "draftEmail",
  initialState,
  reducers: {
    setDraftMail: (state, action: IAction) => {
      state.draftMail = action.payload.draftMail;
    },
  },
});

export const { setDraftMail } = draftMailSlice.actions;

export const useDraftMailSliceActions = () => {
  const dispatch = useAppDispatch();
  return {
    setDraftMail: (payload: IPayload) => dispatch(setDraftMail(payload)),
  };
};
const { reducer } = draftMailSlice;
export default reducer;
