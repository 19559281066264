/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IActivityFetchResponse {
  activityFetch: "";
}

const initialState: IActivityFetchResponse = {
  activityFetch: "",
};

interface IPayload {
  activityFetch: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const activityFetchResponse = createSlice({
  name: "activityFetchResponse",
  initialState,
  reducers: {
    setActivityFetch: (state, action: IAction) => {
      state.activityFetch = action.payload.activityFetch;
    },
  },
});

export const { setActivityFetch } = activityFetchResponse.actions;

export const useActivityFetchActions = () => {
  const dispatch = useAppDispatch();
  return {
    setActivityFetch: (payload: IPayload) =>
      dispatch(setActivityFetch(payload)),
  };
};
const { reducer } = activityFetchResponse;
export default reducer;
