/**
 * @format
 */
import { Spinner } from "components/loader";
import { AllActivityLoader } from "./AllActivityLoader";
import { AllConversationLoader } from "./AllConversationLoader";
import { ChatInboxLoader } from "./ChatInboxLoader";
import { ChatRoomLoader } from "./ChatRoomLoader";
import { ContactActivityLoader } from "./ContactActivityLoader";
import { ContactFilesLoader } from "./ContactFilesLoader";
import { ContactLogLoader } from "./ContactLogLoader";
import { ContactNotesLoader } from "./ContactNotesLoader";
import { ContactTableLoader } from "./ContactTableLoader";

type LoaderType =
  | "ContactTable"
  | "AllConversation"
  | "ContactNotes"
  | "ContactActivity"
  | "Files"
  | "Log"
  | "AllActivity"
  | "ChatRoom"
  | "ChatInbox";

interface Props {
  count?: number;
  type?: LoaderType;
}

function ContentLoader(props: Props) {
  const { type = "ContactTable", count = 1 } = props;

  const getPlaceholder = () => {
    switch (type) {
      case "ContactTable":
        return <ContactTableLoader {...props} />;
      case "AllConversation":
        return <AllConversationLoader {...props} />;
      case "ContactNotes":
        return <ContactNotesLoader {...props} />;
      case "ContactActivity":
        return <ContactActivityLoader {...props} />;
      case "Files":
        return <ContactFilesLoader {...props} />;
      case "Log":
        return <ContactLogLoader {...props} />;
      case "AllActivity":
        return <AllActivityLoader {...props} />;
      case "ChatRoom":
        return <ChatRoomLoader {...props} />;
      case "ChatInbox":
        return <ChatInboxLoader {...props} />;
      default:
        return <Spinner />;
    }
  };

  return (
    <div>
      {Array.from({ length: count }, (_, i) => (
        <div key={`content_loader_${type}_${i}`}>{getPlaceholder()}</div>
      ))}
    </div>
  );
}

export { ContentLoader };
