/* eslint-disable no-useless-concat */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { encode } from "base-64";
import { IResponsePowerDialer } from "pages/admin/settings/screens/power-dialer/query/useFetchPowerDialer";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function fetchPowerDialer(paylod: any): Promise<IResponsePowerDialer> {
  return client.get(
    "https://www.cutcrm.com:81/api/call-manager/call-logs?AccountSid=ACb03fed2316821f6bedddc5b11001e8d7&AuthToken=32b8fc3ba4471927b1b89f408c191269",
  );
}
const getTwiliowRecordingURL = async (url: string, paylod: any) => {
  const response = await fetch(`https://api.twilio.com${url}`, {
    method: "get",
    headers: new Headers({
      Authorization: `Basic ${encode(
        `${"ACb03fed2316821f6bedddc5b11001e8d7"}` +
          ":" +
          `${"32b8fc3ba4471927b1b89f408c191269"}`,
      )}`,
      "Content-Type": "application/json",
    }),
  });
  const data = await response.json();
  return data?.recordings[0];
};

async function saveSetting(body: any): Promise<any> {
  return client.post(API_URLS.ADD_POWER_DIALER_SETTING, body);
}

async function getPowerSetting(userId: number): Promise<any> {
  return client.get(`${API_URLS.GET_POWER_DIAL_SETTING}?userId=${userId}`);
}

export {
  fetchPowerDialer,
  getPowerSetting,
  getTwiliowRecordingURL,
  saveSetting
};

