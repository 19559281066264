/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchMembers } from "services/chat.services";
import { QueryKeys } from "utils/QueryKeys";

export interface IGroupMember {
  id: number;
  companyId: number;
  groupId: number;
  memberId: number;
  memberName: string;
  groupName: string;
  signalRConnectionId: string;
  status: string;
  createdOn: string;
  userProfileUrl?: string;
}
export interface IResponseGroupMembers {
  data: IGroupMember[];
  totalDocs: number;
  statusCode: number;
}

async function getMembers(groupId: number): Promise<IResponseGroupMembers> {
  try {
    const response: IResponseGroupMembers = await fetchMembers(groupId);
    if (response.statusCode === 200) {
      return response;
    }
    return {
      statusCode: 404,
      totalDocs: 0,
      data: [],
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchMembers = (groupId: number, enabled = false) => {
  const cacheKey = [QueryKeys.groupMembers, groupId];

  return useQuery(
    cacheKey,
    () => {
      return getMembers(groupId);
    },
    {
      enabled,
    },
  );
};

export { useFetchMembers };
