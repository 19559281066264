import { ReactNode } from "react";
import "./CallRinging.css";

interface IProps {
  children: ReactNode;
}

function VideoCallRinging(props: IProps) {
  const { children } = props;
  return (
    <div className="call-ringing-animation">
      <div className="ring-1" />
      <div className="ring-2" />
      <div className="ring-3" />
      <div className="ring-4" />
      <div className="ring-5" />
      <div className="phone-icon">
        {/* <CallIcon color="success" /> */}
        {children}
      </div>
    </div>
  );
}

export default VideoCallRinging;
