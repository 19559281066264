/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "redux/store";

type IASIds = number[];

export interface ICustomersReducers {
  selectedCustomers?: number[];
}

const initialState: ICustomersReducers = {
  selectedCustomers: [],
};

interface IPayload {
  selectedCustomers?: any;
}

interface IAction {
  payload: any;
  type: string;
}

const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setSelectedCustomer: (state, action: IAction) => {
      state.selectedCustomers = action.payload.selectedCustomers;
    },

    setAllCustomers: (
      state,
      action: PayloadAction<{ checked: boolean; selectedCustomers: number[] }>,
    ) => {
      const { checked, selectedCustomers } = action.payload;
      if (checked) {
        state.selectedCustomers = selectedCustomers;
      } else {
        state.selectedCustomers = [];
      }
    },

    resetCustomer: (state) => {
      state.selectedCustomers = [];
    },
  },
});

const customerStates = (state: RootState) => state.customers;

export const selectCustomers = createSelector(
  [customerStates],
  (state) => state.selectedCustomers || [],
);

export const { setSelectedCustomer, resetCustomer, setAllCustomers } =
  customerSlice.actions;

export const useCustomersActions = () => {
  const dispatch = useAppDispatch();
  return {
    setSelectedCustomer: (payload: IPayload) =>
      dispatch(setSelectedCustomer(payload)),
    setAllCustomers: (payload: any) => dispatch(setAllCustomers(payload)),

    resetCustomer: () => dispatch(resetCustomer()),
  };
};
const { reducer } = customerSlice;

export default reducer;
