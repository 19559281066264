import { ATTACHMENT_URL } from "config";
import useDecodedData from "hooks/useDecodedData";
import { IUserState } from "redux/user/auth";

export interface IChatProfile {
  userId?: number;
  userProfileUrl?: string;
  fromUser?: number;
  fromProfilePic?: string;
  userName?: string;
  fromUserName?: string;
}

const useChatMessageUtils = () => {
  const decoded = useDecodedData();

  const getProfileUrl = (chat: IChatProfile, user: IUserState): string => {
    if (chat?.userId && chat?.userProfileUrl) {
      return `${ATTACHMENT_URL}${chat?.userProfileUrl}`;
    }

    if (chat?.fromUser === Number(decoded.id)) {
      return user.profilePic || "";
    }

    return `${ATTACHMENT_URL}${chat?.fromProfilePic}`;
  };

  const getUserName = (chat: IChatProfile): string => {
    if (chat?.userId) {
      return chat?.userName || "";
    }

    if (chat?.fromUser === Number(decoded.id)) {
      return decoded.FullName || "";
    }

    return chat?.fromUserName || "";
  };
  const editMessageString = (msg: string) => {
    let formattedMessage = msg;
    formattedMessage = formattedMessage.replace(/<br\s*\/?>/gi, "\n");
    const mentionRegex = /@\[([^\]]+)\]\(userId:(\d+)\)/g;
    if (formattedMessage.includes("@")) {
      formattedMessage = formattedMessage.replace(
        mentionRegex,
        (_, username, userId) => {
          return `<span className="react-input-emoji--mention--text" data-mention-id="${userId}" data-mention-name="${username}">@${username}</span>`;
        },
      );
    }
    return formattedMessage;
  };

  return {
    getProfileUrl,
    getUserName,
    editMessageString,
  };
};

export default useChatMessageUtils;
