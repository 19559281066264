/**
 * @format
 */
import { useQuery } from "react-query";
import { getAllReadChatInfo } from "services/chat.services";
import { QueryKeys } from "utils/QueryKeys";

export interface IReadChatInfo {
  userId: number;
  userName: string;
  readDateTime: string;
  userProfileUrl: string;
}

export interface IResponseReadChatInfo {
  data: IReadChatInfo[] | null;
  totalCount: number;
  statusCode: number;
}

async function getReadLater(chatId: number): Promise<IResponseReadChatInfo> {
  try {
    const response: IResponseReadChatInfo = await getAllReadChatInfo(chatId);
    if (response.statusCode === 200) {
      return response;
    }
    return {
      data: null,
      statusCode: 404,
      totalCount: 0,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchAllReadChatInfo = (
  chatId: number,

  enabled = false,
) => {
  const cacheKey = [QueryKeys.chatReadInfo, chatId];

  return useQuery(
    cacheKey,
    () => {
      return getReadLater(chatId);
    },
    {
      enabled,
    },
  );
};

export { useFetchAllReadChatInfo };
