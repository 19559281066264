import { createSlice } from "@reduxjs/toolkit";

export interface IProps {
  selectedItemDetails: any;
}

const initialState: IProps = {
  selectedItemDetails: null,
};

const emailDetailsSlice = createSlice({
  name: "emailItem",
  initialState,
  reducers: {
    setEmailDetails: (state, action) => {
      const newState = state;
      newState.selectedItemDetails = action.payload;
    },
  },
});

const { reducer, actions } = emailDetailsSlice;

export const { setEmailDetails } = actions;
export default reducer;
