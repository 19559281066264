import { Stack, Typography } from "@mui/material";

interface IProps {
  date: string;
}

function ChatDate({ date }: IProps) {
  let newDate: Date | string = new Date(date);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  if (newDate && newDate?.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0)) {
    newDate = "Today";
  } else if (
    newDate &&
    newDate?.setHours(0, 0, 0, 0) === yesterday?.setHours(0, 0, 0, 0)
  ) {
    newDate = "Yesterday";
  } else {
    newDate = newDate.toLocaleDateString(undefined, options);
  }

  return (
    <Stack
      alignItems="center"
      className="chat-date"
      paddingBottom="1rem"
      sx={{
        textAlign: "center",

        color: "#fff",

        opacity: "0.6",
      }}
    >
      <Typography
        sx={{
          background: "#0066b8",
          padding: "4px 8px",
          borderRadius: "25px",
          fontSize: "11px",
          fontWeight: 500,
        }}
      >
        {newDate}
      </Typography>
    </Stack>
  );
}

export default ChatDate;
