/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface INote {
  note: string;
}

const initialState: INote = {
  note: "",
};

interface IPayload {
  note: string;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const callNoteSlice = createSlice({
  name: "callNote",
  initialState,
  reducers: {
    setCallNote: (state, action: IAction) => {
      state.note = action.payload.note;
    },
  },
});

export const { setCallNote } = callNoteSlice.actions;

export const useCallNoteSliceActions = () => {
  const dispatch = useAppDispatch();
  return {
    setCallNote: (payload: IPayload) => dispatch(setCallNote(payload)),
  };
};
const { reducer } = callNoteSlice;
export default reducer;
