// /**
//  * @format
//  */

import useDecodedData, { IDecodedData } from "hooks/useDecodedData";
import { useContext, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { getChatRoomPagination } from "services/chat.services";
import { SocketContext } from "SocketContex";
import { QueryKeys } from "utils/QueryKeys";

export interface IChatRoom {
  id: number;
  companyId: number;
  isGroup: boolean;
  isBroadCast: boolean;
  groupName: string;

  userId: number;
  toUser: number;
  fromUser: number;
  toUserName: string;
  fromUserName: string;
  toUserConnectionId: string;
  fromUserConnectionId: string;
  lastMessageOn: string;
  status: string;
  message: string;
  createdOn: string;
  updatedOn: string;
  unReadMessagesCount: number;
  isPinned: boolean;
  readLater?: boolean;
  fromProfileUrl: string;
  toProfileUrl: string;
  toStatus?: string;
  fromStatus?: string;
  userName?: string;
  userProfileUrl?: string;
  profileUrl?: string;
  fullName?: string;
  readLaterCount: number;
  lastMessageUserId: number;
  lastMessageUserName: string;
  type: string;
  contactName: string;
  contactId: number;
  isMentioned?: boolean;
}

export interface IResponseChatRooms {
  data: IChatRoom[];
  totalDocs: number;
  statusCode: number;
  pageNo: number;
}

export async function getAllChatRoomPagination(
  userId: number,
  pageNo: number,
  pageLimit: number,
  search: string,
): Promise<IResponseChatRooms> {
  try {
    const response: IResponseChatRooms = await getChatRoomPagination(
      userId,
      pageNo,
      pageLimit,
      search,
    );
    if (response.statusCode === 200) {
      return response;
    }
    return {
      data: [],
      statusCode: 404,
      totalDocs: 0,
      pageNo: 0,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}
export const CHATROOM_PAGE_LIMIT = 15;

const useFetchChatRoom = (search: string, pageNumber = 1, enabled = true) => {
  const decode: IDecodedData = useDecodedData();
  const { chatRooms } = useContext(SocketContext);
  const cacheKey = [QueryKeys.allChatRoomPagination];
  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
    isSuccess,
    isFetchedAfterMount,
    isRefetching,
  } = useInfiniteQuery(
    cacheKey,
    (params) => {
      const { pageParam = pageNumber } = params;
      return getAllChatRoomPagination(
        Number(decode?.id),
        pageParam,
        CHATROOM_PAGE_LIMIT,
        search,
      );
    },
    {
      enabled,
      // keepPreviousData: true,

      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.pageNo + 1;
        if (lastPage.data?.length > 0) {
          return nextPage;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const previousPage = firstPage.pageNo > 1 ? firstPage.pageNo - 1 : 1;
        if (firstPage.data?.length > 0) {
          return previousPage;
        }
        return undefined;
      },
    },
  );

  const removeDuplicateRoom = (arr: IChatRoom[]): IChatRoom[] => {
    const uniqueIds = new Set<number>();
    return arr.filter((message) => {
      if (uniqueIds.has(message.id)) {
        return false;
      }
      uniqueIds.add(message.id);
      return true;
    });
  };

  // Removing duplicates from the messages array

  const chatRoomList = useMemo(() => {
    if (!data) return [];
    const allData = data?.pages?.flatMap((page) => page.data);
    const unique = allData ? removeDuplicateRoom(allData) : [];
    const newUnique = unique?.map((item: IChatRoom) => {
      const isExist = chatRooms.find((x) => x.id === item.id);
      if (isExist) {
        return { ...item, unReadMessagesCount: isExist.unReadMessagesCount };
      }
      return item;
    });
    return newUnique;
  }, [data]);

  return {
    data,
    chatRoomList,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
    isSuccess,
    isFetchedAfterMount,
    isRefetching,
  };
};

export { useFetchChatRoom };

