import { SocketContext } from "SocketContex";
import { useSnackbar } from "components/snackbar";
import { IResponse } from "constants/interfaces";
import useDecodedData from "hooks/useDecodedData";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { useChatsActions } from "redux/chats/chats";
import {
  assignChatToContact,
  chatMessageEdit,
  createGroup,
  deleteChatMessageId,
  deleteChatRoom,
  fetchRoomId,
  pinChatRoom,
  readChatById,
  sendChatMessage,
  unassignChatToContact,
  unPinChatRoom,
  unreadMarked,
  updateChatAsRead,
} from "services/chat.services";
import {
  fetchRoomDetails,
  fetchUserBId,
  postWhoIsTyping,
  updateCallMessage,
} from "services/team.service";
import {
  updateChatRoomDeleteChatRoom,
  updateChatRoomOnReadLater,
  updateChatRoomPinUnPined,
  UpdateChatUnreadCache,
} from "utils";
import { QueryKeys } from "utils/QueryKeys";

export interface IChatRequest {
  selectedChatRoomId?: number | string;
  toUser?: number;
  fromUser?: number;
  message?: string;
  timeStamp?: string;
  files?: string[];
  fileType?: string;
  fileUrl?: string;
  fileName?: string;
  quoteMessageId?: number;
  isPinned?: boolean;
  isGroup?: boolean;
}

export interface ICreateGroupRequest {
  id?: number;
  userId?: number;
  name?: string;
  timeStamp?: string | number;
  members?: number[];
  file?: string;
  uuId?: string;
  ChatRoomUuId?: string;
  contactId?: number;
}

export interface IChatMessage {
  id?: number;
  roomId?: number;
  message?: string;
}

const useApiActions = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const decode = useDecodedData();
  const { chatRooms, setChatRooms } = useContext(SocketContext);
  const { setSelectedChatRoom } = useChatsActions();
  const trySendChatMessage = async (
    values: FormData,
    roomId: number,
    handleUploadProgress?: (progressEvent: any) => void,
    isGroup?: boolean,
  ) => {
    try {
      const response: IResponse = await sendChatMessage(
        values,
        handleUploadProgress,
        isGroup,
      );

      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };
  const tryMarkUnread = async (params: {
    messageId: number;
    roomId: number;
    value: boolean;
  }) => {
    try {
      const response: IResponse = await unreadMarked(
        params?.messageId,
        params?.roomId,
      );
      if (response.statusCode === 200) {
        UpdateChatUnreadCache(params?.messageId, params?.roomId, queryClient);
        updateChatRoomOnReadLater(
          chatRooms,
          setChatRooms,
          params?.roomId,
          response.data,
          setSelectedChatRoom,
        );
        // queryClient.invalidateQueries([
        //   QueryKeys.readLaterGetAll,
        //   params?.roomId,
        // ]);
        // queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const getRoomId = async (toUser: number) => {
    try {
      const response: IResponse = await fetchRoomId(toUser, Number(decode?.id));
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const tryCreateGroup = async (id: number, body: ICreateGroupRequest) => {
    try {
      const response: IResponse = await createGroup(id, body);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.allChats]);
        queryClient.prefetchQuery([QueryKeys.allChats]);
        queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
        queryClient.prefetchQuery([QueryKeys.allChatRoomPagination]);

        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      } else {
        snackbar?.show({
          title: response?.message,
          type: "error",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return 500;
    }
  };

  const deleteChatMessage = async (id?: number | undefined) => {
    try {
      const response: IResponse = await deleteChatMessageId(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.allChats]);
        queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
        queryClient.prefetchQuery([QueryKeys.allChats]);

        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };
  const tryDeleteChatRoom = async (id?: number | undefined) => {
    try {
      const response: IResponse = await deleteChatRoom(id);
      if (response.statusCode === 200) {
        updateChatRoomDeleteChatRoom(chatRooms, setChatRooms, id || 0);
        // queryClient.invalidateQueries([QueryKeys.allChats]);
        // queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
        // queryClient.prefetchQuery([QueryKeys.allChats]);

        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };
  const editChatMessage = async (body: IChatMessage) => {
    try {
      const response: IResponse = await chatMessageEdit(body);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getAllFolderData]);
        queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
        queryClient.invalidateQueries([QueryKeys.allChats, body?.roomId]);
        queryClient.prefetchQuery([QueryKeys.allChats, body?.roomId]);

        // snackbar?.show({
        //   title: response?.message,
        //   type: "success",
        // });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };
  const tryPinChat = async (chatId: number) => {
    try {
      const response: IResponse = await pinChatRoom(chatId);
      if (response.statusCode === 200) {
        updateChatRoomPinUnPined(chatRooms, setChatRooms, chatId, true);
        // queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
        // queryClient.prefetchQuery([QueryKeys.allChatRoomPagination])
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };
  const tryUnPinChat = async (chatId: number) => {
    try {
      const response: IResponse = await unPinChatRoom(chatId);
      if (response.statusCode === 200) {
        updateChatRoomPinUnPined(chatRooms, setChatRooms, chatId, false);
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };

  const getUserById = async (userId: number) => {
    try {
      const response: any = await fetchUserBId(userId);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };
  const getRoomDetails = async (roomId: number) => {
    try {
      const response: any = await fetchRoomDetails(roomId);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };

  const tryWhoIsTyping = async (data: any) => {
    try {
      const response: any = await postWhoIsTyping(data);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };

  const tryUpdateCallMessage = async (data: any) => {
    try {
      const response: any = await updateCallMessage(data);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };

  const tryUpdateChatAsRead = async (chatId: any) => {
    try {
      const response: any = await updateChatAsRead(chatId);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };

  const tryReadChatById = async (chatId: number) => {
    try {
      const response: any = await readChatById(chatId);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };

  const tryAssignChatToContact = async (roomId: number, contactId: number) => {
    try {
      const response: any = await assignChatToContact(roomId, contactId);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };
  const tryUnassignChatToContact = async (
    roomId: number,
    contactId: number,
  ) => {
    try {
      const response: any = await unassignChatToContact(roomId, contactId);
      if (response.statusCode === 200) {
        return response;
      }
      return {
        statusCode: 404,
      };
    } catch (error: any) {
      const msg = error?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return error;
    }
  };

  return {
    tryPinChat,
    tryUnPinChat,
    tryDeleteChatRoom,
    trySendChatMessage,
    getRoomId,
    tryMarkUnread,
    tryCreateGroup,
    deleteChatMessage,
    editChatMessage,
    getUserById,
    getRoomDetails,
    tryWhoIsTyping,
    tryUpdateCallMessage,
    tryUpdateChatAsRead,
    tryReadChatById,
    tryAssignChatToContact,
    tryUnassignChatToContact,
  };
};

export { useApiActions };

