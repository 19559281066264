import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function ChatRoomLoader(props: Props) {
  return (
    <Box {...props}>
      <Box
        sx={{
          marginTop: 2,
          padding: 1,
          display: "flex",
          borderRadius: 1,
          border: 1,
          borderColor: "#e5e5e5",
        }}
      >
        <Stack
          sx={{
            marginRight: 1,
            justifyContent: "center",
          }}
        >
          <Skeleton
            animation="wave"
            height={40}
            variant="circular"
            width={40}
          />
        </Stack>
        <Stack sx={{ justifyContent: "center", width: "100%" }}>
          <Skeleton
            animation="wave"
            height={10}
            sx={{ textAlign: "right", borderRadius: 0.2 }}
            variant="rectangular"
            width="100%"
          />
          <Skeleton
            animation="wave"
            height={10}
            sx={{ marginY: 1, textAlign: "right", borderRadius: 0.2 }}
            variant="rectangular"
            width="100%"
          />
          <Skeleton
            animation="wave"
            height={10}
            sx={{ textAlign: "right", borderRadius: 0.2 }}
            variant="rectangular"
            width="100%"
          />
        </Stack>
      </Box>
    </Box>
  );
}

export { ChatRoomLoader };
