import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function ContactLogLoader(props: Props) {
  return (
    <Box {...props}>
      <Box sx={{ width: "100%" }}>
        <Skeleton
          animation="wave"
          height={60}
          sx={{ marginBottom: 5 }}
          variant="rectangular"
          width="100%"
        />
      </Box>
    </Box>
  );
}

export { ContactLogLoader };
