/* eslint-disable no-useless-escape */
/* eslint-disable react/no-array-index-key */
import { Typography } from "@mui/material";
import useDecodedData from "hooks/useDecodedData";

interface IProps {
  id: number;
  chatStatus?: any;
  fromUser?: number;
  message: string;
  isMentioned?: boolean;
}
function MessageBox(props: IProps) {
  const { chatStatus, id, message, fromUser } = props;
  const decoded = useDecodedData();

  const lineBreak = (text: string) => {
    let formattedString = text;

    // Regex for matching user mentions
    const mentionRegex = /(?:\s@\[([^\]]+)\]\(userId:(\d+)\))/g;
    // const mentionRegex =
    //   /(?:\s@\[([^\]]+)\]\(userId:(\d+)\)|\s@(\w+(?:\s+\w+)?)|^@(\w+(?:\s+\w+)?)|@\[([^\]]+)\]\(userId:\d+\))/g;

    // Replace user mentions with <strong> tags
    formattedString = formattedString.replace(
      mentionRegex,
      (match, username, userId, justName, fullMention) => {
        // Check if it's a user mention or a name starting with "@"
        if (username) {
          return ` <strong>${username}</strong>`;
        }
        if (justName) {
          return ` <strong>${justName}</strong>`;
        }
        if (fullMention) {
          return ` <strong>${fullMention}</strong>`;
        }
        return match; // Return unchanged if not a match
      },
    );

    // Regex for matching if the string starts with @[Sajid super](userId:29) and format
    const startWithMentionRegex = /@\[([^\]]+)\]\(userId:\d+\)/;
    // const startWithMentionRegex = /^@\[([^\]]+)\]\(userId:\d+\)/;

    // Format if the string starts with @[Sajid super](userId:29)
    if (startWithMentionRegex.test(formattedString)) {
      formattedString = formattedString.replace(
        startWithMentionRegex,
        (match, username) => {
          return `<strong>${username}</strong>`;
        },
      );
    }

    // Regex for matching links
    const linkRegex = /(https?:\/\/[^\s]+)/g;

    // Replace links with <a> tags
    formattedString = formattedString.replace(linkRegex, (match) => {
      return ` <a href="${match}" target="_blank">${match}</a>`;
    });

    return formattedString;
  };

  const isDeleted = chatStatus === "Deleted";

  return (
    <Typography
      sx={{
        fontSize: {
          xs: "0.8rem",
          midlg: "0.88rem",
          xl: "14px",
          maxWidth: "500px",
          color:
            fromUser === Number(decoded?.id) && isDeleted && id
              ? "#555555"
              : "black",
        },
        color: "inherit",
        fontStyle: isDeleted ? "italic" : "normal",
        opacity: isDeleted ? "0.6" : "1",
      }}
    >
      {isDeleted && id ? (
        "This message has been deleted. "
      ) : (
        <Typography
          dangerouslySetInnerHTML={{
            __html: lineBreak(message) || "",
          }}
          sx={{ whiteSpace: "pre-wrap" }}
        />
      )}
    </Typography>
  );
}

export default MessageBox;
