/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchUsers } from "services/team.service";
import { QueryKeys } from "utils/QueryKeys";

export interface IUser {
  id: number;
  companyId: number;
  roleId: number;
  contactId: number;
  roleName: string;
  fullName: string;
  address: string;
  email: string;
  password: string;
  mobileNumber: string;
  status: string;
  createdOn: string;
  updatedOn: string;
  lastLoginTime: string;
  lastLogoutTime: string;
  isEmail: boolean;
  isSms: boolean;
  isRecording: boolean;
  isTranscription: boolean;
  faceName: string;
  profileImage?: string;
}

export interface IResponseUser {
  data: IUser[];
  totalDocs: number;
  limit: number;
  statusCode: number;
}

async function getUsers(
  pageNo: number,
  pageLimit = 10,
  search?: string,
  roleId?: number,
): Promise<IResponseUser | undefined> {
  try {
    const response: IResponseUser = await fetchUsers(
      pageNo,
      pageLimit,
      search,
      roleId,
    );
    if (response.statusCode === 200) {
      return response;
    }
    return {
      data: [],
      totalDocs: 0,
      limit: 10,
      statusCode: 500,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchUsers = (
  pageNo: number,
  pageLimit = 10,
  search?: string,
  roleId?: number,
  enabled = true,
) => {
  const cacheKey = [QueryKeys.users];
  return useQuery(
    cacheKey,
    () => {
      return getUsers(pageNo, pageLimit, search, roleId);
    },
    {
      enabled,
      // staleTime: Infinity,
    },
  );
};

export { useFetchUsers };
