import { Box, Typography } from "@mui/material";

interface IProps {
  whoIs?: string;
  subTitle: string;
  fileIcon: JSX.Element;
  textColor?: string;
  // id: number;
  // chatStatus: any;
}

function FileTypes(props: IProps) {
  const { whoIs, subTitle, fileIcon, textColor } = props;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "0.8rem",
              midlg: "0.88rem",
              xl: "16px",
              maxWidth: "500px",
            },
            marginLeft: 0.5,
            color: textColor || "#546376",
          }}
        >
          {whoIs}
        </Typography>
        {fileIcon}
        {/* {chatStatus === 3 && id ? (
        "This message has been deleted."
      ) : ( */}
        <Typography
          sx={{
            fontSize: {
              xs: "0.8rem",
              midlg: "0.88rem",
              xl: "14px",
              maxWidth: "500px",
            },
            marginLeft: 0.5,
            color: textColor || "#546376",
          }}
        >
          {subTitle}
        </Typography>
      </Box>
    </>
  );
}

export default FileTypes;
