import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { SocketContext } from "SocketContex";
import Slider from "components/layouts/popup-modals/Slider";
import AppRoutes from "navigation/appRoutes";
import { useFetchDisposition } from "pages/admin/settings/screens/global-disposition/query/useFetchDisposition";
import { useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useCallManagerActions } from "redux/call-manager/callManager";
import { useCallNoteSliceActions } from "redux/call-note/callNote";
import { preferenceList } from "redux/dashboard/dashboard";
import { RootState } from "redux/store";
import { triggerRefetch } from "utils";
import { QueryKeys } from "utils/QueryKeys";
import useCallManager from "../hook/useCallManager";
import { useAPIActions } from "../query/useApiActions";

interface IDispositionList {
  open: boolean;
  callCampaignID: number;
  handleClose: (remove?: boolean) => void;
  handleHangup?: () => void;
  handleCloseCallPopup: () => void;
}

function CreateDispositionListSlider(props: IDispositionList) {
  const {
    handleClose,
    open,
    handleCloseCallPopup,
    callCampaignID,
    handleHangup,
  } = props;
  const [selectedStatus, setSelectedStatus] = useState(0);
  const { resetCallInfo } = useCallManagerActions();
  const { tryUpdateDispositionSid, tryUpdateCallNote, tryUpdateCallNoteById } =
    useAPIActions();

  const { setCallNote } = useCallNoteSliceActions();
  const [isSubmitting, setSubmitting] = useState(false);
  const callManagerData = useCallManager();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { handleOutGoingCall, refetchBunch, setVoiceCallMiniPopup } =
    useContext(SocketContext);
  const [searchTerm, setSearchTerm] = useState("");
  const { data: getAllDisposition, isLoading: isDispositionLoading } =
    useFetchDisposition(callCampaignID, 0, 10000);

  const params = useParams();
  const getPreferenceList = useSelector(preferenceList);
  const getCallNote = useSelector((state: RootState) => state.callNote.note);

  const isAuto = useMemo(
    () =>
      getPreferenceList?.find(
        (item: any) => item.preferenceName === "autoDial",
      ),
    [getPreferenceList],
  );
  const handleSelectStatus = (id: number) => {
    setSelectedStatus(id);

    onSubmit(id);
  };
  const onSubmit = async (statusId: any) => {
    const activity = params.activityId;
    const paramsUrl = params.url;
    const id = params.contactId;

    setSubmitting(true);
    await tryUpdateDispositionSid({
      confId: callManagerData?.callInfo?.confId,
      status: statusId,
    });
    if (getCallNote) {
      await tryUpdateCallNoteById({
        confId: callManagerData?.callInfo?.confId,
        note: getCallNote,
      });
    }
    await handleAction();
    refetchBunch?.customerTableRefetch?.();
    handleCloseCallPopup();
    handleClose();
    resetCallInfo();
    setCallNote({ note: "" });
    setSubmitting(false);
    const cacheKey = [QueryKeys.contacts, Number(id)];
    const contactData: any = await queryClient.getQueryData(cacheKey);

    if (contactData && params.contactId) {
      const { nextPrev } = contactData;

      if (isAuto?.preferences === "true") {
        let route = `${AppRoutes.CONTACTS_DETAILS}/${nextPrev.next.id}`;
        if (activity) {
          route += `/${activity}`;
        }
        if (paramsUrl) {
          route += `/${encodeURIComponent(paramsUrl)}`;
        }
        navigate(route);
        const phone = nextPrev?.next?.mobile || nextPrev?.next?.phone || "";
        handleOutGoingCall(phone, nextPrev?.next.id, "Contact");
      }
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDispositions = getAllDisposition?.data?.filter((item: any) => {
    const words = searchTerm.toLowerCase().split(" ");
    return words.some(
      (word) =>
        item?.statusCode.toLowerCase().includes(word) ||
        item?.description.toLowerCase().includes(word),
    );
  });

  // CallLog Table Api refetch
  const handleAction = () => {
    triggerRefetch();
  };

  useEffect(() => {
    if (open) {
      setVoiceCallMiniPopup?.(false);
    }
  }, [open, setVoiceCallMiniPopup]);

  return (
    <div>
      <Slider
        backgroundColor="#fff"
        open={open}
        size="xs"
        onClose={handleClose}
      >
        <Stack alignItems="center" direction="row" gap={1} paddingRight="13px">
          <DialogTitle>Disposition Status</DialogTitle>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            // label="Search"
            placeholder="Search..."
            size="small"
            style={{ borderRadius: "400px" }}
            type="search"
            value={searchTerm}
            variant="outlined"
            onChange={handleSearchChange}
          />
        </Stack>

        <DialogContent dividers>
          <Box>
            {filteredDispositions?.map((item: any) => {
              return (
                <Box>
                  <Box
                    sx={{
                      marginTop: "5px",
                      backgroundColor:
                        item.id === selectedStatus ? "#F6F7FB" : "transparent",
                    }}
                  >
                    <Box
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                      }}
                      // onClick={() => handleSelectStatus(item.id)}
                    >
                      <Checkbox
                        checked={item.id === selectedStatus}
                        color="primary"
                        onChange={() => handleSelectStatus(item.id)}
                      />
                      <Typography>
                        <Typography component="span" sx={{ color: "blue" }}>
                          {item?.statusCode}
                        </Typography>{" "}
                        - {item?.description}
                      </Typography>

                      {isSubmitting && item.id === selectedStatus && (
                        <CircularProgress size={18} />
                      )}
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            paddingTop="10px"
            spacing={2}
          >
            <Button
              autoFocus
              color="error"
              style={{ padding: "0.5rem 9px" }}
              variant="contained"
              onClick={() => handleClose()}
            >
              More Time
            </Button>
            {/*  <Button
              //   startIcon={<AddIcon />}
              style={{ padding: "0.5rem 9px" }}
              variant="contained"
              onClick={handleHangup}
            >
              Hangup again
            </Button> */}
            {/* <Button
              //   startIcon={<AddIcon />}
              style={{ padding: "0.5rem 9px" }}
              variant="contained"
              onClick={() => onSubmit()}
            >
              {isSubmitting ? <CircularProgress size={16} /> : "Submit"}
            </Button> */}
          </Stack>
        </DialogContent>
      </Slider>
    </div>
  );
}

export default CreateDispositionListSlider;
