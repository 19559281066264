import { Box, Stack, Typography } from "@mui/material";
import useDecodedData from "hooks/useDecodedData";
import { IChat } from "pages/user/inbox/query/useFetchChats";
import React from "react";
import MessageBox from "../message-box";
import MessageMedia from "../message-media";

type ChatQuoteProps = {
  chat: IChat;
  handleMediaPopup: (fileUrl: string, chat?: any) => void;
  classes?: any;
};

function ChatQuoteMessage(props: ChatQuoteProps) {
  const { chat, handleMediaPopup, classes } = props;
  const decoded = useDecodedData();
  if (!chat?.quoteMessage || chat?.status === "Deleted") return null;
  const isFromCurrentUser =
    chat?.quoteMessage?.fromUser === Number(decoded?.id);
  const className = isFromCurrentUser ? "sendQuoteMsg" : "receivedQuoteMsg";
  const fromUser = isFromCurrentUser ? "You" : chat.quoteMessage?.fromUserName;

  return (
    <Box
      className={`${classes.quoteMsg} ${classes[className]}`}
      sx={{ color: "#333" }}
    >
      <Typography
        sx={{
          paddingTop: "2px",
          fontSize: "11px",
          fontWeight: 500,
          color: "#b04a4a",
          lineHeight: 1,
        }}
      >
        {fromUser}
      </Typography>
      <FileStack chat={chat} handleMediaPopup={handleMediaPopup} />
      <MessageBox
        chatStatus={chat?.status}
        fromUser={chat?.quoteMessage?.fromUser}
        id={chat?.quoteMessage?.quoteMessageId}
        message={chat?.quoteMessage?.message}
      />
    </Box>
  );
}

type FileStackProps = {
  chat: IChat;
  handleMediaPopup: (fileUrl: string, chat?: any) => void;
};

function FileStack(props: FileStackProps) {
  const { chat, handleMediaPopup } = props;
  if (chat?.status === "Deleted" || !chat?.quoteMessage?.fileUrls) return null;

  return (
    <Stack gap={1}>
      {chat?.quoteMessage?.fileUrls?.map((file: string) => (
        <MessageMedia
          key={file}
          chat={chat}
          file={file}
          handleMediaPopup={handleMediaPopup}
        />
      ))}
    </Stack>
  );
}

export default React.memo(ChatQuoteMessage);
