/* eslint-disable @typescript-eslint/no-unused-vars */
import CallEndIcon from "@mui/icons-material/CallEnd";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import Imagespath from "assets/images";
import { CallType } from "constants/interfaces";

interface IOutGoingCallRinging {
  handleOutGoingCallHangup: (type: CallType, leftConference: boolean) => void;
}
function OutGoingCallRinging(props: IOutGoingCallRinging) {
  const { handleOutGoingCallHangup } = props;
  return (
    <Box
      sx={{
        backgroundColor: "#414143",
        borderRadius: 2,
        margin: 3,
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <img
        alt="personalIcon"
        loading="lazy"
        src={Imagespath.personalIcon}
        style={{ width: 300, height: 300, margin: "auto" }}
      />
      <Typography color="#ffffff" textAlign="center">
        Connecting . . .
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          marginY: "auto",
          marginX: 30,
        }}
      >
        <IconButton
          onClick={() => handleOutGoingCallHangup("end-outgoing", false)}
        >
          <Avatar sx={{ bgcolor: "#FF0000", color: "#ffffff", padding: 4 }}>
            <CallEndIcon />
          </Avatar>
        </IconButton>
      </Box>
    </Box>
  );
}

export default OutGoingCallRinging;
