/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

const initialState: any = {
  permissions: [],
  userSpecificPermissions: [],
};

const accessPermissionsSlice = createSlice({
  name: "accessPermissions",
  initialState,
  reducers: {
    setPermissions: (state, action: any) => {
      state.permissions = action.payload.permissions;
    },
    setUserSpecificPermissions: (state, action: any) => {
      state.userSpecificPermissions = action.payload.userSpecificPermissions;
    },
    setScreenPermissions: (state, action: any) => {
      if (state.permissions[action.payload.screenCode]) {
        state.permissions[action.payload.screenCode] =
          action.payload.permissions;
      }
    },
  },
});

export const {
  setPermissions,
  setUserSpecificPermissions,
  setScreenPermissions,
} = accessPermissionsSlice.actions;

export const useAccessPermissionsActions = () => {
  const dispatch = useAppDispatch();
  return {
    setPermissions: (payload: any) => dispatch(setPermissions(payload)),
    setScreenPermissions: (payload: any) =>
      dispatch(setScreenPermissions(payload)),
    setUserSpecificPermissions: (payload: any) =>
      dispatch(setUserSpecificPermissions(payload)),
  };
};
const { reducer } = accessPermissionsSlice;

export default reducer;
