import CallEndIcon from "@mui/icons-material/CallEnd";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import MinimizeIcon from "@mui/icons-material/Minimize";
import PresentToAllIcon from "@mui/icons-material/PresentToAll";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import Stopwatch from "pages/call-manager/components/Stopwatch";
import { useContext } from "react";
import palette from "theme/palette";

import { getInitials } from "utils/get-initials";
import PlayVideo from "../play-video";

interface IVideoPopup {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  toCall?: string;
  cameraOn?: boolean;
  fromMinimize?: boolean;
}

export default function VideoPopup({
  fromMinimize,
  handleClose,
  toCall,
}: // cameraOn,
  IVideoPopup) {
  const {
    leaveCall,
    switchCameraOff,
    switchCamera,
    audioOn,
    setAudioOn,
    switchAudioOn,
    switchAudioOff,
    call,
    cameraOn,
    openCallScreen,
    setOpenCallScreen,
    me,
    callAccepted,
    setCallPopup,
    shareScreen,
    screenShareEnabled,
    onlineUserList,
  } = useContext(SocketContext);

  const isOnline: any = onlineUserList?.includes(call.toUserId);
  return (
    <div>
      <Box
        className="call-popup"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          height: openCallScreen ? "100%" : "1px",
          width: openCallScreen ? "100%" : "1px",
          zIndex: openCallScreen ? 99999 : -1,
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#f7f7f7",
            // backgroundColor: "red",
            padding: "5px 16px",
            height: "8%",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginRight: "8px" }}>
              <Avatar>{getInitials(toCall || call.name)}</Avatar>
            </Box>
            <Typography sx={{ color: palette.common.black }}>
              {toCall || call.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
              color: palette.common.black,
            }}
            onClick={() => {
              handleClose();
              setCallPopup?.(true);
            }}
          >
            <MinimizeIcon />
          </Box>
        </Box>
        <Box sx={{ height: "82%" }}>
          <PlayVideo isCameraOn={cameraOn} />
        </Box>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#f7f7f7",
            padding: "5px 16px",
            height: "10%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "79px",
              // padding: "5px",
              position: "absolute",
              top: "72px",
              left: "60px",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isOnline ? "green" : "red",
              borderRadius: "5px",
              marginBottom: 5,
            }}
          >
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                fontWeight: 400,
                fontSize: "15px",
              }}
            >
              {onlineUserList?.includes(call.toUserId) ? "Online" : "Offline"}
            </Typography>
          </Box>

          <Stopwatch
            callStatus={callAccepted}
            countValue={0}
            pLeft="0px"
            stopwatchStatus={callAccepted}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
          >
            {call.isVideoCall &&
              (cameraOn ? (
                <IconButton
                  onClick={() => {
                    // setCameraOn(false);
                    switchCameraOff(
                      me === call.userToCall
                        ? call.from
                        : call.userToCall || "",
                    );
                  }}
                >
                  <VideocamIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    // setCameraOn(true);
                    switchCamera(
                      me === call.userToCall
                        ? call.from
                        : call.userToCall || "",
                    );
                  }}
                >
                  <VideocamOffIcon />
                </IconButton>
              ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              cursor: "pointer",
            }}
          >
            {call.isVideoCall ? (
              <IconButton
                onClick={() => {
                  shareScreen(!screenShareEnabled);
                }}
              >
                <PresentToAllIcon
                  sx={{
                    color: screenShareEnabled
                      ? palette.info.main
                      : palette.common.black,
                  }}
                />
              </IconButton>
            ) : null}

            {audioOn ? (
              <IconButton
                onClick={() => {
                  setAudioOn(false);
                  switchAudioOff();
                }}
              >
                <MicIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={() => {
                  setAudioOn(true);
                  switchAudioOn();
                }}
              >
                <MicOffIcon />
              </IconButton>
            )}
            {/* <Typography sx={{ fontSize: "12px" }}>
              {audioOn ? "Turn Off Camera" : "Turn On Camera"}
            </Typography> */}
          </Box>
          <IconButton
            onClick={() => {
              handleClose();
              setOpenCallScreen?.(false);
              leaveCall(call?.from);
            }}
          >
            <CallEndIcon color="error" />
          </IconButton>
        </Box>
      </Box>
    </div>
  );
}
