/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IAgreementContendState {
  content: string;
  subject: string;
  contactDetail: any;
}

const initialState: IAgreementContendState = {
  content: "",
  subject: "",
  contactDetail: null,
};

interface IPayload {
  content: string;
  subject: string;
  contactDetail: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const agreementDownloadSlice = createSlice({
  name: "agreementDownload",
  initialState,
  reducers: {
    setAgreementContend: (state, action: IAction) => {
      state.subject = action.payload.subject;
      state.content = action.payload.content;
      state.contactDetail = action.payload.contactDetail;
    },
  },
});

export const { setAgreementContend } = agreementDownloadSlice.actions;

export const useAgreementDownloadActions = () => {
  const dispatch = useAppDispatch();
  return {
    setAgreementContend: (payload: IPayload) =>
      dispatch(setAgreementContend(payload)),
  };
};
const { reducer } = agreementDownloadSlice;
export default reducer;
