import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function ContactNotesLoader(props: Props) {
  return (
    <Box {...props}>
      <Box sx={{ alignItems: "center", display: "flex" }}>
        <Box sx={{ width: "100%" }}>
          <Skeleton
            animation="wave"
            height={70}
            sx={{ marginBottom: 1 }}
            variant="rectangular"
            width="100%"
          />
        </Box>
        <Box sx={{ marginLeft: 1 }}>
          <Skeleton
            animation="wave"
            height={50}
            variant="circular"
            width={50}
          />
        </Box>
      </Box>
    </Box>
  );
}

export { ContactNotesLoader };
