import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useChatUsersActions } from "redux/chat-users-list/chatUsersList";
import palette from "theme/palette";
import { formatTime } from "utils";

interface IProps {
  stopwatchStatus: any;
  callStatus: any;
  countValue?: number;
  pLeft?: string;
  fontSize?: string;
}
function Stopwatch(props: IProps) {
  const { stopwatchStatus, pLeft, fontSize = "12px", callStatus } = props;

  const { setCallDuration } = useChatUsersActions();
  const storage: any = useSelector((state: any) => state.chatUserList);
  useEffect(() => {
    let intervalId: any;

    if (stopwatchStatus) {
      intervalId = setInterval(() => {
        setCallDuration();
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [stopwatchStatus]);

  return (
    <Box sx={{ paddingLeft: pLeft || "15px" }}>
      <Typography sx={{ fontSize, fontWeight: "600" }}>
        {formatTime(storage?.callDuration)}
      </Typography>
      <Typography sx={{ fontSize: 11, color: palette.error.main }}>
        {callStatus}
      </Typography>
    </Box>
  );
}

export default Stopwatch;
