import jwtDecode from "jwt-decode";
import { store } from "redux/store";
import { RoleConstants } from "./permission.config";

interface IProps {
  children?: any;
  controlCode: any;
}
function UserControlPermissionGuard(props: IProps): any {
  const { children, controlCode } = props;
  const state: any = store.getState();
  const decodedToken: any = jwtDecode(state?.user?.token);

  if (RoleConstants?.superAdmin === decodedToken?.RoleName) {
    return children;
  }
  if (
    state?.accessPermissionsSlice?.userSpecificPermissions?.includes(
      controlCode,
    )
  ) {
    return children;
  }
  return null;
}
export default UserControlPermissionGuard;
