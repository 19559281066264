import { Box, Typography } from "@mui/material";
import palette from "theme/palette";

interface ILiveSuggestions {
  handleScroll: any;
  suggestionsWindowRef: any;
  suggestions: any;
}
function LiveSuggestions(props: ILiveSuggestions) {
  const { handleScroll, suggestionsWindowRef, suggestions } = props;
  return (
    <Box
      sx={{
        boxShadow: 10,
        borderRadius: 2,
        margin: 3,
        height: "50vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        ref={suggestionsWindowRef}
        sx={{
          overflowY: "scroll",
          padding: "16px",
          scrollBehavior: "smooth",
          backgroundColor: "transparent",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "5px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            background: "#555",
          },
        }}
        onScroll={handleScroll}
      >
        {suggestions?.map((recommendations: any) => (
          <>
            <Box
              sx={{
                margin: 2,
                bgcolor: palette.gray.light,
                padding: 1,
                boxShadow: 6,
              }}
            >
              {recommendations?.map((i: string) => {
                return (
                  <Typography
                    sx={{
                      marginLeft: 2,
                      display: "list-item",
                      fontSize: "13px",
                      color: palette.text.muted,
                    }}
                  >
                    {i}
                  </Typography>
                );
              })}
            </Box>
          </>
        ))}
      </Box>
    </Box>
  );
}

export default LiveSuggestions;
