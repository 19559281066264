/* eslint-disable no-plusplus */
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { SocketContext } from "SocketContex";
import ProgressBar from "components/progress-bar";
import useDecodedData from "hooks/useDecodedData";
import useUserInfo from "hooks/useUserInfo";
import { debounce } from "lodash";
import moment from "moment";
import { ISetImagePreview } from "pages/user/inbox/component/chats";
import InputBox from "pages/user/inbox/component/chats/components/input-box";
import MediaPreviewItem from "pages/user/inbox/component/chats/components/media-preview-item";
import QuoteMessagePreview from "pages/user/inbox/component/chats/components/qoute-message-preview";
import useAddChatForm from "pages/user/inbox/hook/useAddChatForm";
import { ChatInterface } from "pages/user/inbox/query/chat.interface";
import {
  IChatRequest,
  useApiActions,
} from "pages/user/inbox/query/useApiAction";
import { IChat } from "pages/user/inbox/query/useFetchChats";
import { IResponseGroupMembers } from "pages/user/inbox/query/useFetchMembers";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useChatsActions } from "redux/chats/chats";
import { RootState } from "redux/store";
import palette from "theme/palette";
import { UpdateChatInCache, getUuid } from "utils";
import { QueryKeys } from "utils/QueryKeys";

interface IProps {
  // room: IChatRoom;
  setQuotedMessageOpen: Dispatch<SetStateAction<boolean>>;
  setQuotedMessage: Dispatch<SetStateAction<IChat | null>>;
  quotedMessage: IChat | null;
  handleQuoteMessageClose: () => void;
  isDragging: boolean;
  setIsDragging: Dispatch<SetStateAction<boolean>>;
  quotedMessageOpen: boolean;
  scrollRef: React.RefObject<HTMLDivElement>;
  refetchChats: any;
  fetchPreviousPage?: any;
  hasPreviousPage?: any;
  groupMembers: any;
}

const initialValues: IChatRequest = {
  selectedChatRoomId: 0,
  toUser: 0,
  fromUser: 0,
  message: "",
  timeStamp: "",
  files: [],
  quoteMessageId: 0,
};

function MiniChatFooter(props: any) {
  const {
    setQuotedMessageOpen,
    quotedMessage,
    setIsDragging,
    quotedMessageOpen,
    handleQuoteMessageClose,
    isDragging,
    handleNewChat,
    groupMembers,
  } = props;
  const { setPageNumber } = useChatsActions();
  const [imageListData, setImageListData] = useState<File[]>([]);
  const chats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId, selectedChatRoom } = chats;
  const room = selectedChatRoom;

  const [previewList, setPreviewList] = useState<ISetImagePreview[]>([]);
  const decoded = useDecodedData();
  const [toUserData, setToUserData] = useState<any>(null);
  const { trySendChatMessage, getUserById, tryWhoIsTyping } = useApiActions();
  const { me, whoIsTyping, sendMessage, whoIsTypingEvent } =
    useContext(SocketContext);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const queryClient = useQueryClient();
  const groupMemberData = queryClient.getQueryData<IResponseGroupMembers>([
    QueryKeys.groupMembers,
    selectedChatRoom?.id,
  ]);

  const userData = useUserInfo();

  const memberIds = useMemo(
    () =>
      groupMemberData?.data
        ?.map((item) => item.memberId)
        ?.filter((x) => x !== Number(decoded?.id)),
    [groupMemberData],
  );

  const fetchUserDetail = async () => {
    const onlineUserId =
      Number(decoded?.id) === room.toUser ? room.fromUser : room.toUser;
    console.log("onlineUserId", onlineUserId);
    const toUser = await getUserById(onlineUserId);

    setToUserData(toUser?.data || null);
  };
  useEffect(() => {
    if (room) fetchUserDetail();
  }, [room]);

  useEffect(() => {
    if (selectedChatRoom) {
      handleQuoteMessageClose();
      setPreviewList([]);
    }
  }, [selectedChatRoom]);

  const handleUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadPercentage(percentCompleted);
  };

  const onSubmit = async (values: IChatRequest) => {
    const trimmedMessage = (values.message || "").trim();

    if (trimmedMessage === "" && previewList.length === 0) {
      return;
    }

    if (!selectedChatRoom) return false;
    formik.resetForm();
    setIsDragging(false);
    setImageListData([]);
    setPreviewList([]);
    handleQuoteMessageClose();
    const uuid = getUuid();
    const msgTimeStamp = String(new Date().getTime().toString());

    const newChat: ChatInterface = {
      id: 0,
      uuId: uuid,
      chatUuId: selectedChatRoom.id,
      companyId: Number(decoded?.CompanyId),
      createdOn: moment().format("YYYY-MM-DD[T]HH:mm"),
      lastMessageUserId: Number(decoded?.id),
      fromUser: Number(decoded?.id),
      message: values.message || "",
      fromUserName: decoded?.FullName,
      groupName: selectedChatRoom.groupName,
      roomId: selectedChatRoom.id,
      isBroadCast: selectedChatRoom?.isBroadCast,
      isGroup: selectedChatRoom?.isGroup,
      isPinned: selectedChatRoom?.isPinned,
      isRead: false,
      lastMessageUserName: decoded?.FullName,
      timeStamp: msgTimeStamp,
      toUser:
        Number(decoded?.id) !== selectedChatRoom?.toUser
          ? selectedChatRoom?.toUser
          : selectedChatRoom?.fromUser,
      toUserName:
        Number(decoded?.id) !== selectedChatRoom.toUser
          ? selectedChatRoom?.toUserName
          : selectedChatRoom?.fromUserName,
      type: "text",
      updatedOn: moment().format("YYYY-MM-DD[T]HH:mm"),
      lastMessageOn: moment().format("YYYY-MM-DD[T]HH:mm"),
      userId: Number(decoded?.id),
      userName: decoded?.FullName,
      userProfileUrl: userData.profilePic,
      status: "Active",
      fromProfileUrl: userData.profilePic,
      chatStatus: "Sending",
    };
    async function messageRequest(
      file: any,
      fileChatUuid: string,
      msg: ChatInterface,
      lastFile = false,
    ) {
      const chatUuId = getUuid();
      const msgTimeStamp = String(new Date().getTime().toString());
      // eslint-disable-next-line no-param-reassign
      msg.chatUuId = selectedChatRoom?.id ? chatUuId : "";
      // eslint-disable-next-line no-param-reassign
      msg.uuId = fileChatUuid || uuid;
      const formData = new FormData();

      const text = values?.message || "";
      const formattedMessage = text.replace(
        /\*\*(.*?)\*\*|__(.*?)__/g,
        (match, bold, italic) => {
          if (bold !== undefined) {
            return `<b>${bold}</b>`;
          }
          if (italic !== undefined) {
            return `<i>${italic}</i>`;
          }
          return match; // Return the original match if neither bold nor italic are defined
        },
      );

      if (file) {
        formData.append("Files", file);
        formData.append("Message", lastFile ? formattedMessage : ""); // done
        newChat.fileUrls = [URL.createObjectURL(file)];
        newChat.extension = file.name;
        newChat.message = lastFile ? formattedMessage : "";
      } else {
        newChat.message = formattedMessage;
        formData.append("Message", formattedMessage); // done
      }
      handleNewChat?.(newChat);
      setPageNumber({ pageNumber: 1 });
      formData.append("UuId", fileChatUuid || uuid);
      formData.append("ChatRoomId", String(selectedChatRoom?.id)); // done
      formData.append(
        "ToUser",
        String(
          selectedChatRoom?.toUser === Number(decoded?.id)
            ? selectedChatRoom?.fromUser
            : selectedChatRoom?.toUser,
        ),
      ); // done
      formData.append("FromUser", String(decoded?.id)); // done
      formData.append("TimeStamp", msgTimeStamp.toString()); // done
      formData.append(
        "QuoteMessageId",
        String((quotedMessage && quotedMessage.id) || 0), // done
      );

      formData.append("ChatRoomUuId", chatUuId); // done

      const response = await trySendChatMessage(
        formData,
        newChat.roomId,
        handleUploadProgress,
        newChat.isGroup,
      );

      if (response?.data) {
        sendMessage(response.data, room.isGroup ? memberIds : toUserData?.id);
        await UpdateChatInCache(response.data, queryClient);
      }
    }

    if (previewList?.length > 0) {
      for (let i = 0; i < imageListData.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        await messageRequest(
          imageListData[i],
          getUuid(),
          newChat,
          previewList.length - 1 === i,
        );
      }
    } else {
      messageRequest(null, "", newChat);
    }
    return true;
  };

  const handleCancelFile = (remimage: any) => {
    setPreviewList((images: any) =>
      images.filter((image: any) => image.url !== remimage),
    );
  };

  const formik = useAddChatForm(onSubmit, initialValues);
  const { handleSubmit, isSubmitting, setFieldValue, values } = formik;

  const handleSend = () => {
    if (!isSubmitting) {
      setQuotedMessageOpen(false);
      handleSubmit();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
    const trimmedMessage = formik.values.message
      ? formik.values.message.trim()
      : "";

    if (trimmedMessage !== "" || previewList.length > 0) {
      if (!isSubmitting) {
        handleSend();
      }
    }
  };
  const handleOnEnter = (text: string) => {
    setFieldValue("message", text);
    handleSend();
  };

  const handleWhoIsTyping = debounce((data: any, connectionId: string) => {
    const typingReq = {
      roomId: selectedChatRoomId,
      fromUserId: Number(decoded.id),
      userName: decoded.FullName,
    };
    // tryWhoIsTyping(typingReq);
    whoIsTypingEvent(typingReq, connectionId);
  }, 200);
  return (
    <Box
      className="chat-footer"
      sx={{
        // position: "absolute",
        // left: "0",
        // right: "0",
        // bottom: "0",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        position: "static",
        maxHeight: "100%",
        flex: "0 0 auto",
      }}
    >
      <Divider sx={{ borderBottomWidth: "1px", marginTop: "5px" }} />
      <Stack
        direction="row"
        sx={{
          width: "100%",
          //   alignItems: "center",
          gap: 1,
        }}
      >
        {isLoadingPreview ? (
          <Box
            sx={{
              display: "flex",
              paddingLeft: "2rem",
              paddingBottom: "2rem",
              width: "100%",
            }}
          >
            <CircularProgress size={26} />
          </Box>
        ) : (
          previewList?.map((image: any) => {
            return (
              <MediaPreviewItem
                key={image?.id}
                fromMinimize
                handleCancelFile={handleCancelFile}
                previewItem={image}
              />
            );
          })
        )}
      </Stack>
      {whoIsTyping?.roomId === selectedChatRoomId ? (
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              textAlign: "left",
              fontSize: "15px",
              color: palette.success.main,
              backgroundColor: "#fff",
              p: "8px",
            }}
          >
            {whoIsTyping ? `${whoIsTyping?.userName} is typing...` : ""}
          </Typography>
        </Box>
      ) : null}
      {uploadPercentage > 0 && previewList?.length > 0 && (
        <Box sx={{ width: "100%" }}>
          <ProgressBar value={uploadPercentage} />
        </Box>
      )}

      <Box sx={{ backgroundColor: "#f1f1f1", width: "100%" }}>
        <QuoteMessagePreview
          handleQuoteMessageClose={handleQuoteMessageClose}
          quotedMessage={quotedMessage}
          quotedMessageOpen={quotedMessageOpen}
        />

        <InputBox
          isRecording
          formik={formik}
          groupMemberData={groupMemberData?.data || []}
          handleKeyPress={handleKeyPress}
          handleOnEnter={handleOnEnter}
          handleWhoIsTyping={handleWhoIsTyping}
          imageListData={imageListData}
          isDragging={isDragging}
          selectedChatRoomId={selectedChatRoomId || 0}
          setImageListData={setImageListData}
          setIsDragging={setIsDragging}
          setIsLoadingPreview={setIsLoadingPreview}
          setPreviewList={setPreviewList}
          toUserData={toUserData}
        />
      </Box>
    </Box>
  );
}

export default React.memo(MiniChatFooter);
