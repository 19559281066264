/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useState } from "react";

interface IProps {
  url: string;
  type?: string;
  style?: any;
}
function AudioPlayer(props: IProps) {
  const { url, type, style } = props;
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggleAudio = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <audio ref={audioRef} controls {...props} style={{ ...style }}>
        <source src={url} type={type || "audio/mpeg"} />
        Your browser does not support the audio element.
      </audio>
      {/* <Button type="button" onClick={toggleAudio}>
        {isPlaying ? "Pause" : "Play"}
      </Button> */}
    </>
  );
}

export default AudioPlayer;
